export enum RegiseterStatus {
  REGISTER = 'REGISTER',
  AUTH = 'AUTH',
  SUCCESS = 'SUCCESS',
}

export enum PrefixOption {
  PATIENT = 'PATIENT',
  DOCTOR = 'DOCTOR',
}

export type ProvinceListType = {
  id: number
  name: string
}

export type DistrictType = {
  id: number
  name: string
}

export type SubDistrictType = {
  name: string
}

export type ProvineType = {
  nameTH: string
  nameEN: string
}
export interface FormProps {
  form: any
  disable?: any
  prefix_option?: PrefixOption
  require_email?: boolean
  require_phone?: boolean
  require_citizen_id?: boolean
  isLoading: boolean
}

export type RegisterErrorMessage = {
  title: string
  description: string
}
