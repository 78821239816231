import React from 'react'
import { Grid, Skeleton } from '@mui/material'

interface TableSkeletonProps {
  columnsAmount: number
}

export default function TableSkeleton({ columnsAmount }: TableSkeletonProps) {
  return (
    <div className="w-full">
      <div className="bg-white-main w-full px-[20px] rounded-[8px] mt-[15px]">
        {Array(10)
          .fill(0)
          .map((_, index: number) => (
            <Grid
              key={`health-column-${index}`}
              container
              columns={columnsAmount}
              columnSpacing="5px"
            >
              {Array(columnsAmount)
                .fill(0)
                .map((_, index: number) => (
                  <Grid key={`health-rows-${index}`} item mobile={1}>
                    <Skeleton height="50px" />
                  </Grid>
                ))}
            </Grid>
          ))}
      </div>
    </div>
  )
}
