import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { Icon } from '@iconify/react'

interface ButtonProps {
  id: string
  text: string
  icon?: string
  submit?: boolean
  loading?: boolean
  shape?: string
  width: string
  height?: string
  style?: string
  buttonTextSize?: string
  disable?: boolean
  onAction?: Function
  backgroundColor?: string
}

function ButtonComponent({
  text,
  icon,
  id,
  submit = false,
  loading = false,
  shape = 'contained',
  width,
  height = 'auto',
  style,
  buttonTextSize = '16px',
  disable = false,
  onAction,
  backgroundColor = '#1475BB',
}: ButtonProps) {
  const styleOutline =
    shape === 'outlined'
      ? {
          bgcolor: '#FFFF',
          color: '#1475BB',
          '&:hover': {
            backgroundColor: '#C9E2F4',
            color: '#1475BB',
          },
          '&.active': {
            backgroundColor: '#FFFF',
            color: '#1475BB',
          },
        }
      : {
          color: '#FFFF',
          bgcolor: backgroundColor,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: backgroundColor,
            color: '#FFFF',
            boxShadow: 'none',
          },
          '&:active': {
            backgroundColor: backgroundColor,
            color: '#FFFF',
            boxShadow: 'none',
          },
        }
  return (
    <div className={style ?? ''}>
      <Button
        id={id}
        type={submit ? 'submit' : 'button'}
        disabled={loading || disable}
        variant={(shape as 'outlined') ?? 'contained'}
        className="flex justify-center items-center"
        sx={{
          width: width,
          height: height,
          ...styleOutline,
        }}
        onClick={() => onAction && onAction()}
      >
        {icon && <Icon icon={icon} className="text-[20px] mr-[5px]" />}
        <p className={`text-[${buttonTextSize}] tablet:text-[14px] capitalize`}>
          {text}
        </p>
        {loading && (
          <CircularProgress
            color="inherit"
            size={buttonTextSize}
            sx={{ marginLeft: '10px' }}
          />
        )}
      </Button>
    </div>
  )
}

export default ButtonComponent
