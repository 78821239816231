import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { AlertComponent, ElementComponent, MenuComponent } from 'components'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import { DrugType } from 'types'
import { useMutation, useQuery } from 'urql'
import { general } from 'utils'

function DrugAddPage() {
  const menuList = [
    { title: 'จัดการคลังยา', link: '' },
    { title: 'เพิ่มชนิดยา', link: '' },
  ]
  const unitList = [
    { title: 'mg' },
    { title: 'g' },
    { title: 'ml' },
    { title: 'mg/ml' },
    { title: 'mg/2ml' },
    { title: 'mg/3ml' },
    { title: 'mg/4ml' },
    { title: 'mg/5ml' },
  ]
  const typeList = [
    { title: 'เบาหวาน' },
    { title: 'ความดัน' },
    { title: 'ไขมัน' },
    { title: 'วิตามิน' },
    { title: 'อื่นๆ' },
    { title: '-' },
  ]

  const [isDrugTypeChoosed, setIsDrugTypeChoosed] = useState<boolean>(false)
  const [drugType, setDrugType] = useState<string>('')
  const [drugListApiData] = useQuery(MedicalQuery.getDrugTypeList())
  const [drugTypeData, setDrugTypeData] = useState([])

  useEffect(() => {
    if (drugListApiData.fetching || !drugListApiData.data) return

    if (!drugListApiData?.fetching && !drugListApiData.error) {
      let preData = []
      drugListApiData.data.getDrugType.map((item: any) => {
        preData.push({
          label: item.dutName,
          dutId: item.dutId,
          dutName: item.dutName,
        })
      })
      setDrugTypeData(preData)
    }
  }, [drugListApiData.data])

  const [dName, setDName] = useState<string>('')
  const [dUseType, setDUseType] = useState<string>('')
  const [tradeName, setTradeName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [cost, setCost] = useState<string>('0.0')
  const [income, setIncome] = useState<string>('0.0')
  const [alarmCutPoint] = useState<string>('0')
  const [typeOne, setTypeOne] = useState<string>('')
  const [typeTwo, setTypeTwo] = useState<string>('')
  const [quantity, setQuantity] = useState<string>('')
  const [unit, setUnit] = useState<string>('')
  const [phrDrug, setPhrDrug] = useState<string>('')
  const [defaultShow, setDefaultShow] = useState<string>('')
  const [drugMainType, setDrugMainType] = useState<string>('')
  const [stock, setStock] = useState<number>(0)
  const [drugDefaultCondition, setDrugDefaultCondition] = useState([])
  const [injDefault, setInjDefault] = useState<string>('')
  const [injMin, setInjMin] = useState<string>('')
  const [injMax, setInjMax] = useState<string>('')
  const [injUnit, setInjUnit] = useState<string>('')
  const [injIM, setInjIM] = useState<string>('')
  const [injIV, setInjIV] = useState<string>('')
  const [injSC, setInjSC] = useState<string>('')
  const [invoiceType, setInvoiceType] = useState<string>('')

  const initialValues: DrugType.DrugDataType = {
    dName: dName,
    dUseType: dUseType,
    tradeName: tradeName,
    description: description,
    cost: cost,
    income: income,
    alarmCutPoint: alarmCutPoint,
    typeOne: typeOne,
    typeTwo: typeTwo,
    quantity: quantity,
    unit: unit,
    phrDrug: phrDrug,
    defaultShow: defaultShow,
    drugMainType: drugMainType,
    stock: stock,
    drugDefaultCondition: JSON.stringify({ data: drugDefaultCondition }),
    injDefault: injDefault,
    injMin: injMin,
    injMax: injMax,
    injUnit: injUnit,
    injIM: injIM,
    injIV: injIV,
    injSC: injSC,
    invoiceType: invoiceType,
  }

  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })

  const [createMedicalMutation, executeMedicalMutation] = useMutation(
    MedicalMutate.createDrug,
  )

  const createDrug = async (values: DrugType.DrugDataType) => {
    let payload = {
      data: {
        dName: values.dName,
        dUseType: values.dUseType,
        tradeName: values.tradeName,
        description: values.description,
        cost: values.cost,
        income: values.income,
        alarmCutPoint: values.alarmCutPoint,
        typeOne: values.typeOne,
        typeTwo: values.typeTwo,
        quantity: values.quantity,
        unit: values.unit,
        phrDrug: values.phrDrug,
        defaultShow: values.defaultShow,
        drugMainType: values.drugMainType,
        stock: values.stock,
        drugDefaultCondition: values.drugDefaultCondition,
        injDefault: values.injDefault,
        injMin: values.injMin,
        injMax: values.injMax,
        injUnit: values.injUnit,
        injIM: values.injIM,
        injIV: values.injIV,
        injSC: values.injSC,
        invoiceType: values.invoiceType,
      },
    }
    const result = await executeMedicalMutation(payload)

    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: result.error.toString(),
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: '',
      onClose: () => {
        general.http.goto('/drug-management/list')
      },
    })

    return
  }

  return (
    <div className="p-[80px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      <div
        className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px]  text-[16px] mt-4"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <p className="text-[20px] text-blue-main">เพิ่มชนิดยา</p>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          //validationSchema={validationSchema}
          onSubmit={createDrug}
        >
          {(formik) => (
            <Form className="w-[90%]">
              <Stack
                direction="row"
                spacing="10px"
                justifyContent="space-between"
                className="text-[14px] mt-4"
              >
                <div className="w-[40%]">
                  <p className="mt-2">ชื่อยา</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => setDName(event.target.value)}
                  />
                </div>
                <div className="w-[40%]">
                  <p className="mt-2">ชื่อการค้า</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => setTradeName(event.target.value)}
                  />
                </div>
                <div className="w-[20%]">
                  <p className="mt-2">ขนาดยา</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => setQuantity(event.target.value)}
                  />
                </div>
                <div className="w-[20%]">
                  <p className="mt-2">หน่วย</p>
                  <FormControl className="w-[100%]" size="small">
                    <Select
                      name=""
                      onChange={(event: SelectChangeEvent) =>
                        setUnit(event.target.value)
                      }
                    >
                      {unitList.length > 0 &&
                        unitList.map((item: any) => (
                          <MenuItem value={item.title}>{item.title}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </Stack>

              <Stack
                direction="row"
                spacing="10px"
                className="text-[14px] mt-4"
              >
                <div className="w-[30%]">
                  <p className="mt-2">สรรพคุณยา</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </div>
                <div className="w-[20%]">
                  <p className="mt-2">ประเภทบัญชียา</p>
                  <FormControl className="w-[100%]" size="small">
                    <Select
                      onChange={(event: SelectChangeEvent) =>
                        setDrugMainType(event.target.value)
                      }
                    >
                      <MenuItem value={'ยาในบัญชี'}>ยาในบัญชี</MenuItem>
                      <MenuItem value={'ยานอกบัญชี ($)'}>
                        ยานอกบัญชี ($)
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="w-[20%]">
                  <p className="mt-2">PHR Drug</p>
                  <FormControl className="w-[100%]" size="small">
                    <Select
                      onChange={(event: SelectChangeEvent) =>
                        setPhrDrug(event.target.value)
                      }
                    >
                      <MenuItem value={'Yes'}>ใช่</MenuItem>
                      <MenuItem value={'No'}>ไม่ใช่</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="w-[20%]">
                  <p className="mt-2">Default Show</p>
                  <FormControl className="w-[100%]" size="small">
                    <Select
                      onChange={(event: SelectChangeEvent) =>
                        setDefaultShow(event.target.value)
                      }
                    >
                      <MenuItem value={'Yes'}>ใช่</MenuItem>
                      <MenuItem value={'No'}>ไม่ใช่</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Stack>
              <Stack
                direction="row"
                spacing="10px"
                className="text-[14px] mt-4"
              >
                <div className="w-[20%]">
                  <p className="mt-2">กลุ่มยา 1</p>
                  <FormControl className="w-[100%]" size="small">
                    <Select
                      onChange={(event: SelectChangeEvent) =>
                        setTypeOne(event.target.value)
                      }
                    >
                      {typeList.length > 0 &&
                        typeList.map((item: any) => (
                          <MenuItem value={item.title}>{item.title}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="w-[20%]">
                  <p className="mt-2">กลุ่มยา 2</p>
                  <FormControl className="w-[100%]" size="small">
                    <Select
                      onChange={(event: SelectChangeEvent) =>
                        setTypeTwo(event.target.value)
                      }
                    >
                      {typeList.length > 0 &&
                        typeList.map((item: any) => (
                          <MenuItem value={item.title}>{item.title}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="w-[20%]">
                  <p className="mt-2">หมวดเงินใบเสร็จ</p>
                  <FormControl className="w-[100%]" size="small">
                    <Select
                      onChange={(event: SelectChangeEvent) =>
                        setInvoiceType(event.target.value)
                      }
                    >
                      <MenuItem value="ค่ายา">ค่ายา</MenuItem>
                      <MenuItem value="ค่าหัตถการ">ค่าหัตถการ</MenuItem>
                      <MenuItem value="ค่าทางห้องปฏิบัติการ">
                        ค่าทางห้องปฏิบัติการ
                      </MenuItem>
                      <MenuItem value="ค่าวิชาชีพแพทย์">
                        ค่าวิชาชีพแพทย์
                      </MenuItem>
                      <MenuItem value="ค่าเวชภัณฑ์">ค่าเวชภัณฑ์</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Stack>
              <Stack
                direction="row"
                spacing="10px"
                className="text-[14px] mt-4"
              >
                <div className="w-[20%]">
                  <p className="mt-2">ต้นทุน (หน่วย)</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => setCost(event.target.value)}
                  />
                </div>
                <div className="w-[20%]">
                  <p className="mt-2">ราคาขาย (หน่วย)</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => setIncome(event.target.value)}
                  />
                </div>
              </Stack>
              <p className="text-[18px] mt-4">วิธีบริหารยา</p>
              <div className="w-[20%] mt-4">
                <p className="mt-2">ประเภทยา</p>
                <FormControl className="w-[100%]" size="small">
                  <Select
                    name=""
                    onChange={(event: SelectChangeEvent) => {
                      setIsDrugTypeChoosed(true)
                      setDrugType(event.target.value)
                      setDUseType(event.target.value)
                    }}
                  >
                    <MenuItem value={'ยากินเม็ด'}>ยากินเม็ด</MenuItem>
                    <MenuItem value={'ยาพ่น'}>ยาพ่น</MenuItem>
                    <MenuItem value={'ยาผงละลาย'}>ยาผงละลาย</MenuItem>
                    <MenuItem value={'ยาน้ำ'}>ยาน้ำ</MenuItem>
                    <MenuItem value={'ครีมภายนอก'}>ครีมภายนอก</MenuItem>
                    <MenuItem value={'ยาหยอดตา'}>ยาหยอดตา</MenuItem>
                    <MenuItem value={'ยาฉีด'}>ยาฉีด</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {isDrugTypeChoosed &&
                (drugType === 'ยากินเม็ด' ||
                  drugType === 'ยาพ่น' ||
                  drugType === 'ยาผงละลาย' ||
                  drugType === 'ยาน้ำ' ||
                  drugType === 'ครีมภายนอก' ||
                  drugType === 'ยาหยอดตา') && (
                  <div className="mt-4">
                    {!drugListApiData?.fetching && !drugListApiData.error && (
                      <Autocomplete
                        multiple
                        options={drugTypeData}
                        onChange={(_, value) => {
                          setDrugDefaultCondition(value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder=""
                          />
                        )}
                      />
                    )}
                  </div>
                )}
              {isDrugTypeChoosed && drugType === 'ยาฉีด' && (
                <Stack
                  direction="row"
                  spacing="10px"
                  className="text-[14px] mt-4"
                >
                  <div className="w-[10%]">
                    <p className="mt-2">Default</p>
                    <TextField
                      className="w-[100%]"
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(event) => setInjDefault(event.target.value)}
                    />
                  </div>
                  <div className="w-[10%]">
                    <p className="mt-2">Min</p>
                    <TextField
                      className="w-[100%]"
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(event) => setInjMin(event.target.value)}
                    />
                  </div>
                  <div className="w-[10%]">
                    <p className="mt-2">Max</p>
                    <TextField
                      className="w-[100%]"
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      onChange={(event) => setInjMax(event.target.value)}
                    />
                  </div>
                  <div className="w-[10%]">
                    <p className="mt-2">หน่วย</p>
                    <FormControl className="w-[100%]" size="small">
                      <Select
                        onChange={(event: SelectChangeEvent) =>
                          setInjUnit(event.target.value)
                        }
                      >
                        <MenuItem value={'mg'}>mg</MenuItem>
                        <MenuItem value={'g'}>g</MenuItem>
                        <MenuItem value={'ml'}>ml</MenuItem>
                        <MenuItem value={'amp'}>amp</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="w-[15%]">
                    <p className="mt-2">วิธีการฉีด</p>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            if (event.target.checked) {
                              setInjIM('Yes')
                            } else {
                              setInjIM('No')
                            }
                          }}
                        />
                      }
                      label="IM"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            if (event.target.checked) {
                              setInjIV('Yes')
                            } else {
                              setInjIV('No')
                            }
                          }}
                        />
                      }
                      label="IV"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            if (event.target.checked) {
                              setInjSC('Yes')
                            } else {
                              setInjSC('No')
                            }
                          }}
                        />
                      }
                      label="SC"
                    />
                  </div>
                </Stack>
              )}
              <div className="w-[20%]">
                <p className="mt-4 text-red-main">ปริมาณยาในคลัง</p>
                <TextField
                  className="w-[100%]"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(event) => setStock(parseInt(event.target.value))}
                />
              </div>
              <ElementComponent.Button
                //submit
                id="submit-diagnose-button"
                width="w-main"
                height="32px"
                text="ยืนยันส่งข้อมูล"
                style="mt-2 ml-[40%]"
                loading={createMedicalMutation.fetching}
                onAction={() => {
                  if (invoiceType === '') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาเลือกหมวดเงินใบเสร็จ',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else if (stock === 0) {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาระบุ ปริมาณยาในคลัง',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else if (income === '0.0') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาระบุ ราคาขาย',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else if (drugType === '') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณารเลือก ประเภทยา',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else {
                    formik.submitForm()
                  }
                }}
              />
            </Form>
          )}
        </Formik>
        {submitPopupData.type === 'success' ? (
          <AlertComponent.SuccessModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        ) : (
          <AlertComponent.ErrorModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        )}
      </div>
    </div>
  )
}

export default DrugAddPage
