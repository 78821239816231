import React from 'react'

import { RegisterType } from 'types'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { UserQuery } from 'services/graphql/userService'
import { useQuery } from 'urql'

export default function EnterPriseForm({ form }: RegisterType.FormProps) {
  const [doctorData] = useQuery({
    ...UserQuery.getUsers({
      where: {
        role: {
          is: {
            name: {
              equals: 'DOCTOR',
            },
          },
        },
      },
    }),
  })

  const [EnterPriseData] = useQuery({
    ...UserQuery.getEnterpriseTreatmentPlaces(),
  })

  return (
    <div>
      <p className="text-[18px] font-bold mb-[30px]">
        ข้อมูลการรับบริการทางการแพทย์
      </p>
      <div className="flex flex-col gap-main">
        <p>หน่วยงานที่ดูแล</p>
        <Select
          className="w-[100%] -mt-4"
          size="small"
          name="ercId"
          variant="outlined"
          defaultValue={form.values.ercId}
          onChange={(event: SelectChangeEvent) =>
            form.setFieldValue('ercId', event.target.value)
          }
          error={form.touched.ercId && Boolean(form.errors.ercId)}
        >
          {!EnterPriseData.fetching &&
            !EnterPriseData.error &&
            EnterPriseData.data.getEnterpriseTreatmentPlaces.map(
              (item: any) => (
                <MenuItem value={item.ercId}>{item.thaiName}</MenuItem>
              ),
            )}
        </Select>
        <p>เเพทย์ประจำตัว</p>
        <Select
          className="w-[100%] -mt-4"
          size="small"
          name="docId"
          variant="outlined"
          placeholder="เเพทย์ประจำตัว"
          defaultValue={form.values.docId}
          onChange={(event: SelectChangeEvent) =>
            form.setFieldValue('docId', event.target.value)
          }
          error={form.touched.docId && Boolean(form.errors.docId)}
        >
          {!doctorData.fetching &&
            !doctorData.error &&
            doctorData.data.getUsers.map((item: any) => (
              <MenuItem value={item.uId}>
                {item.firstname} {item.lastname}
              </MenuItem>
            ))}
        </Select>
      </div>
    </div>
  )
}
