export const getUserOverviewData = (uId: string) => {
  const getUserQuery = `query getUser ($where: UserWhereUniqueInput!) {
                          getUser (where: $where) {
                            hnId
                            firstname
                            lastname
                            UserMedicalDemograhicData {
                              gender
                              birthdate
                              province
                              district
                              allergic
                              userUnderlyingDiseaseTrasaction {
                                underlyingDisease {
                                  thaiName
                                  engName
                                }
                              }
                            }
                          }
                        }`

  return {
    query: getUserQuery,
    variables: {
      where: {
        uId: uId,
      },
    },
  }
}

export const getDiseaseData = () => {
  const getDisease = `query getDiseases($where: DiseaseWhereInput! $orderBy: [DiseaseOrderByWithRelationInput!]){
    getDiseases(where: $where, orderBy: $orderBy) {
      dId
      thaiName
      medName
    }
  }`

  return {
    query: getDisease,
    variables: {
      where: {
        showFlag: {
          equals: true,
        },
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getOneDiseaseData = (medName: string) => {
  const query = `
    query getDiseases($where: DiseaseWhereInput){
      getDiseases(where: $where) {
        dId
        engName
        thaiName
        medName
        imagePath
        diseaseAttibute {
            daId
            type
            engName
            thaiName
            unitThai
            medName
            description
            condition
            inputFlag
            calFlag
            showFlag
            mandatoryFlag
        }
        associateDiease {
            adId
            thaiName
            imagePath
            associateDieaseAttibute {
                adaId
                medName
                thaiName
                unitThai
                description
                type
                indexSelect
                condition
                showFlag
                showPrevoius
            }
        }
        type
      }
    }
  `

  return {
    query: query,
    variables: {
      where: {
        medName: {
          equals: medName,
        },
      },
    },
  }
}

export const getDiseasesAttibutes = (disease_dId: string) => {
  const getDisease = `query getDiseasesAttibutes($where: DiseaseAttibuteWhereInput!){
    getDiseasesAttibutes(where: $where) {
      daId
      description
      thaiName
      medName
      unitThai
    }
  }`

  return {
    query: getDisease,
    variables: {
      where: {
        disease: {
          is: {
            medName: {
              equals: disease_dId,
            },
          },
        },
        showFlag: {
          equals: true,
        },
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getHealthStatusOverallDiseaseScorces = () => {
  const getHealthStatusOverallDiseaseScorces = `query getHealthStatusOverallDiseaseScorces($where: HealthStatusOverallScorceDataWhereInput! $orderBy: [HealthStatusOverallScorceDataOrderByWithRelationInput!], $take: Int){
    getHealthStatusOverallDiseaseScorces(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getHealthStatusOverallDiseaseScorces,
    variables: {
      where: {
        puId: {
          equals: '',
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 1,
    },
  }
}

export const getHealthStatusDiseaseScorces = (
  disease_dId: string,
  puId: string,
) => {
  const getHealthStatusDiseaseScorces = `query getHealthStatusDiseaseScorces($where: HealthStatusDiseaseScorceDataWhereInput! $orderBy: [HealthStatusDiseaseScorceDataOrderByWithRelationInput!], $take: Int){
    getHealthStatusDiseaseScorces(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getHealthStatusDiseaseScorces,
    variables: {
      where: {
        puId: {
          equals: puId,
        },
        disease: {
          is: {
            medName: {
              equals: disease_dId,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 1,
    },
  }
}
export const getMedDataTransactionsForBmi = (daId: string) => {
  const getMedDataTransactions = `query getMedDataTransactions($where: UserMedTrasactionWhereInput, $take: Int, $skip: Int, $orderBy: [UserMedTrasactionOrderByWithRelationInput!]){
    getMedDataTransactions(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      value
      createdAt
    }
  }`

  return {
    query: getMedDataTransactions,
    variables: {
      where: {
        userMedicalData: {
          is: {
            puId: {
              equals: '',
            },
          },
        },
        daId: {
          equals: daId,
        },
        diseaseAttibute: {
          is: {
            showFlag: {
              equals: true,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 6,
    },
  }
}

export const getMedDataTransactionsBydId = (daId: string, puId: string) => {
  const getMedDataTransactions = `query getMedDataTransactions($where: UserMedTrasactionWhereInput! $orderBy: [UserMedTrasactionOrderByWithRelationInput!], $take: Int){
    getMedDataTransactions(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getMedDataTransactions,
    variables: {
      where: {
        userMedicalData: {
          is: {
            puId: {
              equals: puId,
            },
          },
        },
        daId: {
          equals: daId,
        },
        diseaseAttibute: {
          is: {
            showFlag: {
              equals: true,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 6,
    },
  }
}

export const getMedDataTransactionsByadaId = (adaId: string) => {
  const getMedDataTransactions = `query getMedDataTransactions($where: UserMedTrasactionWhereInput! $orderBy: [UserMedTrasactionOrderByWithRelationInput!], $take: Int){
    getMedDataTransactions(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getMedDataTransactions,
    variables: {
      where: {
        userMedicalData: {
          is: {
            puId: {
              equals: '',
            },
          },
        },
        adaId: {
          equals: adaId,
        },
        associateDieaseAttibute: {
          is: {
            showFlag: {
              equals: true,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 6,
    },
  }
}

export const getLatestMedDataTransactionByadaID = (
  adaId: string,
  puId: string,
) => {
  const getMedDataTransactions = `query getMedDataTransactions($where: UserMedTrasactionWhereInput! $orderBy: [UserMedTrasactionOrderByWithRelationInput!], $take: Int){
    getMedDataTransactions(where: $where, orderBy: $orderBy, take: $take) {
      value
      createdAt
    }
  }`

  return {
    query: getMedDataTransactions,
    variables: {
      where: {
        userMedicalData: {
          is: {
            puId: {
              equals: puId,
            },
          },
        },
        adaId: {
          equals: adaId,
        },
        associateDieaseAttibute: {
          is: {
            showFlag: {
              equals: true,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 1,
    },
  }
}

export const getAssociateMedDataTransactions = (adaId: string) => {
  const query = `
    query getMedDataTransactions($where: UserMedTrasactionWhereInput! $orderBy: [UserMedTrasactionOrderByWithRelationInput!], $take: Int){
      getMedDataTransactions(where: $where, orderBy: $orderBy, take: $take) {
        value
        createdAt
      }
    }
  `

  return {
    query: query,
    variables: {
      where: {
        userMedicalData: {
          is: {
            puId: {
              equals: '',
            },
          },
        },
        adaId: {
          equals: adaId,
        },
        associateDieaseAttibute: {
          is: {
            showFlag: {
              equals: true,
            },
          },
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
      take: 6,
    },
  }
}

export const getDiseaseAttibutesCondition = (daId: string) => {
  const querySchema = `query getDiseaseAttibuteCondition($where: DiseaseAttibuteWhereInput) {
                        getDiseaseAttibuteCondition(where: $where) {
                          daId
                          condition
                        }
                      }`

  return {
    query: querySchema,
    variables: {
      where: {
        daId: {
          equals: daId,
        },
      },
    },
  }
}

export const getAssociateDiseaseAttibuteCondition = (adaId: string) => {
  const querySchema = `query getAssociateDiseaseAttibuteCondition($where: AssociateDieaseAttibuteWhereInput) {
    getAssociateDiseaseAttibuteCondition(where: $where) {
      adaId
      condition
    }
  }`

  return {
    query: querySchema,
    variables: {
      where: {
        adaId: {
          equals: adaId,
        },
      },
    },
  }
}

export const getConsultType = () => {
  const getConsultType = `query getConsultType {
    getConsultType  {
        ctId
        typeName
    }
}`

  return {
    query: getConsultType,
    variables: {
      where: {
        showFlag: {
          equals: true,
        },
      },
    },
  }
}

export const getPatientConsultList = (
  dateConsult: string,
  typeConsult: string,
) => {
  const getConsultList = `
    query getConsultList ($where: ConsultWhereInput, $orderBy: [ConsultOrderByWithRelationInput!]) {
      getConsultList (where: $where, orderBy: $orderBy) {
          cId
          placeName
          time
          ctId
          textConsult
          uid
          createdAt
          dateConsult
          finishedFlag
          doctorUid
          isDiagnose
          isPayedDrug
          dId
          user {
              firstname
              lastname
          }
      }
  }
  `

  return {
    query: getConsultList,
    variables: {
      where: {
        dateConsult: {
          equals: dateConsult,
        },
        typeConsult: {
          equals: typeConsult,
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}

export const getDiagnoseLastOne = (dId: string) => {
  const getDiagnoseLastOne = `
    query getDiagnoseLastOne ($where: DiagnoseWhereInput, $orderBy: [DiagnoseOrderByWithRelationInput!]) {
      getDiagnoseLastOne (where: $where, orderBy: $orderBy) {
        dId
        icdData
        allergy
        detail
        recommend
        drugData
        costTotal
        incomeTotal
        uId
        duId
        createdAt
        imageFile
        incomeReal
      }
  }
  `
  return {
    query: getDiagnoseLastOne,
    variables: {
      where: {
        dId: {
          equals: dId,
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}

export const getDiagnoseLastOneByPatient = (dId: string) => {
  const getDiagnoseLastOne = `
    query getDiagnoseLastOne ($where: DiagnoseWhereInput, $orderBy: [DiagnoseOrderByWithRelationInput!]) {
      getDiagnoseLastOne (where: $where, orderBy: $orderBy) {
        dId
        icdData
        allergy
        detail
        recommend
        drugData
        costTotal
        incomeTotal
        uId
        duId
        createdAt
        imageFile
        incomeReal
      }
  }
  `
  return {
    query: getDiagnoseLastOne,
    variables: {
      where: {
        uId: {
          equals: dId,
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}
export const getDiagnoseListByPatient = (dId: string) => {
  const getDiagnoseList = `
    query getDiagnoseList ($where: DiagnoseWhereInput, $orderBy: [DiagnoseOrderByWithRelationInput!]) {
      getDiagnoseList (where: $where, orderBy: $orderBy) {
        dId
        icdData
        allergy
        detail
        recommend
        drugData
        costTotal
        incomeTotal
        uId
        duId
        createdAt
        imageFile
        incomeReal
      }
  }
  `
  return {
    query: getDiagnoseList,
    variables: {
      where: {
        uId: {
          equals: dId,
        },
      },
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}
export const getUserByUserId = (uId: string) => {
  const userDoctorSchema = `
    query getUser ($where: UserWhereUniqueInput!) {
      getUser (where: $where) {
          uId
          hnId
          prefix
          firstname
          lastname
          imagePath
          ercId
          docId
          UserAuth {
              identifyId
              phoneNumber
          }
          UserMedicalDemograhicData {
              allergic
              gender
              birthdate
              province
              district
              subdistrict
              postcode
              userUnderlyingDiseaseTrasaction {
                uudtId
                underlyingDisease {
                    udId
                    medName
                    thaiName
                }
            }
          }
      }
    }
  `

  return {
    query: userDoctorSchema,
    variables: {
      where: {
        uId: uId,
      },
    },
  }
}

export const getPatientConsultFinishedList = (dateConsult: string) => {
  const getConsultList = `
    query getConsultList ($where: ConsultWhereInput) {
      getConsultList (where: $where) {
          cId
          placeName
          time
          ctId
          textConsult
          uid
          createdAt
          dateConsult
          finishedFlag
          doctorUid
          isDiagnose
          isPayedDrug
          dId
          user {
              firstname
              lastname
          }
      }
  }
  `

  return {
    query: getConsultList,
    variables: {
      where: {
        dateConsult: {
          equals: dateConsult,
        },
        finishedFlag: {
          equals: true,
        },
      },
    },
  }
}
