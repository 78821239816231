import React from 'react'
import { NavLink } from 'react-router-dom'
import { Icon } from '@iconify/react'

import { Logo2SVG } from 'assets/svg'
import { route } from 'settings'

interface MenuItemProps {
  title: string
  icon: string
  urlPath: string
}

const menuList = [
  {
    title: 'ภาพรวม',
    icon: 'material-symbols:pie-chart',
    urlPath: route.OVERVIEW_PAGE_URL,
  },
  {
    title: 'จัดการข้อมูลผู้ใช้งาน',
    icon: 'material-symbols:person',
    urlPath: route.USER_MANAGEMENT_MAIN_PAGE_URL,
  },
  {
    title: 'จัดการคลังยา',
    icon: 'material-symbols:pill',
    urlPath: route.DRUG_MANAGEMENT_MAIN_PAGE_URL,
  },
  {
    title: 'จัดการข้อมูลโรค',
    icon: 'heroicons:document-chart-bar-solid',
    urlPath: route.DISEASE_MANAGEMENT_URL,
  },
  { title: 'ตั้งค่า', icon: 'uiw:setting', urlPath: route.SETTING_PAGE_URL },
]

function MenuItem({ title, icon, urlPath }: MenuItemProps) {
  return (
    <NavLink
      to={urlPath}
      className={({ isActive }) =>
        `flex items-center px-[20px] p-[10px] gap-[10px] ${
          isActive
            ? 'bg-blue-light text-blue-main'
            : 'text-white-main hover:bg-sky-700 hover:text-blue-light'
        }`
      }
    >
      <Icon icon={icon} className="w-[25px] h-[25px]" />
      <p className="text-[18px]">{title}</p>
    </NavLink>
  )
}

export default function DoctorNavbar() {
  return (
    <div
      className="w-[300px] h-full fixed top-0 left-0 z-[9999]"
      style={{
        background: '#0D5CDD',
      }}
    >
      <div className="pt-[20px] px-[25px]">
        <Logo2SVG />
      </div>

      <div className="mt-[50px] flex flex-col gap-[10px]">
        {menuList.map((item: any, index: number) => (
          <MenuItem
            key={`doctor-menu-${index}`}
            title={item.title}
            icon={item.icon}
            urlPath={item.urlPath}
          />
        ))}
      </div>

      <p className="text-[14px] text-white-main text-center absolute bottom-[25px] w-full">
        © 2022, All rights reserved
      </p>
    </div>
  )
}
