import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'urql'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import dayjs from 'dayjs'

import { NoDiseaseDataSVG } from 'assets/svg'
import { ElementComponent, ModalComponent, AlertComponent } from 'components'
import { general } from 'utils'
import { route } from 'settings'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import { UserQuery } from 'services/graphql/userService'

import { GeneralDieaseForm, SpecificDiseaseForm } from './index'

interface DiseaseDataSpeicific {
  disease: {
    dId: string
    thaiName: string
  }
  date: Date
}

function AddHealthForm() {
  const [errorModalData, setErrorModalData] = useState({
    title: 'บันทึกข้อมูลไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const [searchTable, setSearchTable] = useState('')

  const [userDataOption, setUserDataOption] = useState([])

  const [userData] = useQuery({
    ...UserQuery.getUsers({
      where: {
        role: {
          is: {
            name: {
              equals: 'PATIENT',
            },
          },
        },
      },
      dropDownListFlag: true,
    }),
  })

  const formatData = (users) => {
    if (users.length > 1)
      return [
        { key: '', value: 'โปรดระบุผู้ใช้งาน' },
        ...users.map((user) => ({
          key: `${user.UserAuth.phoneNumber} | ${Array(
            7 - user.hnId.toString().length,
          )
            .fill(0)
            .join('')}${user.hnId} | ${user.prefix} ${user.firstname} ${
            user.lastname
          }`,
          value: user.uId,
        })),
      ]
    return [{ key: '', value: 'โปรดระบุผู้ใช้งาน' }]
  }

  useEffect(() => {
    if (userData.fetching || userData.error) return
    setUserDataOption(formatData(userData.data.getUsers))
  }, [userData])

  const handleUserChange = (value: { label: string; value: string }, form) => {
    form.setFieldValue('uId', value.value)
    setSearchTable(value.label)
  }

  const [medicalMutate, executeMedical] = useMutation(
    MedicalMutate.createMedicalData,
  )

  const [medicalQueryData] = useQuery(
    MedicalQuery.getDisease({
      AND: [
        { type: { equals: 'GENERAL' } },
        { diseaseAttibute: { every: { inputFlag: { equals: true } } } },
      ],
    }),
  )

  const [formData, setFormData] = useState({
    uId: '',
  })
  const [validationForm, setValidationForm] = useState<any>({
    uId: yup.string().required('กรุณาเลือกผคนไข้ที่ต้องการเพิ่มข้อมูล'),
  })

  const [diseaseList, setDiseaseList] = useState([])

  const [openDiseaseModal, setOpenDiseaseModal] = useState<boolean>(false)
  const [diseaseSpecific, setDiseaseSpecific] = useState([])

  const handleRemoveDiseaseSpecific = (index: number) => {
    let newDiseaseSpecific = [...diseaseSpecific]
    newDiseaseSpecific.splice(index, 1)
    setDiseaseSpecific(newDiseaseSpecific)
  }

  const handleSubmit = async (values?: any) => {
    const uId = values.uId
    const processData = { ...values }
    delete processData.uId
    const data = Object.keys(processData).flatMap((key: any) => {
      if (
        values[key].value !== '' &&
        values[key].value !== null &&
        values[key].value.length !== 0
      ) {
        let result = {
          daId: values[key].daId,
          createdAt: values[key]?.createdAt ?? dayjs().toISOString(),
        }

        if (values[key].type === 'FLOAT')
          result['value'] = Number(values[key].value)
        else if (values[key].type === 'IMAGE' && values[key].value.length > 0)
          result['file'] = values[key].value
        else if (values[key].type === 'TEXT' && values[key].value !== '')
          result['desciption'] = values[key].value

        return result
      }

      return null
    })

    await executeMedical({
      data: {
        dataInputFlag: true,
        userMedTrasaction: {
          createMany: {
            data: data.filter((item) => item !== null),
          },
        },
        patientUser: {
          connect: {
            uId: uId,
          },
        },
        submitUser: {
          connect: {
            uId: '',
          },
        },
      },
    }).then((res) => {
      if (res.error) {
        setErrorModalData({ ...errorModalData, open: true })
        return
      }
    })

    general.http.goto(route.HEALTH_DATA_MANAGEMENT_URL)
  }

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'บันทึกข้อมูลไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  useEffect(() => {
    ;(async () => {
      if (!medicalQueryData.fetching) {
        const { getDiseases } = await medicalQueryData.data
        const form = getDiseases.reduce((diseaseList, disease) => {
          disease.diseaseAttibute.forEach((data) => {
            diseaseList[data.medName] = {
              type: data.type,
              daId: data.daId,
              value: data.type === 'IMAGE' ? [] : '',
            }
          })
          return diseaseList
        }, {})

        const validation = await getDiseases.reduce((diseaseList, disease) => {
          disease.diseaseAttibute.forEach((data) => {
            if (data.mandatoryFlag) {
              diseaseList[data.medName] = yup.object().shape({
                value: yup
                  .number()
                  .min(
                    data.minValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .max(
                    data.maxValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .typeError(
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                  )
                  .test(
                    'check-2-precision',
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                    (value) => {
                      if (value)
                        return !!value.toString().match(/^\d+(\.\d{0,2})?$/)

                      return true
                    },
                  ),
              })
            }

            if (data.type === 'FLOAT' && !data.mandatoryFlag) {
              diseaseList[data.medName] = yup.object().shape({
                value: yup
                  .number()
                  .min(
                    data.minValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .max(
                    data.maxValuePos,
                    `กรุณากรอกค่าที่เป็นตัวเลขเท่านั้น มากสุดที่ ${data.maxValuePos} และ น้อยที่สุด ${data.minValuePos}`,
                  )
                  .typeError(
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                  )
                  .test(
                    'check-2-precision',
                    'กรุณากรอกค่าที่เป็นตัวเลขและทศนิยมไม่เกิน 2 ตำแหน่ง',
                    (value) => {
                      if (value)
                        return !!value.toString().match(/^\d+(\.\d{0,2})?$/)

                      return true
                    },
                  ),
              })
            }
          })
          return diseaseList
        }, {})

        setFormData({ ...form, ...formData })
        setDiseaseList(getDiseases)
        setValidationForm(
          yup.object().shape({
            ...validation,
            uId: yup.string().required('กรุณาเลือกผคนไข้ที่ต้องการเพิ่มข้อมูล'),
          }),
        )
      }
    })()
  }, [medicalQueryData])

  return (
    <>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationForm}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <div
              className="rounded-[10px] px-[18px] pt-[18px] pb-[30px] mt-[25px] bg-white-main"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <p className="py-[13px] px-[18px] mb-[40px] bg-gray-light rounded-[5px] text-[18px] font-bold">
                ข้อมูลคนไข้
              </p>

              {!userData.fetching &&
              !userData.error &&
              userDataOption.length > 0 ? (
                <>
                  {formik.isSubmitting}
                  <ElementComponent.SearchBar.SearchBar
                    name="เลือกคนไข้ที่ต้องการเพิ่มข้อมูล"
                    label="เลือกคนไข้ที่ต้องการเพิ่มข้อมูล"
                    isLoading={userData.fetching}
                    isSubmitting={formik.isSubmitting}
                    data={searchTable as any}
                    error={formik.touched.uId && Boolean(formik.errors.uId)}
                    errorText={formik.errors.uId as string}
                    option={userDataOption}
                    onSelect={(value) => handleUserChange(value, formik)}
                  />
                </>
              ) : (
                <div className="flex flex-col items-center">
                  <p className="mt-[30px]">Loading</p>
                </div>
              )}
            </div>
            <div
              className="rounded-[10px] px-[18px] pt-[18px] pb-[30px] mt-[25px] bg-white-main"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <p className="py-[13px] px-[18px] mb-[40px] bg-gray-light rounded-[5px] text-[18px] font-bold">
                ข้อมูลทั่วไป
              </p>

              <GeneralDieaseForm
                loading={medicalMutate.fetching}
                formData={formik}
                diseaseList={diseaseList}
              />
            </div>

            <div
              className="rounded-[10px] px-[18px] pt-[18px] pb-[30px] mt-[25px] bg-white-main"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <div className="flex justify-between items-center py-[13px] px-[10px] laptop:px-[18px] mb-[40px] bg-gray-light rounded-[5px]">
                <p className="text-[14px] laptop:text-[18px] font-bold">
                  ข้อมูลเฉพาะทาง
                </p>

                <div className="bg-white-main shrink">
                  <ElementComponent.Button
                    disable={medicalMutate.fetching}
                    id="add-disease-specific"
                    icon="tabler:plus"
                    shape="outlined"
                    text="เพิ่มข้อมูลเฉพาะทาง"
                    width="100%"
                    onAction={() => setOpenDiseaseModal(true)}
                  />
                </div>
              </div>

              {diseaseSpecific.length >= 1 ? (
                <div className="flex flex-col gap-[20px]">
                  {diseaseSpecific.map((value, index: number) => (
                    <SpecificDiseaseForm
                      key={`diseaseSpecific-form-${index}`}
                      loading={medicalMutate.fetching}
                      data={value}
                      formData={formik}
                      onRemove={() => handleRemoveDiseaseSpecific(index)}
                      onSetFormData={(newField: any) => {
                        setFormData({ ...formik.values, ...newField })
                      }}
                      onSetValidateForm={(newSchema: any) =>
                        setValidationForm(
                          yup
                            .object()
                            .shape({ ...validationForm.fields, ...newSchema }),
                        )
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <NoDiseaseDataSVG />
                  <p className="mt-[30px]">ตอนนี้คุณยังไม่มีข้อมูลโรคแสดง</p>
                </div>
              )}

              <ModalComponent.SpecificDataModal
                diseaseList={diseaseSpecific.map(
                  (values: DiseaseDataSpeicific) => {
                    return values.disease.dId
                  },
                )}
                open={openDiseaseModal}
                onClose={() => setOpenDiseaseModal(false)}
                onSelect={(values) => {
                  setDiseaseSpecific([...diseaseSpecific, values])
                }}
              />
            </div>

            <div
              className="mt-[50px] flex 
            justify-center md:justify-end"
            >
              <ElementComponent.Button
                id="submit-health-form"
                style="w-full md:w-1/3 "
                submit
                loading={medicalMutate.fetching || medicalQueryData.fetching}
                text="ยืนยันส่งข้อมูล"
                width="100%"
                height="52px"
              />
            </div>
          </Form>
        )}
      </Formik>

      <AlertComponent.ErrorModal
        title={errorModalData.title}
        description={errorModalData.description}
        open={errorModalData.open}
        onClose={onCloseErrorModal}
      />
    </>
  )
}

export default AddHealthForm
