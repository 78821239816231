import {
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { AlertComponent, ElementComponent, MenuComponent } from 'components'
import { IcoSampleImg } from 'assets/svg'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import { useMutation, useQuery } from 'urql'
import { UserQuery } from 'services/graphql/userService'
import { useState } from 'react'
import dayjs from 'dayjs'
import { general } from 'utils'
import { route } from 'settings'
import { Formik, Form } from 'formik'

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]
type ConsultType = {
  placeName: string
  dateConsult: string
  time: string
  ctId: string
  textConsult: string
  uid: string
  temp: string
  bp_sys: string
  bp_dia: string
  hr: string
  rr: string
  doctorUid: string
  isDiagnose: boolean
  isPayedDrug: boolean
  typeConsult: string
}

interface IConsultSubmit {
  data: {
    placeName: string
    dateConsult: string
    time: string
    ctId: string
    textConsult: string
    uid: string
    temp: string
    bp_sys: string
    bp_dia: string
    hr: string
    rr: string
    doctorUid: string
    isDiagnose: boolean
    isPayedDrug: boolean
    typeConsult: string
  }
}

export default function ClinicVisitOpenPage() {
  const [dateValue] = useState<Value>(new Date())

  const [ctId, setCtId] = useState<string>('')
  const [textConsult, setTextConsult] = useState<string>('')
  const [uid, setUid] = useState<string>('')
  const [temp, setTemp] = useState<string>('')
  const [bp_sys, setBpSys] = useState<string>('')
  const [bp_dia, setBpDia] = useState<string>('')
  const [hr, setHr] = useState<string>('')
  const [rr, setRr] = useState<string>('')
  const [doctorUid, setDoctorUid] = useState<string>('')
  const [ercId, setErcId] = useState<string>('')
  const [docId, setDocId] = useState<string>('')

  const initialValues: ConsultType = {
    placeName: '',
    dateConsult: dateValue.toString(),
    time: '00.00',
    ctId: ctId,
    textConsult: textConsult,
    uid: uid,
    temp: temp,
    bp_sys: bp_sys,
    bp_dia: bp_dia,
    hr: hr,
    rr: rr,
    doctorUid: doctorUid,
    isDiagnose: false,
    isPayedDrug: false,
    typeConsult: 'walkin',
  }
  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })
  const [createConsultMutation, executeMutationConsult] = useMutation(
    MedicalMutate.createConsult,
  )
  const [EnterPriseData] = useQuery({
    ...UserQuery.getEnterpriseTreatmentPlaces(),
  })

  const createConsult = async (values: ConsultType) => {
    let payload: IConsultSubmit = {
      data: {
        placeName: values.placeName,
        dateConsult: dayjs(values.dateConsult).format('DD/MM/YYYY'),
        time: values.time,
        ctId: values.ctId,
        textConsult: values.textConsult,
        uid: values.uid,
        temp: values.temp,
        bp_sys: values.bp_sys,
        bp_dia: values.bp_dia,
        hr: values.hr,
        rr: values.rr,
        doctorUid: values.doctorUid,
        isDiagnose: false,
        isPayedDrug: false,
        typeConsult: 'walkin',
      },
    }
    const result = await executeMutationConsult(payload)
    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: 'กรุณาลองใหม่อีกครั้ง' + JSON.stringify(result.error),
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: '',
      onClose: () => {
        general.http.goto(route.USER_MANAGEMENT_CLINIC_PAGE_URL)
      },
    })

    return
  }

  const [patientSelected, setPatientSelected] = useState<any>({})
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [consultTypeData] = useQuery(MedicalQuery.getConsultType())
  const [doctorData] = useQuery({
    ...UserQuery.getUsers({
      where: {
        role: {
          is: {
            name: {
              equals: 'DOCTOR',
            },
          },
        },
      },
    }),
  })

  const [patientData] = useQuery({
    ...UserQuery.getUsers({
      where: {
        role: {
          is: {
            name: {
              equals: 'PATIENT',
            },
          },
        },
      },
    }),
  })
  const menuList = [
    {
      title: 'เปิด visit ผู้ป่วย',
      link: '',
    },
  ]
  let patientPreData = []
  if (!patientData.fetching && !patientData.error) {
    patientPreData = patientData.data.getUsers
    patientPreData.map((item: any, index: number) => {
      patientPreData[index].label =
        'HN : ' +
        item.hnId +
        ' ปชช : ' +
        item.UserAuth.identifyId +
        ' ' +
        item.firstname +
        ' ' +
        item.lastname
    })
  }
  return (
    <div>
      <div className="pl-[70px] mt-[40px]">
        <MenuComponent.MenuHeaderBar menuList={menuList} />
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={createConsult}
      >
        {(formik) => (
          <Form>
            <div
              className="p-[50px]  m-[70px] mt-[40px]"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <Grid container columns={{ desktop: 2.05 }} gap="8px">
                <Grid item desktop={1.0} className=" p-[8px]">
                  <div className="flex gap-[5px]">
                    <Autocomplete
                      onChange={(_, value) => {
                        if (value === null) {
                          setIsSelected(false)
                          setErcId('')
                          setDocId('')
                          return
                        }
                        setErcId(value.ercId)
                        setDocId(value.docId)
                        setUid(value.uId)
                        setPatientSelected(value)
                        setIsSelected(true)
                      }}
                      className="w-[100%]"
                      options={patientPreData}
                      noOptionsText="ค้นหาจาก ชื่อ-นามสกุล"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          placeholder="ค้นหาจาก ชื่อ-นามสกุล"
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 3.05 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.3} className=" p-[8px]">
                  <IcoSampleImg className="h-[80px] " />
                </Grid>
                <Grid item desktop={0.4} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-4 text-center">
                    ชื่อ นามสกุล
                  </p>
                </Grid>
                <Grid item desktop={1.7} className=" p-[8px]">
                  <div className=" mt-3">
                    <TextField
                      className="w-[100%]"
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      InputProps={{
                        sx: { borderRadius: 10 },
                      }}
                      value={
                        !isSelected
                          ? ''
                          : patientSelected.firstname +
                            ' ' +
                            patientSelected.lastname
                      }
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 4.1 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.5} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    HN
                  </p>
                </Grid>
                <Grid item desktop={1.5} className=" p-[8px]">
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: 10 },
                    }}
                    value={!isSelected ? '' : patientSelected.hnId}
                  />
                </Grid>
                <Grid item desktop={0.5} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    ภูมิลำเนา
                  </p>
                </Grid>
                <Grid item desktop={1.5} className=" p-[8px]">
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: 10 },
                    }}
                    value={
                      !isSelected
                        ? ''
                        : patientSelected.UserMedicalDemograhicData.province
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 4.1 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.5} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    วันเกิด
                  </p>
                </Grid>
                <Grid item desktop={1.5} className=" p-[8px]">
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: 10 },
                    }}
                    value={
                      !isSelected
                        ? ''
                        : dayjs(
                            patientSelected.UserMedicalDemograhicData.birthdate,
                          ).format('DD/MM/BBBB')
                    }
                  />
                </Grid>
                <Grid item desktop={0.5} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    เเพ้ยา-เเพ้อาหาร
                  </p>
                </Grid>
                <Grid item desktop={1.5} className=" p-[8px]">
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: 10 },
                    }}
                    value={
                      !isSelected
                        ? ''
                        : patientSelected.UserMedicalDemograhicData.allergic
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 4.1 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.5} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    เบอร์โทรศัพท์
                  </p>
                </Grid>
                <Grid item desktop={1.5} className=" p-[8px]">
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: 10 },
                    }}
                    value={
                      !isSelected ? '' : patientSelected.UserAuth.phoneNumber
                    }
                  />
                </Grid>
                <Grid item desktop={0.5} className=" p-[8px]">
                  <p className="text-[12px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                    บัตรประจำตัวประชาชน
                  </p>
                </Grid>
                <Grid item desktop={1.5} className=" p-[8px]">
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: 10 },
                    }}
                    value={
                      !isSelected ? '' : patientSelected.UserAuth.identifyId
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 4.1 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.5} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    หน่วยงานที่ดูเเล
                  </p>
                </Grid>
                <Grid item desktop={1.5} className=" p-[8px]">
                  <Select
                    className="w-[100%]"
                    size="small"
                    name=""
                    variant="outlined"
                    value={isSelected && ercId}
                  >
                    {!EnterPriseData.fetching &&
                      !EnterPriseData.error &&
                      EnterPriseData.data.getEnterpriseTreatmentPlaces.map(
                        (item: any) => (
                          <MenuItem value={item.ercId}>
                            {item.thaiName}
                          </MenuItem>
                        ),
                      )}
                  </Select>
                </Grid>
                <Grid item desktop={0.5} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    เเพทย์ประจำตัว
                  </p>
                </Grid>
                <Grid item desktop={1.5} className=" p-[8px]">
                  <Select
                    className="w-[100%]"
                    size="small"
                    name=""
                    variant="outlined"
                    value={isSelected && docId}
                  >
                    {!doctorData.fetching &&
                      !doctorData.error &&
                      doctorData.data.getUsers.map((item: any) => (
                        <MenuItem value={item.uId}>
                          {item.firstname} {item.lastname}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.25} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    โรคประจำตัว
                  </p>
                </Grid>
                <Grid item desktop={1.75} className=" p-[8px]">
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: 10 },
                    }}
                    value={
                      !isSelected
                        ? ''
                        : patientSelected.UserMedicalDemograhicData?.userUnderlyingDiseaseTrasaction
                            .map((item: any) => item.underlyingDisease.thaiName)
                            .join(',')
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.25} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    ปรึกษาเรื่อง
                  </p>
                </Grid>
                <Grid item desktop={1.0} className=" p-[8px]">
                  <Select
                    className="w-[100%]"
                    size="small"
                    name=""
                    variant="outlined"
                    onChange={(event: SelectChangeEvent) =>
                      setCtId(event.target.value)
                    }
                  >
                    {!consultTypeData.fetching &&
                      !consultTypeData.error &&
                      consultTypeData.data.getConsultType.map(
                        (item: { ctId: string; typeName: string }) => (
                          <MenuItem value={item.ctId}>{item.typeName}</MenuItem>
                        ),
                      )}
                  </Select>
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.25} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    อาการสำคัญ
                  </p>
                </Grid>
                <Grid item desktop={1.75} className=" p-[8px]">
                  <TextField
                    rows={4}
                    multiline={true}
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    InputProps={{
                      sx: { borderRadius: 5 },
                    }}
                    onChange={(event) => setTextConsult(event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.25} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    สัญญาณชีพ
                  </p>
                </Grid>
                <Grid item desktop={1.75} className=" p-[8px]">
                  <Grid
                    container
                    columns={{ desktop: 2.02 }}
                    gap="8px"
                    className="mt-2"
                  >
                    <Grid item desktop={1.0}>
                      <Stack
                        direction={{ laptop: 'row' }}
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <div>
                          <p>Temp</p>
                          <p className="text-[10px]">อุณหภูมิ</p>
                        </div>
                        <TextField
                          className="w-[70%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          InputProps={{
                            sx: { borderRadius: 10 },
                          }}
                          onChange={(event) => setTemp(event.target.value)}
                        />
                        <p className="text-gray-main">C</p>
                      </Stack>
                    </Grid>
                    <Grid item desktop={1.0}>
                      <Stack
                        direction={{ laptop: 'row' }}
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <div>
                          <p>BP</p>
                          <p className="text-[10px]">ความดันโลหิต</p>
                        </div>
                        <TextField
                          className="w-[35%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          InputProps={{
                            sx: { borderRadius: 10 },
                          }}
                          onChange={(event) => setBpSys(event.target.value)}
                        />
                        /
                        <TextField
                          className="w-[35%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          InputProps={{
                            sx: { borderRadius: 10 },
                          }}
                          onChange={(event) => setBpDia(event.target.value)}
                        />
                        <p className="text-gray-main">mmHg</p>
                      </Stack>
                    </Grid>
                    <Grid item desktop={1.0}>
                      <Stack
                        direction={{ laptop: 'row' }}
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <div>
                          <p>HR</p>
                          <p className="text-[10px]">ชีพจร</p>
                        </div>
                        <TextField
                          className="w-[70%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          InputProps={{
                            sx: { borderRadius: 10 },
                          }}
                          onChange={(event) => setHr(event.target.value)}
                        />
                        <p className="text-gray-main">bmp</p>
                      </Stack>
                    </Grid>
                    <Grid item desktop={1.0}>
                      <Stack
                        direction={{ laptop: 'row' }}
                        justifyContent="space-around"
                        alignItems="center"
                      >
                        <div>
                          <p>RR</p>
                          <p className="text-[10px]">อัตราหายใจ</p>
                        </div>
                        <TextField
                          className="w-[70%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          InputProps={{
                            sx: { borderRadius: 10 },
                          }}
                          onChange={(event) => setRr(event.target.value)}
                        />
                        <p className="text-gray-main">/min</p>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.25} className=" p-[8px]">
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] mt-1 text-center">
                    แพทย์ผู้ตรวจ
                  </p>
                </Grid>
                <Grid item desktop={1.0} className=" p-[8px]">
                  <Select
                    className="w-[100%]"
                    size="small"
                    name=""
                    variant="outlined"
                    onChange={(event: SelectChangeEvent) =>
                      setDoctorUid(event.target.value)
                    }
                  >
                    {!doctorData.fetching &&
                      !doctorData.error &&
                      doctorData.data.getUsers.map((item: any) => (
                        <MenuItem value={item.uId}>
                          {item.firstname} {item.lastname}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
              <ElementComponent.Button
                //submit
                id="submit-diagnose-button"
                width="w-main"
                height="32px"
                text="ยืนยันส่งข้อมูล"
                style="mt-2 ml-[40%]"
                loading={createConsultMutation.fetching}
                onAction={() => {
                  if (!isSelected) {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาเลือกผู้ป่วย',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else if (ctId === '') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาเลือก ปรึกษาเรื่อง',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else if (doctorUid === '') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาเลือก แพทย์ผู้ตรวจ',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else {
                    formik.submitForm()
                  }
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
      {submitPopupData.type === 'success' ? (
        <AlertComponent.SuccessModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      ) : (
        <AlertComponent.ErrorModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      )}
    </div>
  )
}
