import React from 'react'
import { Icon } from '@iconify/react'

import { route } from 'settings'
import { MenuComponent, FormComponent } from 'components'

const menuList = [
  { title: 'จัดการข้อมูลสุขภาพ', link: route.HEALTH_DATA_MANAGEMENT_URL },
  { title: 'เพิ่ม', link: '' },
]

export default function AddHealthDataManagementPage() {
  return (
    <div className="laptop:ml-[245px] laptop:mr-[245px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />

      <div className="flex items-center gap-[13px] mt-[25px]">
        <Icon icon="mdi:heart-plus" className="text-[28px]" />
        <p className="text-[20px] font-bold">เพิ่มข้อมูลสุขภาพ</p>
      </div>

      <FormComponent.HealthForm.AddHealthForm />
    </div>
  )
}
