import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { Icon } from '@iconify/react'
import { ElementComponent } from 'components'

interface ImageFormProps {
  loading?: boolean
  disable: boolean
  exampleImage?: string
  onChoose?: any
}

function ImageForm({
  loading = false,
  disable,
  exampleImage,
  onChoose,
}: ImageFormProps) {
  const [images, setImages] = useState([])

  const removeImage = (index: number) => {
    let newObject = [...images]
    newObject.splice(index, 1)
    setImages(newObject)
    onChoose(newObject)
    //formData.setFieldValue(medName, newObject)
  }

  return (
    <div className="px-[10px] pb-[20px]">
      <div className="flex flex-col gap-[50px]">
        {exampleImage !== '' && !disable && (
          <div>
            <Stack
              direction={{ mobile: 'column', laptop: 'row' }}
              gap="15px"
              className="items-center"
            >
              {exampleImage.split(',').map((imagePath: any, index: number) => (
                <div
                  className="w-full h-[300px] laptop:w-[300px] laptop:h-[210px]"
                  key={`exmaple-image-${index}`}
                >
                  <ElementComponent.Image
                    className="w-full h-full object-cover bg-center rounded-[10px]"
                    src={`${imagePath}`}
                    alt=""
                  />
                </div>
              ))}
            </Stack>
          </div>
        )}

        <div>
          <Stack
            direction={{ mobile: 'column', laptop: 'row' }}
            gap="15px"
            className="items-center flex-wrap"
          >
            {images !== null &&
              images.map((item: any, index: number) => (
                <div
                  key={`image-upload-${index}`}
                  className="w-full h-[100px] laptop:w-[100px] laptop:h-[100px] relative"
                >
                  {!disable && (
                    <div
                      onClick={() => removeImage(index)}
                      className="absolute top-0 right-0 p-[5px] border-indigo-900 bg-blue-main hover:brightness-75 cursor-pointer rounded-tr-[10px]"
                    >
                      <Icon
                        icon="mdi:bin"
                        className="h-[25px] w-[25px] text-white-main"
                      />
                    </div>
                  )}
                  <ElementComponent.Image
                    className="h-full w-full object-cover bg-center rounded-[10px] border"
                    src={
                      typeof item === 'string'
                        ? item
                        : URL.createObjectURL(item)
                    }
                    alt=""
                  />
                </div>
              ))}

            {images.length < 4 && !disable && (
              <div>
                <ElementComponent.ImageButton
                  isLoading={loading}
                  id="image-upload-specific-disease"
                  width={'100px'}
                  height={'100px'}
                  text={
                    Array.isArray(images)
                      ? images.length >= 1
                        ? `${3 - images.length}/3`
                        : undefined
                      : undefined
                  }
                  onChange={(event) => {
                    if (event.target.files) {
                      setImages([...images, event.target.files[0]])
                      onChoose([...images, event.target.files[0]])
                      /*formData.setFieldValue(medName, {
                        ...formData.images,
                        value: [
                          ...formData.images[medName],
                          event.target.files[0],
                        ],
                      })*/
                    }
                  }}
                />
              </div>
            )}
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default ImageForm
