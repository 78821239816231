import React from 'react'

import { ElementComponent } from 'components'
import { RegisterType } from 'types'
import { useResponsive } from 'hooks'

const prefixDoctorConfig: ElementComponent.SelectBar.OptionSelectType[] = [
  { key: 'น.พ.', value: 'น.พ.' },
  { key: 'พ.ญ.', value: 'พ.ญ.' },
]

const prefixPatientConfig: ElementComponent.SelectBar.OptionSelectType[] = [
  { key: 'นาย', value: 'นาย' },
  { key: 'นาง', value: 'นาง' },
  { key: 'นางสาว', value: 'นางสาว' },
  { key: 'เด็กหญิง', value: 'เด็กหญิง' },
  { key: 'เด็กชาย', value: 'เด็กชาย' },
]

const genderConfig: ElementComponent.SelectBar.OptionSelectType[] = [
  { key: 'ชาย', value: 'ชาย' },
  { key: 'หญิง', value: 'หญิง' },
]

export default function PersonForm({
  form,
  isLoading,
  require_email = false,
  require_phone = false,
  require_citizen_id = false,
  prefix_option = RegisterType.PrefixOption.PATIENT,
}: RegisterType.FormProps) {
  const { isMobile } = useResponsive()

  return (
    <div className="flex flex-col gap-[20px]">
      <div className={`flex flex-col md:flex-row gap-[20px] md:gap-[6px]`}>
        <div className="flex-1 md:basis-4/12">
          <ElementComponent.SelectBar.SelectBar
            label="คำนำหน้า"
            name="prefix"
            isLoading={isLoading}
            placeholder="คำนำหน้า"
            width="w-full"
            focusSpacing={isMobile}
            data={form.values.prefix}
            option={
              prefix_option === RegisterType.PrefixOption.PATIENT
                ? prefixPatientConfig
                : prefixDoctorConfig
            }
            error={form.touched.prefix && Boolean(form.errors.prefix)}
            errorText={form.errors.prefix}
            onChange={(text: string) => form.setFieldValue('prefix', text)}
          />
        </div>

        <div className="flex-1 md:basis-8/12">
          <ElementComponent.InputBar
            id="firstname-input"
            label="ชื่อจริง"
            placeholder="ชื่อจริง"
            name="firstname"
            width="100%"
            height="51px"
            focusSpacing={isMobile}
            value={form.values.firstname}
            errorText={form.errors.firstname}
            error={form.touched.firstname && Boolean(form.errors.firstname)}
            onChange={(text: string) => form.setFieldValue('firstname', text)}
            isLoading={isLoading}
          />
        </div>
      </div>

      <ElementComponent.InputBar
        id="lastname-input"
        label="นามสกุล"
        placeholder="นามสกุล"
        name="lastname"
        width="100%"
        height="50px"
        value={form.values.lastname}
        errorText={form.errors.lastname}
        error={form.touched.lastname && Boolean(form.errors.lastname)}
        onChange={(text: string) => form.setFieldValue('lastname', text)}
        isLoading={isLoading}
      />

      <ElementComponent.SelectBar.SelectBar
        label="เพศ"
        placeholder="เพศ"
        name="gender"
        isLoading={isLoading}
        width={isMobile ? 'w-full' : 'w-main'}
        data={form.values.gender}
        option={genderConfig}
        errorText={form.errors.gender}
        error={form.touched.gender && Boolean(form.errors.gender)}
        onChange={(text: string) => form.setFieldValue('gender', text)}
      />

      <ElementComponent.DatePicker
        isLoading={isLoading}
        dateValue={form.values.birthdate}
        name={'birthdate'}
        isSubmitting={form.isSubmitting}
        label={{ day: 'วันเกิด', month: 'เดือนเกิด', year: 'ปีเกิด' }}
        errorText={form.errors.birthdate}
        error={form.touched.birthdate && Boolean(form.errors.birthdate)}
        onChange={(text: string) => form.setFieldValue('birthdate', text)}
      />

      {require_email && (
        <ElementComponent.InputBar
          id="email-input"
          label="อีเมล"
          placeholder="อีเมล"
          name="email"
          width="100%"
          height="50px"
          value={form.values.email}
          errorText={form.errors.email}
          error={form.touched.email && Boolean(form.errors.email)}
          onChange={(text: string) => form.setFieldValue('email', text)}
          isLoading={isLoading}
        />
      )}

      {require_phone && (
        <ElementComponent.InputBar
          id="phone-number-input"
          label="เบอร์โทรศัพท์"
          placeholder="เบอร์โทรศัพท์"
          name="phoneNumber"
          width="100%"
          height="50px"
          value={form.values.phoneNumber}
          errorText={form.errors.phoneNumber}
          error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
          onChange={(text: string) => form.setFieldValue('phoneNumber', text)}
          isLoading={isLoading}
        />
      )}

      {require_citizen_id && (
        <ElementComponent.InputBar
          id="citizen-id-input"
          label="เลขบัตรประชาชน"
          placeholder="เลขบัตรประชาชน"
          name="citizenId"
          width="100%"
          height="50px"
          value={form.values.citizenId}
          errorText={form.errors.citizenId}
          error={form.touched.citizenId && Boolean(form.errors.citizenId)}
          onChange={(text: string) => form.setFieldValue('citizenId', text)}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}
