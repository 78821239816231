const APP_SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api/data`
const GRAPHQL_DATA_URL = `${APP_SERVER_URL}/graphql`

//* External APIs
const PROVINCE_DATA_URL =
  'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json'

const DISTRICT_DATA_URL =
  'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json'

const SUBDISTRICT_DATA_URL =
  'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_tambon.json'

export {
  APP_SERVER_URL,
  GRAPHQL_DATA_URL,
  PROVINCE_DATA_URL,
  DISTRICT_DATA_URL,
  SUBDISTRICT_DATA_URL,
}
