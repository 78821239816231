import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, Skeleton } from '@mui/material'
import { Field } from 'formik'
import { styled } from '@mui/material/styles'

export type OptionType = {
  key: string
  value: string
}

interface AutocompleteOption {
  label: string
  value: string
}

interface SearchBarProps {
  isLoading?: boolean
  name: string
  width?: string
  height?: string
  isSubmitting: boolean
  data: OptionType
  error?: boolean
  errorText?: string
  option: OptionType[]
  disabled?: boolean
  label: string
  onSelect: Function
  focusSpacing?: boolean
}

interface TextFieldStyleProps {
  height: string
  width: string
  error: boolean
  disabled: boolean
}

const TextFieldStyle = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'width' && prop !== 'error',
})<TextFieldStyleProps>(({ height, width, error, disabled }) => {
  let border_color_hover = 'rgba(201, 226, 244, 1)'
  if (error) border_color_hover = 'rgba(228, 0, 9, 1)'
  if (disabled) border_color_hover = '#E7E7E7'

  return {
    '& .MuiOutlinedInput-root': {
      height: height,
      width: width,
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
      '& fieldset': {
        borderColor: error ? 'rgba(228, 0, 9, 1)' : '#E7E7E7',
      },
      '&:hover fieldset': {
        borderColor: border_color_hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: error ? 'rgba(228, 0, 9, 1)' : 'rgba(20, 117, 187, 1)',
      },
      '&.Mui-disabled': {
        backgroundColor: '#F1F1F2',
        '& > fieldset': {
          borderColor: '#E7E7E7',
        },
      },
    },
    '& input::placeholder': {
      fontSize: '16px',
    },
  }
})

function SearchBar({
  isLoading = false,
  name,
  data,
  option,
  isSubmitting,
  disabled = false,
  label,
  width = '100%',
  height = '50px',
  error = false,
  errorText,
  onSelect,
  focusSpacing = true,
}: SearchBarProps) {
  const [cliked, setCliked] = useState(false)

  const onHandleClick = (e, defaultOnClick) => {
    setCliked(true)

    if (defaultOnClick) defaultOnClick(e)
  }

  const onHandleBlur = (e, defaultOnblur) => {
    setCliked(false)

    if (defaultOnblur) defaultOnblur(e)
  }

  const renderInput = (params) => {
    return (
      <TextFieldStyle
        {...params}
        disabled={disabled}
        onBlur={(e) => onHandleBlur(e, params.onBlur)}
        onClick={(e) => onHandleClick(e, params.onClick)}
        width={width}
        height={height}
        error={!disabled && error}
        inputProps={{
          ...params.inputProps,
          placeholder: !disabled && cliked ? '' : label,
        }}
      />
    )
  }

  const handleOption = () => {
    return option?.map((item: OptionType) => ({
      label: item.key,
      value: item.value,
    }))
  }

  useEffect(() => {
    if (!disabled && error && isSubmitting) {
      const element = document.getElementById(`error-${name}`)
      if (element) {
        element.scrollIntoView({ block: 'center' })
        element.focus({ preventScroll: true })
      }
    }
  }, [disabled, error, isSubmitting])

  return !isLoading ? (
    <div
      className={
        focusSpacing && ((!disabled && cliked) || data)
          ? 'mt-[20px] transition-all ease-in-out'
          : ''
      }
    >
      <Field id={name} name={name}>
        {({ field }) => (
          <div>
            <div className="relative w-fit">
              <p
                className={`text-black-main text-[16px] w-auto break-normal whitespace-nowrap text-left pl-1 font-bold ${
                  (!disabled && cliked) || data
                    ? 'absolute bottom-[0.25px] duration-300 delay-100 transition-all ease-in-out'
                    : 'absolute top-0.25 p-2 transition-all ease-in-out'
                }`}
              >
                {label}
              </p>
            </div>
            <Autocomplete
              {...field}
              id={name}
              name={name}
              defaultValue={option[0].key}
              value={data || option[0].key}
              getOptionDisabled={(options: AutocompleteOption) =>
                options.label === option[0].key
              }
              isOptionEqualToValue={(
                options: AutocompleteOption,
                value: string,
              ) => options.label === value}
              onChange={(_, value: AutocompleteOption) => {
                if (value !== null) onSelect(value)
              }}
              disabled={disabled}
              options={handleOption()}
              renderInput={renderInput}
              sx={{
                fontSize: '16px',
                '& .MuiInputBase-input': {
                  color:
                    !data || data.key === option[0].key
                      ? '#00000050'
                      : '#000000',
                },
              }}
            />
          </div>
        )}
      </Field>
      {!disabled && error && (
        <p
          id={`error-${name}`}
          className="text-red-main text-[14px] mt-[6px] font-[400]"
        >
          {errorText}
        </p>
      )}
    </div>
  ) : (
    <div>
      <Skeleton variant="rounded" width={width} height={height} />
    </div>
  )
}

export { SearchBar }
