import React, { useState, useRef } from 'react'
import { useMutation } from 'urql'
import { Formik, Form } from 'formik'
import { Icon } from '@iconify/react'
import Resizer from 'react-image-file-resizer'
import dayjs from 'dayjs'
import * as yup from 'yup'

import { UserMutate } from 'services/graphql/userService'
import { route } from 'settings'
import { ProfileType, RegisterType } from 'types'
import { useResponsive } from 'hooks'
import { AvatarProfile } from 'assets/svg'

import {
  ElementComponent,
  FormComponent,
  AlertComponent,
  MenuComponent,
} from 'components'

interface UserProfileFormProps {
  onOpenModal: Function
  setErrorModalData: React.Dispatch<
    React.SetStateAction<{
      title: string
      description: string
      open: boolean
    }>
  >
}

const phoneRegExp = /^(0[689]{1})+([0-9]{8})+$/

const validationProfile = yup.object({
  image: yup.string().nullable(),
  prefix: yup.string().required('กรุณาเลือกคำนำหน้า'),
  firstname: yup
    .string()
    .max(30, 'กรุณากรอกชื่อจริงไม่เกิน 30 ตัวอักษร')
    .required('กรุณากรอกชื่อจริงไม่เกิน 30 ตัวอักษร'),
  lastname: yup
    .string()
    .max(30, 'กรุณากรอกนามสกุลไม่เกิน 30 ตัวอักษร')
    .required('กรุณากรอกนามสกุลไม่เกิน 30 ตัวอักษร'),
  gender: yup.string().required('กรุณาเลือกเพศ'),
  birthdate: yup
    .date()
    .test(
      'check-date-more-than-today',
      'วันเกิดไม่ครวเป็นวันในอนาคต',
      (value) => {
        if (dayjs().add(543, 'year').isBefore(dayjs(value))) return false
        return true
      },
    )
    .test(
      'check-date-more-less-than-130-year',
      'ต้องขออภัย ระบบไม่รองรับคนที่อายุมากกว่า 130 ปี',
      (value) => {
        if (dayjs(value) < dayjs().subtract(130, 'year')) return false
        return true
      },
    )
    .typeError(
      'รูปแบบของวันที่ไม่ถูกต้อง โปรดระบุในรูปแบบ วัน/เดือน/ปี และ ปีเป็น พศ.',
    )
    .nullable()
    .required('กรุณาเลือกวัน/เดือน/ปี เกิด'),
  province: yup.string().required('กรุณาเลือกจังหวัด'),
  district: yup.string().required('กรุณาเลือก อำเภอ/เขต'),
  email: yup.string().email('รูปแบบอีเมล์ไม่ถูกต้อง').nullable(),
  identifyId: yup
    .string()
    .max(5, 'กรุณากรอกไม่เกิน 5 ตัวเลข')
    .required('กรุณากรอกเลขใบประกอบวิชาชีพ'),
  password: yup
    .string()
    .min(5, 'กรุณากรอกรหัสผ่านที่มีมีความยาวตั้งแต่ 5 ถึง 32')
    .max(32, 'กรุณากรอกรหัสผ่านที่มีมีความยาวตั้งแต่ 5 ถึง 32 ')
    .required('กรุณาตั้งรหัสผ่านใหม่'),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง'),
})

function UserProfileForm({
  onOpenModal,
  setErrorModalData,
}: UserProfileFormProps) {
  const { isMobile } = useResponsive()
  const uploadRef = useRef(null)
  const [uploadFile, setUploadFile] = useState<boolean>(false)
  const [imageStateError, setImageStateError] = useState<boolean>(false)

  const [, executeMutation] = useMutation(UserMutate.createDoctor)

  const initialValues: ProfileType.CreateDoctorUserForm = {
    image: '',
    prefix: '',
    firstname: '',
    lastname: '',
    gender: '',
    birthdate: new Date(),
    district: '',
    province: '',
    email: '',
    identifyId: '',
    phoneNumber: '',
    password: '',
  }

  const handleImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
    formData,
  ) => {
    const { files } = event.target

    if (files) {
      setImageStateError(false)
      setUploadFile(true)
      Resizer.imageFileResizer(
        files[0],
        200,
        200,
        'JPEG',
        100,
        0,
        (url) => formData.setFieldValue('image', url),
        'base64',
      )
    }
  }

  const handleErrorMessage = (
    text: string,
  ): { title: string; description: string } => {
    let result = {
      title: 'บันทึกข้อมูลไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
    }

    if (text === 'this user already exist in the system')
      return {
        title: 'มีผู้ใช้งานนี้ในระบบ',
        description: 'กรุณากรอกเลขใบประกอบวิชาชีพ ที่ไม่ซ้ำกับเลขนี้',
      }

    return result
  }

  const createUser = async (values: ProfileType.CreateDoctorUserForm) => {
    let submitData = {
      imagePath: values.image.split(',')[1],
      prefix: values.prefix,
      firstname: values.firstname,
      lastname: values.lastname,
      UserMedicalDemograhicData: {
        create: {
          birthdate: dayjs(values.birthdate).toDate(),
          gender: values.gender,
          district: values.district,
          province: values.province,
        },
      },
      role: {
        connect: {
          urId: '',
        },
      },
      UserAuth: {
        create: {
          email: values.email,
          identifyId: values.identifyId,
          phoneNumber: values.phoneNumber,
          password: values.password,
        },
      },
    }

    if (!uploadFile) {
      delete submitData.imagePath
    }

    await executeMutation({
      data: submitData,
      where: {
        uId: '',
      },
    }).then((res) => {
      if (res.error) {
        const error: any = res.error

        setErrorModalData({
          ...handleErrorMessage(error?.graphQLErrors[0]?.message),
          open: true,
        })
        return
      }

      onOpenModal()
    })
  }

  const imageOnError = () => {
    setImageStateError(true)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationProfile}
      onSubmit={createUser}
    >
      {(formik) => (
        <>
          <Form>
            <div
              className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px] laptop:p-[60px] text-[16px]"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <div className="flex justify-center mt-[40px] mb-[45px]">
                <div className="relative flex justify-center items-center w-[150px] h-[150px] bg-white-dark border border-gray-light rounded-full">
                  {!imageStateError && formik.values.image !== '' ? (
                    <ElementComponent.Image
                      fullImage={false}
                      className="w-[150px] h-[150px] bg-center bg-cover rounded-full"
                      src={formik.values.image}
                      onError={imageOnError}
                    />
                  ) : (
                    <AvatarProfile className="w-[150px] h-[150px] bg-center bg-cover rounded-full" />
                  )}

                  <input
                    className="hidden"
                    ref={uploadRef}
                    type="file"
                    accept="image/*"
                    onChange={(event) => handleImage(event, formik)}
                  />

                  <div className="absolute right-[10px] bottom-0 bg-blue-main rounded-full w-[28px] h-[28px] flex justify-center items-center cursor-pointer">
                    <Icon
                      icon="material-symbols:edit"
                      className="text-white-main"
                      onClick={() => uploadRef.current.click()}
                    />
                  </div>
                </div>
              </div>

              <div className="laptop:max-w-[470px] tablet:max-w-[630px] m-auto">
                <FormComponent.ProfileForm.PersonForm
                  form={formik}
                  isLoading={false}
                  prefix_option={RegisterType.PrefixOption.DOCTOR}
                  require_email
                  require_phone
                />
              </div>

              <hr className="border-t-[1px] border-dashed border-gray-dark mt-[30px] mb-[42px]" />

              <div className="laptop:max-w-[470px] tablet:max-w-[630px] m-auto mb-5">
                <FormComponent.ProfileForm.AddressForm
                  form={formik}
                  isLoading={false}
                />
              </div>

              <hr className="border-t-[1px] border-dashed border-gray-dark mt-[30px] mb-[42px]" />

              <div className="laptop:max-w-[470px] tablet:max-w-[630px] m-auto mb-5">
                <FormComponent.ProfileForm.AuthForm
                  form={formik}
                  isLoading={false}
                />
              </div>
            </div>

            <div className="flex justify-end pt-10">
              <ElementComponent.Button
                id="submit-button"
                submit
                text="ยืนยันส่งข้อมูล"
                width={isMobile ? '100%' : '260px'}
                height="52px"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default function AddDoctorUserPage() {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)

  const [errorModalData, setErrorModalData] = useState({
    title: 'บันทึกข้อมูลไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'บันทึกข้อมูลไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  const onCloseSuccessModal = () => {
    setOpenSuccessModal(false)
    window.location.href = route.USER_MANAGEMENT_PAGE_URL
  }

  const menuList = [
    { title: 'จัดการข้อมูลผู้ใช้งาน', link: route.USER_MANAGEMENT_PAGE_URL },
    { title: 'เพิ่มข้อมูลแพทย์', link: '' },
  ]

  return (
    <div className="pt-10 px-14">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      <div className="my-[25px] flex items-center justify-between">
        <div className="flex items-center gap-[10px]">
          <Icon icon="ic:baseline-person-add" className="w-[35px] h-[35px]" />
          <p className="text-[24px]">{`เพิ่มข้อมูลแพทย์`}</p>
        </div>
      </div>

      <UserProfileForm
        onOpenModal={() => setOpenSuccessModal(true)}
        setErrorModalData={setErrorModalData}
      />

      <AlertComponent.SuccessModal
        title="บันทึกข้อมูลเรียบร้อย"
        open={openSuccessModal}
        onClose={onCloseSuccessModal}
      />

      <AlertComponent.ErrorModal
        title={errorModalData.title}
        description={errorModalData.description}
        open={errorModalData.open}
        onClose={onCloseErrorModal}
      />
    </div>
  )
}
