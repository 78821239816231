import { Grid, Stack } from '@mui/material'
import { ElementComponent, MenuComponent } from 'components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MedicalQuery } from 'services/graphql/medicalService'
import { route } from 'settings'
import { useQuery } from 'urql'

function DrugOverviewPage() {
  const navigate = useNavigate()
  const menuList = [{ title: 'จัดการคลังยา', link: '' }]
  const [drugApiData] = useQuery(MedicalQuery.getDrugListWithName(''))
  return (
    <div className="p-[80px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      <div
        className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px]  text-[16px] mt-4"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Grid
          container
          justifyContent="center"
          columns={{ mobile: 3.3 }}
          gap="20px"
        >
          <Grid item mobile={1}>
            <Stack
              justifyItems="center"
              direction="column"
              spacing="10px"
              className="relative overflow-hidden rounded-[10px] bg-gray-200 p-[25px]  text-[18px] mt-4 text-center text-blue-main"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
              onClick={() => navigate(route.DRUG_MANAGEMENT_LIST_PAGE_URL)}
            >
              <p>จำนวนยาทั้งหมด</p>
              {!drugApiData?.fetching && !drugApiData.error ? (
                <p className="text-[48px]">
                  {drugApiData.data.getDrugList.length}
                </p>
              ) : (
                <p className="text-[48px]">0</p>
              )}

              <p>ตัว</p>
            </Stack>
          </Grid>
          <Grid item mobile={1}>
            <Stack
              justifyItems="center"
              direction="column"
              spacing="10px"
              className="relative overflow-hidden rounded-[10px] bg-gray-200 p-[25px]  text-[18px] mt-4 text-center text-blue-main"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <p>จำนวนยาที่ใกล้หมดอายุ</p>
              <p className="text-[48px]">0</p>
              <p>ตัว</p>
            </Stack>
          </Grid>
          <Grid item mobile={1}>
            <Stack
              justifyItems="center"
              direction="column"
              spacing="10px"
              className="relative overflow-hidden rounded-[10px] bg-gray-200 p-[25px]  text-[18px] mt-4 text-center text-blue-main"
              style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
            >
              <p>จำนวนยาที่ใกล้หมดสต๊อก</p>
              <p className="text-[48px]">0</p>
              <p>ตัว</p>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <ElementComponent.Button
        id="submit-health-form"
        text=" ดูรายชื่อยาทั้งหมด"
        width="100%"
        style="mt-4"
        onAction={() => navigate(route.DRUG_MANAGEMENT_LIST_PAGE_URL)}
      />
      <ElementComponent.Button
        id="submit-drug-type-form"
        text="วิธีบริหารยา"
        width="100%"
        style="mt-4"
        onAction={() => navigate(route.DRUG_TYPE_MANAGEMENT_LIST_PAGE_URL)}
      />

      <div
        className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px]  text-[16px] mt-4"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Stack
          direction="row"
          spacing="10px"
          justifyContent="space-between"
          className="text-[20px] text-blue-main"
        >
          <p>ยายอดนิยม</p>
          <ElementComponent.Button
            id="submit-health-form"
            text="เพิ่มยา"
            width="150px"
            onAction={() => navigate(route.DRUG_MANAGEMENT_ADD_PAGE_URL)}
          />
        </Stack>
        {/*<Stack
          direction="row"
          spacing="10px"
          justifyContent="space-between"
          className="text-[18px] mt-4"
        >
          <p>1. Metformin (Pipformin) 500 mg</p>
          <p>3,894 เม็ด</p>
        </Stack>
        <Stack
          direction="row"
          spacing="10px"
          justifyContent="space-between"
          className="text-[18px] mt-2"
        >
          <p>2. Amlodipine (GPO) 10 mg</p>
          <p>2,741 เม็ด</p>
        </Stack>
        <Stack
          direction="row"
          spacing="10px"
          justifyContent="space-between"
          className="text-[18px] mt-2"
        >
          <p>2. Amlodipine (GPO) 10 mg</p>
          <p>2,741 เม็ด</p>
        </Stack>
        <Stack
          direction="row"
          spacing="10px"
          justifyContent="space-between"
          className="text-[18px] mt-2"
        >
          <p>2. Amlodipine (GPO) 10 mg</p>
          <p>2,741 เม็ด</p>
        </Stack>
        <Stack
          direction="row"
          spacing="10px"
          justifyContent="space-between"
          className="text-[18px] mt-2"
        >
          <p>2. Amlodipine (GPO) 10 mg</p>
          <p>2,741 เม็ด</p>
        </Stack>
        <Stack
          direction="row"
          spacing="10px"
          justifyContent="space-between"
          className="text-[18px] mt-2"
        >
          <p>2. Amlodipine (GPO) 10 mg</p>
          <p>2,741 เม็ด</p>
        </Stack>*/}
      </div>
    </div>
  )
}

export default DrugOverviewPage
