import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'urql'
import { useNavigate } from 'react-router-dom'
import { Tooltip, Pagination } from '@mui/material'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Icon } from '@iconify/react'
import { Formik, Form } from 'formik'

import { MedicalQuery, MedicalMutate } from 'services/graphql/medicalService'
import { ElementComponent, SkeletonComponent, AlertComponent } from 'components'
import { route } from 'settings'
import { date } from 'utils'

const dataInputFlagFilterSelect: ElementComponent.SelectBar.OptionSelectType[] =
  [
    { value: 'ทั้งหมด', key: 'null' },
    { value: 'กรอกข้อมูลแล้ว', key: 'true' },
    { value: 'ยังไม่ได้กรอกข้อมูล', key: 'false' },
    { value: 'รอการยืนยันตัวตนเพื่อเชื่อมต่อข้อมูล', key: 'no-link' },
  ]

export default function HealthDataManagementPage() {
  const processQueryArgs = (args: {
    searchText: string
    state: string | null
  }) => {
    let query = {}
    if (args.searchText)
      query = {
        ...query,
        OR: [
          {
            patientUser: {
              is: {
                hnId: {
                  equals: parseInt(args.searchText),
                },
              },
            },
          },
          {
            patientUser: {
              is: {
                UserAuth: {
                  is: {
                    phoneNumber: {
                      contains: args.searchText,
                    },
                  },
                },
              },
            },
          },
        ],
      }

    if (args.state && (args.state === 'true' || args.state === 'false'))
      query = {
        ...query,
        dataInputFlag: {
          equals: args.state === 'true',
        },
        puId: {
          not: {
            equals: null,
          },
        },
      }

    if (args.state && args.state === 'no-link')
      query = {
        ...query,
        puId: {
          equals: null,
        },
      }

    return query
  }

  const navigate = useNavigate()
  const PAGE_SIZE = 10
  const topicTable = [
    'HN (DRASA)',
    'HN (สถานพยาบาล)',
    'ชื่อ-นามสกุล',
    'วันที่บันทึกข้อมูล',
    'สถานะ',
    'ตัวเลือก',
  ]

  const [filterDataTable, setFilterDataTable] = useState<{
    searchText: string
    state: string | null
  }>({
    searchText: '',
    state: null,
  })
  const [pageAmount, setPageAmount] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [openDeleteModal, setOpenDeleteModal] = useState<{
    umdId: string
    state: boolean
  }>({ umdId: '', state: false })

  const [medicalTableData, setMedicalTableData] = useState<any[]>([])
  const [medicalData, reexecuteMedicalDataQuery] = useQuery({
    ...MedicalQuery.getMedicalDatas(
      processQueryArgs(filterDataTable),
      PAGE_SIZE,
      (page - 1) * PAGE_SIZE,
    ),
  })

  const [medicalDataCount, reexecuteMedicalDataCountQuery] = useQuery({
    ...MedicalQuery.countMedicalData(processQueryArgs(filterDataTable)),
  })

  const [deleteMedical, executeDeleteMedical] = useMutation(
    MedicalMutate.deleteMedicalData,
  )

  const handleOnClickAddMedicalDataButton = () => {
    window.location.href = route.HEALTH_DATA_MANAGEMENT_ADD_URL
  }

  const handelOnDeleteMedicalData = async () => {
    await executeDeleteMedical({
      where: {
        umdId: openDeleteModal.umdId,
      },
    })
    reexecuteMedicalDataQuery()
    reexecuteMedicalDataCountQuery()
    setOpenDeleteModal({ umdId: '', state: false })
  }

  const option = (umdId: string, inputDataFlag: boolean, disable = true) => {
    const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#C9E2F4',
        color: '#1475BB',
        fontSize: 12,
      },
    })

    return (
      <div className="flex justify-center">
        <StyleToolTip title={`แก้ไขข้อมูลสุขภาพ`} color="info">
          <Icon
            icon="mdi:pencil"
            className={`w-[28px] h-[28px] ${
              disable || inputDataFlag
                ? 'cursor-not-allowed text-gray-main'
                : 'cursor-pointer hover:text-blue-main'
            }`}
            onClick={() =>
              (disable || !inputDataFlag) &&
              navigate(`${route.HEALTH_DATA_MANAGEMENT_URL}/${umdId}/update`)
            }
          />
        </StyleToolTip>
        <StyleToolTip title={`ลบข้อมูลสุขภาพ`} color="info">
          <Icon
            icon="mdi:bin"
            className={`w-[28px] h-[28px] ${
              disable
                ? 'cursor-not-allowed text-gray-main'
                : 'cursor-pointer text-red-main hover:text-blue-main'
            }`}
            onClick={() =>
              !disable && setOpenDeleteModal({ umdId: umdId, state: true })
            }
          />
        </StyleToolTip>
        <StyleToolTip title={`ดูข้อมูลสุขภาพ`} color="info">
          <Icon
            icon="material-symbols:article"
            className={`w-[28px] h-[28px] ${
              disable || !inputDataFlag
                ? 'cursor-not-allowed text-gray-main'
                : 'ccursor-pointer hover:text-blue-main'
            }`}
            onClick={() =>
              (disable || inputDataFlag) &&
              navigate(`${route.HEALTH_DATA_MANAGEMENT_URL}/${umdId}`)
            }
          />
        </StyleToolTip>
      </div>
    )
  }

  const formatData = (data) => {
    const process_color = (status) => {
      if (status === 'ยังไม่ได้กรอกข้อมูล') return 'red-main'

      if (status === 'กรอกข้อมูลแล้ว') return 'green-main'

      return 'gray-main'
    }

    return data.map((item) => {
      let identity_id = '-'
      let name = '-'
      let status = 'ยังไม่ได้กรอกข้อมูล'

      if (item?.patientUser?.hnId)
        identity_id = `${Array(7 - item.patientUser?.hnId?.toString().length)
          .fill(0)
          .join('')}${item.patientUser?.hnId || ''}`

      if (item?.patientUser?.prefix)
        name = `${item.patientUser?.prefix || ''} ${
          item.patientUser?.firstname || ''
        } ${item.patientUser?.lastname || ''}`

      if (item.dataInputFlag)
        status = item.patientUser
          ? 'กรอกข้อมูลแล้ว'
          : 'รอการยืนยันตัวตนเพื่อเชื่อมต่อข้อมูล'

      return {
        [topicTable[0]]: {
          data: identity_id,
          color: 'black',
        },
        [topicTable[1]]: {
          data: item.userHN || '-',
          color: 'black',
        },
        [topicTable[2]]: {
          data: name,
          color: 'black',
        },
        [topicTable[3]]: {
          data: date.convertToShowDateForGraph(item.createdAt),
          color: 'black',
        },
        [topicTable[4]]: {
          data: status,
          color: process_color(status),
        },
        [topicTable[5]]: {
          data: option(item.umdId, item.dataInputFlag, false),
          color: 'black',
        },
      }
    })
  }

  const handleSearchData = () => {
    setPage(1)
  }

  useEffect(() => {
    if (
      medicalData.fetching ||
      !medicalData.data ||
      medicalDataCount.fetching ||
      !medicalDataCount.data
    )
      return

    setPageAmount(
      Math.ceil(
        (medicalDataCount.data.getAggregateMedicalData?._count.umdId || 0) / 10,
      ),
    )
    setMedicalTableData(formatData(medicalData.data.getMedicalDatas || []))
  }, [medicalData, medicalDataCount])

  useEffect(() => {
    setPage(1)
  }, [filterDataTable])

  return (
    <div className="p-[80px]">
      <div className="flex items-center gap-[10px]">
        <Icon icon="tabler:report" className="w-[28px] h-[28px]" />
        <p className="text-[24px]">จัดการข้อมูลสุขภาพ</p>
      </div>

      <div
        className="mt-[20px] px-[20px] py-[30px] rounded-[8px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex flex-row justify-between">
          <div className="mb-[24px]">
            <Formik
              initialValues={{ text: '', dataFlag: null }}
              onSubmit={() => handleSearchData()}
            >
              {(formik) => (
                <Form>
                  <div className="flex gap-20">
                    <ElementComponent.InputBar
                      id="search-data-table"
                      name="text-search"
                      focusSpacing={false}
                      isLoading={false}
                      placeholder={'ค้นหาด้วย HN หรือ เบอร์โทรศัพท์'}
                      height="40px"
                      width="130%"
                      frontIcon={
                        <Icon
                          icon="material-symbols:search-rounded"
                          className="w-[20px] h-[20px]"
                        />
                      }
                      value={formik.values.text}
                      onChange={(text: string) => {
                        formik.setFieldValue('text', text)
                        setFilterDataTable({
                          ...filterDataTable,
                          searchText: text,
                        })
                      }}
                    />

                    <ElementComponent.SelectBar.SelectBar
                      label="สถานะ"
                      placeholder="สถานะ"
                      focusSpacing={false}
                      name="state"
                      isLoading={false}
                      width={'w-full'}
                      height="h-[37px]"
                      data={formik.values.dataFlag}
                      option={dataInputFlagFilterSelect}
                      onChange={(dataFlag: string) => {
                        formik.setFieldValue('dataFlag', dataFlag)
                        setFilterDataTable({
                          ...filterDataTable,
                          state: dataFlag,
                        })
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="mb-[24px]">
            <ElementComponent.Button
              id="submit-button"
              onAction={handleOnClickAddMedicalDataButton}
              icon="material-symbols:add"
              text={'สร้างข้อมูลสุขภาพ'}
              width="200px"
              height="40px"
            />
          </div>
        </div>

        {!medicalData?.fetching ||
        !medicalDataCount.fetching ||
        !deleteMedical.fetching ? (
          <div>
            <ElementComponent.Table col={topicTable} row={medicalTableData} />
            {medicalTableData.length === 0 && (
              <div className="my-[80px]">
                <p className="text-center text-[18px] text-black-lighter">
                  ไม่มีข้อมูลสุขภาพ
                </p>
              </div>
            )}
          </div>
        ) : (
          <SkeletonComponent.TableSkeleton columnsAmount={4} />
        )}

        {pageAmount !== 0 && (
          <div className="mt-[30px] flex justify-center">
            <Pagination
              page={page}
              count={pageAmount}
              variant="outlined"
              shape="rounded"
              onChange={(_, newPage: number) => setPage(newPage)}
            />
          </div>
        )}
      </div>

      <AlertComponent.ConfirmModal
        icon="mdi:bin"
        text={`คุณต้องการลบข้อมูลผลตรวจเลือดหรือไหม ?`}
        open={openDeleteModal.state}
        onClose={() => setOpenDeleteModal({ umdId: '', state: false })}
        handleCancel={() => setOpenDeleteModal({ umdId: '', state: false })}
        handleConfirm={() => handelOnDeleteMedicalData()}
      />
    </div>
  )
}
