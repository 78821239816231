import React from 'react'
import { Stack } from '@mui/material'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import { useResponsive } from 'hooks'
import { route } from 'settings'
import { MenuComponent } from 'components'

const menuListOption = {
  title: 'บัญชีของฉัน',
  menu: [
    {
      text: 'ข้อมูลส่วนตัว',
      icon: 'material-symbols:person',
      path: route.PROFILE_PAGE_URL,
    },
  ],
}

function UserLayout() {
  const { isLaptop } = useResponsive()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      className="bg-white-dark h-full min-h-[80vh] laptop:min-h-[77vh] flex mt-[-90px] laptop:mt-0 laptop:pl-[50px] pt-[60px] pb-[90px]"
    >
      {isLaptop && (
        <MenuComponent.UserOptionBar
          title={menuListOption.title}
          menu={menuListOption.menu}
          options={location.pathname}
          onChangeOptions={(path: string) => {
            navigate(path)
            window.scrollTo(0, 0)
          }}
        />
      )}

      <div className="w-full px-[20px] pt-0 laptop:pl-0 laptop:pr-[50px] laptop:ml-[345px]">
        <Outlet />
      </div>
    </Stack>
  )
}

export default UserLayout
