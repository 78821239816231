import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Field } from 'formik'
import { TextField, InputAdornment, Skeleton } from '@mui/material'

interface InputBarProps {
  frontIcon?: React.ReactNode
  backIcon?: React.ReactNode
  name?: string
  value: string
  width?: string
  height?: string
  label?: string
  errorText?: string
  error?: boolean
  placeholder?: string
  disabled?: boolean
  disable_color?: string
  focusSpacing?: boolean
  style?: string
  onChange: Function
  isLoading: boolean
}

interface TextFieldStyleProps {
  height: string
  width: string
  error: boolean
  disabled: boolean
  disable_color: string
}

export const TextFieldStyle = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'width' && prop !== 'error',
})<TextFieldStyleProps>(({ height, width, error, disabled, disable_color }) => {
  let border_color_hover = 'rgba(201, 226, 244, 1)'
  if (error) border_color_hover = 'rgba(228, 0, 9, 1)'
  if (disabled) border_color_hover = '#E7E7E7'

  return {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&.Mui-disabled': {
        cursor: 'not-allowed',
        WebkitTextFillColor: disable_color,
      },
    },
    '& .MuiOutlinedInput-root': {
      height: height,
      width: width,
      fontFamily: 'Roboto, Sarabun',
      frontSize: '16px',
      backgroundColor: '#FFFFFF',
      borderRadius: '5px',
      '& fieldset': {
        borderColor: error ? 'rgba(228, 0, 9, 1)' : '#E7E7E7',
      },
      '&:hover fieldset': {
        borderColor: border_color_hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: error ? 'rgba(228, 0, 9, 1)' : 'rgba(20, 117, 187, 1)',
      },
      '&.Mui-disabled': {
        backgroundColor: '#F1F1F2',
        cursor: 'not-allowed',
        '& > fieldset': {
          borderColor: '#E7E7E7',
        },
      },
    },
    '& input::placeholder': {
      fontSize: '16px',
    },
  }
})

function InputBarV2({
  frontIcon,
  backIcon,
  value,
  name,
  width = '100%',
  height = '35px',
  errorText,
  error,
  label,
  placeholder,
  focusSpacing = true,
  style,
  disabled,
  disable_color = 'rgba(0, 0, 0, 0.5)',
  onChange,
  isLoading,
}: InputBarProps) {
  const [cliked, setCliked] = useState(false)

  const onHandleClick = (e, defaultOnClick) => {
    setCliked(true)

    if (defaultOnClick) defaultOnClick(e)
  }

  const onHandleBlur = (e, defaultOnblur) => {
    setCliked(false)

    if (defaultOnblur) defaultOnblur(e)
  }

  return !isLoading ? (
    <div
      className={`${style ?? ''} ${
        focusSpacing && (cliked || value || disabled)
          ? 'mt-[20px] transition-all ease-in-out'
          : ''
      }`}
    >
      <Field id={name} name={name}>
        {({ field }) => (
          <>
            <div className="relative w-fit">
              <p
                className={`text-black-main text-[16px] w-auto break-normal whitespace-nowrap text-left pl-1 font-bold ${
                  cliked || value || disabled
                    ? 'absolute bottom-[0.25px] duration-300 delay-100 transition-all ease-in-out'
                    : 'absolute top-0.25 p-2 transition-all ease-in-out'
                }`}
              >
                {label}
              </p>
            </div>
            <TextFieldStyle
              {...field}
              onClick={(e) => onHandleClick(e, field.onClick)}
              onBlur={(e) => onHandleBlur(e, field.onBlur)}
              value={value || ''}
              disabled={disabled}
              disable_color={disable_color}
              error={error}
              fullWidth
              height={height}
              width={width}
              placeholder={cliked ? '' : placeholder}
              aria-label={placeholder}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(event.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ paddingRight: frontIcon ? '5px' : '0px' }}
                  >
                    {frontIcon}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ paddingLeft: backIcon ? '5px' : '0px' }}
                  >
                    {backIcon}
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </Field>
      {error && (
        <p
          id={`error-${name}`}
          className="text-[14px] text-red-main mt-[6px] text-start"
        >
          {errorText}
        </p>
      )}
    </div>
  ) : (
    <div className={`${style}`}>
      <Skeleton variant="rounded" width={width} height={height} />
    </div>
  )
}

export default InputBarV2
