import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useRecoilState } from 'recoil'

import { LoginForm } from 'components/form'
import { LoginSVG, Logo2SVG } from 'assets/svg'
import { AuthMutate } from 'services/graphql/authService'
import { useAuth } from 'stores/recoil'
import { appSettings } from 'settings'
import { general } from 'utils'

function LoginPage() {
  const [searchParams] = useSearchParams()
  const [, executeMutation] = useMutation(AuthMutate.refreshToken)
  const [, setToken] = useRecoilState(useAuth.selector.selectorToken)

  useEffect(() => {
    ;(async () => {
      const token_raw = searchParams.get('token')
      if (!token_raw) return

      const token = JSON.parse(atob(token_raw))

      await executeMutation({
        data: { refreshToken: token.refreshToken },
      })
        .then((res) => {
          if (res.error) {
            window.location.replace('/login')
          }

          general.cookie.setCookie(
            appSettings.TOKEN_KEY,
            res.data.refreshToken.refreshToken,
          )
          setToken(res.data.refreshToken.accessToken)
        })
        .catch(() => {
          window.location.replace('/login')
        })
    })()

    return () => {
      // this now gets called when the component unmounts
    }
  }, [])

  return (
    <div className="flex h-screen">
      <div
        style={{
          background:
            'linear-gradient(89.95deg, #004F64 0.05%, #004884 104.41%)',
        }}
        className="w-1/2 text-white-main text-center flex flex-col justify-center items-center"
      >
        <div className="mb-[80px] scale-[2]">
          <Logo2SVG />
        </div>
        <LoginSVG />
        <p className="text-[28px] mt-[60px] p-[20px]">DR.ASA Console</p>
        <p className="mx-[130px]">
          “จะรอให้ตัวเองป่วยทำไม ในเมื่อคุณป้องกันโรคของตัวเองได้
          มาออกแบบสุขภาพตัวเองกับหมอประจำตัวด้วย DR.ASA”
        </p>
      </div>

      <div className="w-1/2 text-center p-[57px] bg-white-dark">
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginPage
