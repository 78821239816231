export const uploadMedicalImage = `
  mutation createMedicalData(
    $data: UserMedicalDataCreateInput!
    $files: [Upload!]
  ) {
    createMedicalData(data: $data, files: $files) {
      umdId
      imageFile
    }
  }
`

export const createMedicalData = `
mutation createMedicalData($data: UserMedicalDataCreateInput!, $files: [Upload!]) {
  createMedicalData(data: $data, files: $files) {
    umdId
  }
}
`

export const updateMedicalData = `
mutation updateMedicalData($data: UserMedicalDataUpdateInput!, $where: UserMedicalDataWhereUniqueInput!) {
  updateMedicalData(data: $data, where: $where) {
    umdId
  }
}
`

export const deleteMedicalData = `
mutation deleteMedicalData($where: UserMedicalDataWhereUniqueInput!) {
  deleteMedicalData(where: $where) {
    umdId
  }
}
`
export const createDrug = `
mutation createDrug ($data: DrugCreateInput!) {
  createDrug (data: $data) {
      duId
      dName
  }
}
`

export const createDrugType = `
mutation createDrugType ($data: DrugTypeCreateInput!) {
  createDrugType (data: $data) {
      dutId
  }
}
`

export const updateDrug = `
mutation updateDrug ($data: DrugUpdateInput!, $where: DrugWhereUniqueInput!) {
  updateDrug (data: $data, where: $where) {
      duId
      dName
  }
}
`
export const updateDrugType = `
mutation updateDrugType ($data: DrugTypeUpdateInput!, $where: DrugTypeWhereUniqueInput!) {
  updateDrugType (data: $data, where: $where) {
      dutId
  }
}
`

export const createConsult = `
mutation createConsultForDoctor ($data: ConsultCreateInput!) {
  createConsultForDoctor (data: $data) {
      cId,
  }
}
`
export const updateConsult = `
mutation updateDoctorConsult ($data: ConsultUpdateDoctorInput!, $where: ConsultWhereUniqueInput!) {
  updateDoctorConsult (data: $data, where: $where) {
      cId
  }
}
`
export const updateConsultPayed = `
mutation updateConsult ($data: ConsultUpdateInput!, $where: ConsultWhereUniqueInput!) {
  updateConsult (data: $data, where: $where) {
      cId
  }
}
`

export const createDiagnose = `
  mutation createDiagnoseDDDDD ($data: DiagnoseCreateInput!, $files: [Upload!]) {
    createDiagnoseDDDDD (data: $data, files: $files) {
        dId,
    }
  }
`
export const updateDiagnose = `
mutation updateDiagnose ($data: DiagnoseUpdateInput!, $where: DiagnoseWhereUniqueInput!) {
  updateDiagnose (data: $data, where: $where) {
      dId
  }
}
`
export const createUnderlyingDisease = `
mutation createUnderlyingDisease ($data: UnderlyingDiseaseCreateInput!) {
  createUnderlyingDisease (data: $data) {
    udId
  }
}
`
export const updateUnderlyingDisease = `
mutation updateUnderlyingDisease ($data: UnderlyingDiseaseUpdateInput!, $where: UnderlyingDiseaseWhereUniqueInput!) {
  updateUnderlyingDisease (data: $data, where: $where) {
    udId
  }
}
`
export const deleteConsultData = `
mutation deleteConsult($where: ConsultWhereUniqueInput!) {
  deleteConsult(where: $where)
}
`
