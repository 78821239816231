import {
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import dayjs from 'dayjs'
import { useState } from 'react'
import { PatientQuery, UserQuery } from 'services/graphql/userService'
import { useMutation, useQuery } from 'urql'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { Link } from 'react-router-dom'
import {
  IcoCorrect,
  IcoEdit,
  IcoHospital,
  IcoPhpV2,
  IcoWrong,
} from 'assets/svg'
import { AlertComponent, ElementComponent, MenuComponent } from 'components'
import { Icon } from '@iconify/react'
import * as Pics from 'assets/picture'
import { route } from 'settings'
import { MedicalMutate } from 'services/graphql/medicalService'
type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

export default function ClinicMainPage() {
  const [dateValue, onDateChange] = useState<Value>(new Date())
  //const [doctorUid, setDoctorUid] = useState<string>('')
  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })
  const [, executeUpdateConsultMutation] = useMutation(
    MedicalMutate.updateConsult,
  )
  async function setSelectedDoctor(docId: string, cId: string) {
    let payloadConsult = {
      data: {
        doctorUid: { set: docId },
      },
      where: {
        cId: cId,
      },
    }
    const result = await executeUpdateConsultMutation(payloadConsult)
    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: 'กรุณาลองใหม่อีกครั้ง' + JSON.stringify(result.error),
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: '',
      onClose: () => {
        //submitPopupData.open = false
        setSubmitPopupData({
          type: 'success',
          open: false,
          title: '',
          description: '',
          onClose: () => {},
        })
      },
    })

    return
  }

  const [doctorData] = useQuery({
    ...UserQuery.getUsers({
      where: {
        role: {
          is: {
            name: {
              equals: 'DOCTOR',
            },
          },
        },
      },
    }),
  })

  const [patientConsultOnlineList] = useQuery(
    PatientQuery.getPatientConsultList(
      dayjs(dateValue.toString()).format('DD/MM/YYYY'),
      'online',
    ),
  )
  const [patientConsultWalkinList, rePatientConsultWalkinList] = useQuery(
    PatientQuery.getPatientConsultList(
      dayjs(dateValue.toString()).format('DD/MM/YYYY'),
      'walkin',
    ),
  )
  const [, executeDelConsultMutation] = useMutation(
    MedicalMutate.deleteConsultData,
  )

  const [openDeleteModal, setOpenDeleteModal] = useState<{
    cId: string
    state: boolean
  }>({ cId: '', state: false })

  const handelOnDeleteUser = async () => {
    await executeDelConsultMutation({
      where: {
        cId: openDeleteModal.cId,
      },
    }).then((res) => {
      if (res.error) {
        const error: any = res.error

        alert(error)
        return
      }
    })
    rePatientConsultWalkinList()
    //reexecuteUserDataCountQuery()

    setOpenDeleteModal({ cId: '', state: false })
  }
  const menuList = [
    {
      title:
        'รายการจัดการคลินิควันที่ ' +
        dayjs(dateValue.toString()).format('DD MMMM BBBB'),
      link: '',
    },
  ]
  return (
    <div>
      <div className="pl-[70px] mt-[40px]">
        <MenuComponent.MenuHeaderBar menuList={menuList} />
      </div>

      <div
        className="p-[50px]  m-[70px] mt-[40px]"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <Grid container columns={{ desktop: 2.05 }} gap="8px">
          <Grid item desktop={1.0} className=" p-[8px]" justifyContent="right">
            <Calendar
              onChange={onDateChange}
              className="bg-zinc-100 rounded-[20px] border-transparent h-[100%] w-[100%]"
            />
          </Grid>
          <Grid item desktop={1.0} className=" p-[8px]" justifyContent="center">
            <div className="mb-[24px] flex mr-4">
              <div className="flex gap-[5px]">
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="ค้นหาจาก ชื่อ-นามสกุล"
                  InputProps={{
                    sx: { borderRadius: 10 },
                    className: 'bg-zinc-100',
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          icon="material-symbols:search-rounded"
                          className="w-[20px] h-[20px]"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="flex gap-[5px] ml-2">
                <TextField
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="ค้นหาจากเลข HN"
                  InputProps={{
                    className: 'bg-zinc-100',
                    sx: { borderRadius: 10 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          icon="material-symbols:search-rounded"
                          className="w-[20px] h-[20px]"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <Grid
              container
              columns={{ desktop: 2.05 }}
              className="flex gap-[20px] text-white-main p-[20px]"
              justifyContent="center"
            >
              <Grid item desktop={0.95} justifyContent="center">
                <Link to={route.USER_MANAGEMENT_CLINIC_OPEN_VISIT_PAGE_URL}>
                  <ElementComponent.Image
                    fullImage={false}
                    src={Pics.BtnVisitAdd}
                  />
                </Link>
              </Grid>
              <Grid item desktop={0.95} justifyContent="center">
                <Link to={route.USER_MANAGEMENT_ADD_PATIENT_PAGE_PAGE_URL}>
                  <ElementComponent.Image
                    fullImage={false}
                    src={Pics.BtnUserAdd}
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            desktop={1}
            className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
          >
            <p className="text-[16px] text-blue-main ml-2 mt-1">
              ผู้ป่วยจองทั้งหมดของวันนี้{' '}
              {!patientConsultOnlineList.fetching &&
                !patientConsultOnlineList.error && (
                  <span className="text-black-main text-[18px]">
                    {' '}
                    {patientConsultOnlineList.data.getConsultList.length}{' '}
                  </span>
                )}
              คน
            </p>
            <div
              className="overflow-y h-[200px]  text-[13px]"
              style={{ overflowY: 'auto' }}
            >
              {!patientConsultOnlineList.fetching &&
                !patientConsultOnlineList.error &&
                patientConsultOnlineList.data.getConsultList.map(
                  (item: {
                    cId: string
                    placeName: string
                    time: string
                    ctId: string
                    textConsult: string
                    uid: string
                    dateConsult: string
                    doctorUid: string
                    isDiagnose: boolean
                    isPayedDrug: boolean
                    dId: string
                    user: { firstname: string; lastname: string }
                  }) => (
                    <Stack
                      direction={{ laptop: 'row' }}
                      className="flex gap-[8px] text-white-main mt-2"
                    >
                      <Stack
                        direction={{ laptop: 'column' }}
                        className="bg-blue-main rounded-[20px] p-[8px] w-[80%] "
                      >
                        <Stack
                          direction={{ laptop: 'row' }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <span className="ml-1">{item.time} น.</span>
                          <Stack direction={{ laptop: 'row' }} className="ml-1">
                            <IcoHospital className="h-[20px] w-[20px] bg-cover bg-center" />
                            <IcoPhpV2 className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                          </Stack>
                          <span className="ml-4">
                            {item.user.firstname} {item.user.lastname}
                          </span>
                          <Stack direction={{ laptop: 'row' }} className="ml-4">
                            {item.isDiagnose && (
                              <Link
                                to={
                                  route.USER_MANAGEMENT_CLINIC_DIAGNOSE_PAGE_URL +
                                  '/' +
                                  item.uid +
                                  '/' +
                                  item.cId +
                                  '/' +
                                  item.dId +
                                  '/' +
                                  item.uid
                                }
                              >
                                <IcoEdit className="h-[20px] w-[20px] bg-cover bg-center " />
                              </Link>
                            )}
                            {item.isPayedDrug ? (
                              <IcoCorrect className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                            ) : (
                              <IcoWrong className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack
                        direction={{ laptop: 'column' }}
                        className="bg-blue-main rounded-[20px] p-[8px] w-[20%] "
                        justifyContent="space-evenly"
                        alignItems="center"
                      >
                        <Select
                          className="w-[100%] h-[24px] text-white"
                          size="small"
                          name=""
                          variant="outlined"
                          value={item.doctorUid}
                          onChange={(event: SelectChangeEvent) =>
                            setSelectedDoctor(event.target.value, item.cId)
                          }
                        >
                          {!doctorData.fetching &&
                            !doctorData.error &&
                            doctorData.data.getUsers.map((item: any) => (
                              <MenuItem value={item.uId}>
                                {item.firstname} {item.lastname}
                              </MenuItem>
                            ))}
                        </Select>
                      </Stack>
                    </Stack>
                  ),
                )}
            </div>
          </Grid>
          <Grid
            item
            desktop={1}
            className="w-[100%] bg-zinc-100 rounded-[20px] p-[16px]"
          >
            <p className="text-[16px] text-blue-main ml-2 mt-1">
              ผู้ป่วย Walk-in ทั้งหมดของวันนี้
              {!patientConsultWalkinList.fetching &&
                !patientConsultWalkinList.error && (
                  <span className="text-black-main text-[18px]">
                    {' '}
                    {patientConsultWalkinList.data.getConsultList.length}{' '}
                  </span>
                )}
              คน
            </p>
            <div
              className="overflow-y h-[200px] mt-2 text-[13px] text-white-main"
              style={{ overflowY: 'auto' }}
            >
              {!patientConsultWalkinList.fetching &&
                !patientConsultWalkinList.error &&
                patientConsultWalkinList.data.getConsultList.map(
                  (item: {
                    cId: string
                    placeName: string
                    time: string
                    ctId: string
                    textConsult: string
                    uid: string
                    dateConsult: string
                    finishedFlag: boolean
                    doctorUid: string
                    isDiagnose: boolean
                    isPayedDrug: boolean
                    dId: string
                    user: { firstname: string; lastname: string }
                  }) => (
                    <Stack
                      direction={{ laptop: 'row' }}
                      className="bg-blue-main rounded-[20px] w-[100%] mt-2"
                    >
                      <Stack
                        direction={{ laptop: 'column' }}
                        className="bg-blue-main rounded-[20px] p-[8px] w-[100%] "
                      >
                        <Stack
                          direction={{ laptop: 'row' }}
                          justifyContent="space-between"
                        >
                          <Stack className="ml-4" direction={{ laptop: 'row' }}>
                            <IcoPhpV2 className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                            <span className="ml-4">
                              {item.user.firstname} {item.user.lastname}
                            </span>
                          </Stack>
                          <Stack className="ml-4" direction={{ laptop: 'row' }}>
                            {item.isDiagnose && (
                              <Link
                                to={
                                  route.USER_MANAGEMENT_CLINIC_DIAGNOSE_PAGE_URL +
                                  '/' +
                                  item.uid +
                                  '/' +
                                  item.cId +
                                  '/' +
                                  item.dId +
                                  '/' +
                                  item.uid
                                }
                              >
                                <IcoEdit className="h-[20px] w-[20px] bg-cover bg-center " />
                              </Link>
                            )}
                            {item.isPayedDrug ? (
                              <IcoCorrect className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                            ) : (
                              <IcoWrong className="h-[20px] w-[20px] bg-cover bg-center ml-1" />
                            )}
                            {!item.isPayedDrug && (
                              <Icon
                                icon="mdi:bin"
                                className={`w-[20px] h-[20px] ml-1 cursor-pointer text-white-main hover:text-red-main`}
                                onClick={() =>
                                  setOpenDeleteModal({
                                    cId: item.cId,
                                    state: true,
                                  })
                                }
                              />
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  ),
                )}
            </div>
          </Grid>
        </Grid>
      </div>
      <AlertComponent.ConfirmModal
        icon="mdi:bin"
        text={'คุณต้องการลบข้อมูล'}
        open={openDeleteModal.state}
        onClose={() => setOpenDeleteModal({ cId: '', state: false })}
        handleCancel={() => setOpenDeleteModal({ cId: '', state: false })}
        handleConfirm={() => handelOnDeleteUser()}
      />
      {submitPopupData.type === 'success' ? (
        <AlertComponent.SuccessModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      ) : (
        <AlertComponent.ErrorModal
          open={submitPopupData.open}
          title={submitPopupData.title}
          description={submitPopupData.description}
          onClose={submitPopupData.onClose}
        />
      )}
    </div>
  )
}
