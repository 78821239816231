export const getMedicalDatas = (
  condition: Object,
  take: number,
  skip: number,
) => {
  const medicalDataSchema = `query getMedicalDatas($where: UserMedicalDataWhereInput, $take: Int, $skip: Int, $orderBy: [UserMedicalDataOrderByWithRelationInput!]) {
      getMedicalDatas(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
        umdId
        userHN
        patientUser {
            hnId
            prefix
            firstname
            lastname
        }
        dataInputFlag
        createdAt
      }
    }`

  return {
    query: medicalDataSchema,
    variables: {
      where: {
        ...condition,
      },
      take: take,
      skip: skip,
      orderBy: [
        {
          createdAt: 'desc',
        },
      ],
    },
  }
}

export const countMedicalData = (condition: Object) => {
  const getAggregateMedicalData = `query getAggregateMedicalData($where: UserMedicalDataWhereInput, $_count: UserMedicalDataCountAggregateInput ) {
      getAggregateMedicalData(where: $where, _count: $_count) {
          _count {
              umdId
          }
      }
    }
    `

  return {
    query: getAggregateMedicalData,
    variables: {
      where: {
        ...condition,
      },
      _count: {
        umdId: true,
      },
    },
  }
}

export const getDisease = (condition: any) => {
  const getDisease = `query getDiseases($where: DiseaseWhereInput! $orderBy: [DiseaseOrderByWithRelationInput!]){
                        getDiseases(where: $where, orderBy: $orderBy) {
                          dId
                          thaiName
                          medName
                          engName
                          type
                          diseaseAttibute {
                            daId
                            medName
                            thaiName
                            engName
                            unitEnglish
                            unitThai
                            type
                            exampleImage
                            minValuePos
                            maxValuePos
                            mandatoryFlag
                          }
                          createdAt
                        }
                      }`

  return {
    query: getDisease,
    variables: {
      where: {
        ...condition,
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getDiseaseForDropDown = (condition: any) => {
  const getDisease = `query getDiseases($where: DiseaseWhereInput! $orderBy: [DiseaseOrderByWithRelationInput!]){
                        getDiseases(where: $where, orderBy: $orderBy) {
                          dId
                          thaiName
                        }
                      }`

  return {
    query: getDisease,
    variables: {
      where: {
        ...condition,
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getDiseaseAttibute = (condition: any) => {
  const getDiseaseAttibute = `query getDiseasesAttibutes($where: DiseaseAttibuteWhereInput! $orderBy: [DiseaseAttibuteOrderByWithRelationInput!]){
                        getDiseasesAttibutes(where: $where, orderBy: $orderBy) {
                          daId
                          thaiName
                          medName
                          engName
                          unitEnglish
                          unitThai
                          type
                          exampleImage
                          mandatoryFlag
                          minValuePos
                          maxValuePos
                          createdAt
                        }
                      }`

  return {
    query: getDiseaseAttibute,
    variables: {
      where: {
        ...condition,
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getDiseaseAttibuteV2 = (condition: any) => {
  const getDiseaseAttibute = `query getDiseasesAttibutes($where: DiseaseAttibuteWhereInput! $orderBy: [DiseaseAttibuteOrderByWithRelationInput!]){
                        getDiseasesAttibutes(where: $where, orderBy: $orderBy) {
                          daId
                          thaiName
                          medName
                          engName
                          unitEnglish
                          unitThai
                          type
                          exampleImage
                          mandatoryFlag
                          minValuePos
                          maxValuePos
                          createdAt
                          inputFlag
                        }
                      }`

  return {
    query: getDiseaseAttibute,
    variables: {
      where: {
        ...condition,
      },
      orderBy: [
        {
          createdAt: 'asc',
        },
      ],
    },
  }
}

export const getMedicalData = (condition: Object) => {
  const medicalDataSchema = `query getMedicalData($where: UserMedicalDataWhereInput) {
      getMedicalData(where: $where) {
        umdId
        imageFile
        userMedTrasaction {
          diseaseAttibute {
            daId
            dId
            medName
            thaiName
            type
          }
          createdAt
          value
          imagePath
          desciption
        }
        diagnosisData {
          createdAt
          diagnosisResult
          suggestion
          doctorUser {
            firstname
          }
        }
        submitUser {
          firstname
        }
        dataInputFlag
        createdAt
      }
    }`

  return {
    query: medicalDataSchema,
    variables: {
      where: {
        ...condition,
      },
    },
  }
}

export const getDrugOne = (duId: number) => {
  const querySchema = `query getDrugOne ($where: DrugWhereInput) {
    getDrugOne (where: $where) {
        dName
        dUseType
        tradeName
        description
        cost
        income
        alarmCutPoint
        typeOne
        typeTwo
        quantity
        unit
        phrDrug
        defaultShow
        drugMainType
        stock
        drugDefaultCondition
        injDefault
        injMin
        injMax
        injUnit
        injIM
        injIV
        injSC
        invoiceType
    }
}`

  return {
    query: querySchema,
    variables: {
      where: {
        duId: { equals: duId },
      },
    },
  }
}

export const getDrugListWithName = (dName: string) => {
  const querySchema = `query getDrugList ($where: DrugWhereInput) {
    getDrugList (where: $where) {
        duId
        dName
        description
        cost
        income
        stock
        drugDefaultCondition
        phrDrug
        quantity
        unit
        typeOne
        dUseType
        invoiceType
        injDefault
        injMin
        injMax
        injUnit
        injIM
        injIV
        injSC
        tradeName
    }
}`

  return {
    query: querySchema,
    variables: {
      where: {
        dName: {
          contains: dName,
        },
      },
      orderBy: [
        {
          dName: 'asc',
        },
      ],
    },
  }
}

export const getDrugTypeList = () => {
  const querySchema = `query getDrugType {
    getDrugType  {
        dutId
        dutName
    }
}`

  return {
    query: querySchema,
  }
}

export const getDrugTypeListByName = (dutName: string) => {
  const querySchema = `query getDrugType ($where: DrugTypeWhereInput){
    getDrugType (where: $where) {
        dutId
        dutName
    }
}`

  return {
    query: querySchema,
    variables: {
      where: {
        dutName: {
          contains: dutName,
        },
      },
      orderBy: [
        {
          dutName: 'asc',
        },
      ],
    },
  }
}

export const getDrugTypeOneById = (dutId: number) => {
  const querySchema = `query getDrugTypeOne ($where: DrugTypeWhereInput){
    getDrugTypeOne (where: $where) {
        dutId
        dutName
    }
}`

  return {
    query: querySchema,
    variables: {
      where: {
        dutId: {
          equals: dutId,
        },
      },
      orderBy: [
        {
          dutName: 'asc',
        },
      ],
    },
  }
}

export const getConsultType = () => {
  const getConsultType = `query getConsultType {
    getConsultType  {
        ctId
        typeName
    }
}`

  return {
    query: getConsultType,
    variables: {
      where: {
        showFlag: {
          equals: true,
        },
      },
    },
  }
}
export const getOneUnderlyingDisease = (udId: string) => {
  const underlyingDiseaseSchema = `
    query getUnderlyingDisease ($where: UnderlyingDiseaseWhereInput){
      getUnderlyingDisease (where: $where){
        udId
        medName
        thaiName
        engName
      }
    }
  `

  return {
    query: underlyingDiseaseSchema,
    variables: {
      where: {
        OR: [
          {
            udId: {
              equals: udId,
            },
          },
        ],
      },
    },
  }
}
export const getUnderlyingDisease = (thaiName: string) => {
  const underlyingDiseaseSchema = `
    query getUnderlyingDiseases ($where: UnderlyingDiseaseWhereInput){
      getUnderlyingDiseases (where: $where){
        udId
        medName
        thaiName
        engName
      }
    }
  `

  return {
    query: underlyingDiseaseSchema,
    variables: {
      where: {
        OR: [
          {
            thaiName: {
              contains: thaiName,
            },
          },
          {
            engName: {
              contains: thaiName,
            },
          },
        ],
      },
    },
  }
}

export const getIcdTenListWithCode = (icdCode: string) => {
  const querySchema = `query getIcdTenList ($where: IcdTenWhereInput) {
    getIcdTenList (where: $where) {
        icdCode
        icdCodePair
        icdLevel
        icdName
        icdType
    }
}`

  return {
    query: querySchema,
    variables: {
      where: {
        OR: [
          {
            icdCode: {
              contains: icdCode,
            },
          },
          {
            icdName: {
              contains: icdCode,
            },
          },
        ],
        icdType: {
          equals: 'TRUE',
        },
      },
    },
  }
}
