export const createDoctor = `
  mutation createDoctor ($data: UserCreateInput!) {
    createDoctor (data: $data) {
        uId
    }
  }
`

export const createPatient = `
  mutation createPatient ($data: UserCreateInput!) {
    createPatient (data: $data) {
        uId
    }
  }
`

export const updateUserPatient = `
  mutation updateUserInfo ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserInfo (data: $data, where: $where) {
      uId
    }
  }
`

export const updateUser = `
  mutation updateUserInfo ($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUserInfo (data: $data, where: $where) {
      uId
    }
  }
`

export const deleteUser = `
  mutation deleteUser ($where: UserWhereInput) {
    deleteUser ( where: $where)
  }
`

export const changePassword = `
  mutation changePassword ($data: UsersChangePasswordInput!) {
    changePassword (data: $data) {
        uId
    }
  }
`
