import { selector } from 'recoil'
import {
  swipeableDrawerLoginStateAtom,
  swipeableDrawerMenuStateAtom,
} from 'stores/recoil/system/atom'

const selectorSwipeableDrawerLoginStateAtom = selector({
  key: 'selectorSwipeableDrawerLoginStateAtom',
  get: ({ get }) => get(swipeableDrawerLoginStateAtom).state,
  set: ({ set }, newValue: boolean) => {
    set(swipeableDrawerLoginStateAtom, { state: newValue })
  },
})

const selectorSwipeableDrawerSideMeueStateAtom = selector({
  key: 'selectorSwipeableDrawerSideMeueStateAtom',
  get: ({ get }) => get(swipeableDrawerMenuStateAtom).state,
  set: ({ set }, newValue: boolean) => {
    set(swipeableDrawerMenuStateAtom, { state: newValue })
  },
})

export {
  selectorSwipeableDrawerLoginStateAtom,
  selectorSwipeableDrawerSideMeueStateAtom,
}
