import { Grid } from '@mui/material'
import * as Pics from 'assets/picture'
import { ElementComponent } from 'components'
import { Link } from 'react-router-dom'
import { route } from 'settings'
export default function UserManagementMainPage() {
  return (
    <div className="p-[50px] pl-[180px]">
      <Grid container columns={{ desktop: 2.05 }} gap="8px">
        <Grid item desktop={1.0} className=" p-[8px]" justifyContent="right">
          <Link to={route.USER_MANAGEMENT_LIST_PAGE_URL}>
            <ElementComponent.Image
              fullImage={true}
              src={Pics.BtnUserList}
              className="w-[70%] h-[100%]"
            />
          </Link>
        </Grid>
        <Grid item desktop={1.0} className=" p-[8px]" justifyContent="center">
          <ElementComponent.Image
            fullImage={true}
            src={Pics.BtnFollowCustomer}
            className="w-[75%] h-[100%]"
          />
        </Grid>
        <Grid item desktop={1.0} className=" p-[8px]" justifyContent="right">
          <Link to={route.USER_MANAGEMENT_CLINIC_PAGE_URL}>
            <ElementComponent.Image
              fullImage={true}
              src={Pics.BtnClinicManagement}
              className="w-[70%] h-[100%]"
            />
          </Link>
        </Grid>
        <Grid item desktop={1.0} className=" p-[8px]" justifyContent="center">
          <Link to={route.DRUG_MANAGEMENT_MAIN_PAGE_URL}>
            <ElementComponent.Image
              fullImage={true}
              src={Pics.BtnDrugManagement}
              className="w-[70%] h-[100%]"
            />
          </Link>
        </Grid>
      </Grid>
    </div>
  )
}
