import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'urql'
import { useNavigate } from 'react-router-dom'
import { Box, Tabs, Tab, Tooltip, Pagination } from '@mui/material'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Icon } from '@iconify/react'
import { Formik, Form } from 'formik'

import { UserQuery, UserMutate } from 'services/graphql/userService'
import { ElementComponent, SkeletonComponent, AlertComponent } from 'components'
import { route } from 'settings'
import { date } from 'utils'

type TablesStatus = 'PATIENT' | 'DOCTOR'

export default function HealthDataManagementPage() {
  const navigate = useNavigate()
  const PAGE_SIZE = 10
  const [tables, setTables] = useState<TablesStatus>('PATIENT')
  const topicTable =
    tables === 'PATIENT'
      ? [
          'HN',
          'ชื่อ-นามสกุล',
          'อายุ',
          'อำเภอ/เขต',
          'จังหวัด',
          'วันที่บันทึกข้อมูล',
          'ตัวเลือก',
        ]
      : [
          'เลขใบประกอบวิชาชีพ',
          'ชื่อ-นามสกุล',
          'อายุ',
          'อำเภอ/เขต',
          'จังหวัด',
          'วันที่บันทึกข้อมูล',
          'ตัวเลือก',
        ]
  const [searchTable, setSearchTable] = useState<string>('')
  const [pageAmount, setPageAmount] = useState<number>(1)
  const [page, setPage] = useState<number>(1)

  const [userTableData, setUserTableData] = useState<any[]>([])
  const [userData, reexecuteuserDataQuery] = useQuery({
    ...UserQuery.getUsers({
      where: {
        role: {
          is: {
            name: {
              equals: tables,
            },
          },
        },
        ...(searchTable
          ? {
              ...(tables === 'PATIENT'
                ? {
                    hnId: {
                      equals: parseInt(searchTable),
                    },
                  }
                : {
                    UserAuth: {
                      is: {
                        OR: [
                          {
                            identifyId: {
                              contains: searchTable,
                            },
                          },
                        ],
                      },
                    },
                  }),
            }
          : {}),
      },
      take: PAGE_SIZE,
      skip: (page - 1) * PAGE_SIZE,
    }),
  })

  const [userDataCount, reexecuteUserDataCountQuery] = useQuery({
    ...UserQuery.getCountUsers({
      where: {
        role: {
          is: {
            name: {
              equals: tables,
            },
          },
        },
        ...(searchTable
          ? {
              ...(tables === 'PATIENT'
                ? {
                    hnId: {
                      equals: parseInt(searchTable),
                    },
                  }
                : {
                    UserAuth: {
                      is: {
                        OR: [
                          {
                            identifyId: {
                              contains: searchTable,
                            },
                          },
                        ],
                      },
                    },
                  }),
            }
          : {}),
      },
      take: PAGE_SIZE,
      skip: (page - 1) * PAGE_SIZE,
    }),
  })

  const [mutationDeleteUserResult, executeMutation] = useMutation(
    UserMutate.deleteUser,
  )

  const [openDeleteModal, setOpenDeleteModal] = useState<{
    uId: string
    state: boolean
  }>({ uId: '', state: false })

  const handleChangeTable = (value: TablesStatus) => {
    setTables(value)
    setSearchTable('')
    setPage(1)
  }

  const handleOnClickAddUserButton = () => {
    if (tables === 'PATIENT') {
      window.location.href = route.USER_MANAGEMENT_ADD_PATIENT_PAGE_PAGE_URL
      return
    }

    window.location.href = route.USER_MANAGEMENT_ADD_DOCTOR_PAGE_PAGE_URL
  }

  const handelOnDeleteUser = async () => {
    await executeMutation({
      where: {
        uId: {
          equals: openDeleteModal.uId,
        },
      },
    })
    reexecuteuserDataQuery()
    reexecuteUserDataCountQuery()

    setOpenDeleteModal({ uId: '', state: false })
  }

  const option = (uId: string, to: string, disable = true) => {
    const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#C9E2F4',
        color: '#1475BB',
        fontSize: 12,
      },
    })

    return (
      <div className="flex justify-center">
        <StyleToolTip
          title={`แก้ไขข้อมูล${to === 'PATIENT' ? 'คนไข้' : 'แพทย์'}`}
          color="info"
        >
          <Icon
            icon="mdi:pencil"
            className={`w-[28px] h-[28px] ${
              disable
                ? 'cursor-not-allowed text-gray-main'
                : 'cursor-pointer hover:text-blue-main'
            }`}
            onClick={() =>
              !disable &&
              navigate(
                to === 'PATIENT'
                  ? `${route.USER_MANAGEMENT_ADD_PATIENT_PAGE_PAGE_URL}/${uId}`
                  : `${route.USER_MANAGEMENT_ADD_DOCTOR_PAGE_PAGE_URL}/${uId}`,
              )
            }
          />
        </StyleToolTip>
        <StyleToolTip
          title={`ลบข้อมูล${to === 'PATIENT' ? 'คนไข้' : 'แพทย์'}`}
          color="info"
        >
          <Icon
            icon="mdi:bin"
            className={`w-[28px] h-[28px] ${
              disable
                ? 'cursor-not-allowed text-gray-main'
                : 'cursor-pointer text-red-main hover:text-blue-main'
            }`}
            onClick={() =>
              !disable && setOpenDeleteModal({ uId: uId, state: true })
            }
          />
        </StyleToolTip>
        {to === 'DOCTOR' && (
          <StyleToolTip title="ดูข้อมูลที่วินิจฉัย" color="info">
            <Icon
              icon="material-symbols:article"
              className={`w-[28px] h-[28px] ${
                disable
                  ? 'cursor-not-allowed text-gray-main'
                  : 'cursor-pointer hover:text-blue-main'
              }`}
              onClick={() => !disable}
            />
          </StyleToolTip>
        )}
      </div>
    )
  }

  const formatData = (data) => {
    return data.map((item) => {
      const identity_id =
        tables === 'PATIENT'
          ? `${Array(7 - item.hnId.toString().length)
              .fill(0)
              .join('')}${item.hnId}`
          : item.UserAuth.identifyId

      return {
        [topicTable[0]]: {
          data: identity_id,
          color: 'black',
        },
        [topicTable[1]]: {
          data: `${item.prefix} ${item.firstname} ${item.lastname}`,
          color: 'black',
        },
        [topicTable[2]]: {
          data: Math.floor(
            (new Date().getTime() -
              new Date(item.UserMedicalDemograhicData.birthdate).getTime()) /
              (1000 * 3600 * 24) /
              365.25,
          ),
          color: 'black',
        },
        [topicTable[3]]: {
          data: item.UserMedicalDemograhicData.district,
          color: 'black',
        },
        [topicTable[4]]: {
          data: item.UserMedicalDemograhicData.province,
          color: 'black',
        },
        [topicTable[5]]: {
          data: date.convertToShowDateThai(item.createdAt),
          color: 'black',
        },
        [topicTable[6]]: {
          data: option(item.uId, tables, false),
          color: 'black',
        },
      }
    })
  }

  const handleSearchData = () => {
    setPage(1)
  }

  useEffect(() => {
    if (
      userData.fetching ||
      !userData.data ||
      userDataCount.fetching ||
      !userData.data
    )
      return

    setPageAmount(
      Math.ceil((userDataCount.data.aggregateUser?._count.uId || 0) / 10),
    )

    setUserTableData(formatData(userData.data.getUsers || []))
  }, [userData])

  useEffect(() => {
    setPage(1)
  }, [searchTable])

  return (
    <div className="p-[80px]">
      <div className="flex items-center gap-[10px]">
        <Icon
          icon="heroicons:document-chart-bar-solid"
          className="w-[28px] h-[28px]"
        />
        <p className="text-[24px]">จัดการข้อมูลผู้ใช้งาน</p>
      </div>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginTop: '40px',
          marginBottom: '25px',
        }}
      >
        <Tabs
          value={tables}
          onChange={(_, value: TablesStatus) => handleChangeTable(value)}
        >
          <Tab label="ข้อมูลคนไข้" value="PATIENT" sx={{ fontSize: '18px' }} />
          <Tab label="ข้อมูลแพทย์" value="DOCTOR" sx={{ fontSize: '18px' }} />
        </Tabs>
      </Box>

      <div
        className="mt-[20px] px-[20px] py-[30px] rounded-[8px] bg-white-main"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex flex-row justify-between">
          <div className="mb-[24px]">
            <Formik
              initialValues={{ text: '' }}
              onSubmit={() => handleSearchData()}
            >
              {(formik) => (
                <Form>
                  <div className="flex gap-[5px]">
                    <ElementComponent.InputBar
                      id="search-data-table"
                      name="text"
                      focusSpacing={false}
                      isLoading={false}
                      placeholder={
                        tables === 'PATIENT'
                          ? 'ค้นหา HN'
                          : 'ค้นหาเลขใบประกอบวิชาชีพ'
                      }
                      height="40px"
                      frontIcon={
                        <Icon
                          icon="material-symbols:search-rounded"
                          className="w-[20px] h-[20px]"
                        />
                      }
                      value={formik.values.text}
                      onChange={(text: string) => {
                        formik.setFieldValue('text', text)
                        setSearchTable(text)
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="mb-[24px]">
            <ElementComponent.Button
              id="submit-button"
              onAction={handleOnClickAddUserButton}
              icon="material-symbols:add"
              text={
                tables === 'PATIENT' ? 'เพิ่มข้อมูลคนไข้' : 'เพิ่มข้อมูลแพทย์'
              }
              width="200px"
              height="40px"
            />
          </div>
        </div>

        {!userData?.fetching ||
        !userDataCount.fetching ||
        !mutationDeleteUserResult.fetching ? (
          <div>
            <ElementComponent.Table col={topicTable} row={userTableData} />
            {userTableData.length === 0 && (
              <div className="my-[80px]">
                <p className="text-center text-[18px] text-black-lighter">
                  ไม่มีข้อมูลคนผู้ใช้งาน
                </p>
              </div>
            )}
          </div>
        ) : (
          <SkeletonComponent.TableSkeleton columnsAmount={4} />
        )}

        {pageAmount !== 0 && (
          <div className="mt-[30px] flex justify-center">
            <Pagination
              page={page}
              count={pageAmount}
              variant="outlined"
              shape="rounded"
              onChange={(_, newPage: number) => setPage(newPage)}
            />
          </div>
        )}
      </div>

      <AlertComponent.ConfirmModal
        icon="mdi:bin"
        text={`คุณต้องการลบข้อมูล${
          tables === 'PATIENT' ? 'คนไข้' : 'แพทย์'
        } หรือไหม?`}
        open={openDeleteModal.state}
        onClose={() => setOpenDeleteModal({ uId: '', state: false })}
        handleCancel={() => setOpenDeleteModal({ uId: '', state: false })}
        handleConfirm={() => handelOnDeleteUser()}
      />
    </div>
  )
}
