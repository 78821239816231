// Login Pages
const LOGIN_PAGE_URL = '/login'

// Overview Pages
const OVERVIEW_PAGE_URL = '/'

// Health data management Pages
const HEALTH_DATA_MANAGEMENT_URL = '/health-data-management'
const HEALTH_DATA_MANAGEMENT_ADD_URL = `${HEALTH_DATA_MANAGEMENT_URL}/add`
const HEALTH_DATA_MANAGEMENT_READ_URL = `${HEALTH_DATA_MANAGEMENT_URL}/:umdId`
const HEALTH_DATA_MANAGEMENT_UPDATE_URL = `${HEALTH_DATA_MANAGEMENT_URL}/:umdId/update`

//Disease master data
const DISEASE_MANAGEMENT_URL = '/disease-management'
const DISEASE_ADD_PAGE_URL = '/disease-management/add'
const DISEASE_UPDATE_PAGE_URL = '/disease-management/:udId'

// User management Pages
const USER_MANAGEMENT_PAGE_URL = '/user-management'
const USER_MANAGEMENT_MAIN_PAGE_URL = '/user-management/main'
const USER_MANAGEMENT_LIST_PAGE_URL = '/user-management/main/plist'
const USER_MANAGEMENT_CLINIC_PAGE_URL = '/user-management/main/clinic'
const USER_MANAGEMENT_CLINIC_OPEN_VISIT_PAGE_URL =
  '/user-management/main/clinic/visit'
const USER_MANAGEMENT_CLINIC_DIAGNOSE_PAGE_URL =
  '/user-management/main/clinic/diagnose'
const USER_MANAGEMENT_CLINIC_DIAGNOSE_DATA_PAGE_URL =
  '/user-management/main/clinic/diagnose/:patientId/:cId/:dId/:uid'
const DRUG_MANAGEMENT_MAIN_PAGE_URL = '/drug-management'
const DRUG_MANAGEMENT_ADD_PAGE_URL = '/drug-management/add'
const DRUG_MANAGEMENT_LIST_PAGE_URL = '/drug-management/list'
const DRUG_MANAGEMENT_UPDATE_PAGE_URL = '/drug-management/list/:duId'

const DRUG_TYPE_MANAGEMENT_LIST_PAGE_URL = '/drug-management/type/list'
const DRUG_TYPE_MANAGEMENT_ADD_PAGE_URL = '/drug-management/type/add'
const DRUG_TYPE_MANAGEMENT_UPDATE_PAGE_URL = '/drug-management/type/list/:dutId'

const USER_MANAGEMENT_PATIENT_OVERVIEW_DATA_URL =
  '/user-management/main/patient/:uId'
const USER_MANAGEMENT_PATIENT_HEALTH_DATA_URL =
  '/user-management/main/helth/:uId'
const USER_MANAGEMENT_PATIENT_HEALTH_URL = '/user-management/main/helth'
const USER_MANAGEMENT_PATIENT_OVERVIEW_URL = '/user-management/main/patient'
const USER_MANAGEMENT_ADD_PATIENT_PAGE_PAGE_URL = `${USER_MANAGEMENT_PAGE_URL}/patient`
const USER_MANAGEMENT_EDIT_PATIENT_PAGE_PAGE_URL = `${USER_MANAGEMENT_ADD_PATIENT_PAGE_PAGE_URL}/:uId`
const USER_MANAGEMENT_ADD_DOCTOR_PAGE_PAGE_URL = `${USER_MANAGEMENT_PAGE_URL}/doctor`
const USER_MANAGEMENT_EDIT_DOCTOR_PAGE_PAGE_URL = `${USER_MANAGEMENT_ADD_DOCTOR_PAGE_PAGE_URL}/:uId`

// Profile Pages
const SETTING_PAGE_URL = '/setting'
const PROFILE_PAGE_URL = `${SETTING_PAGE_URL}/profile`

// ETC
const EXPECT_PAGE_URL = '*'

export {
  DISEASE_MANAGEMENT_URL,
  DISEASE_ADD_PAGE_URL,
  DISEASE_UPDATE_PAGE_URL,
  DRUG_TYPE_MANAGEMENT_ADD_PAGE_URL,
  DRUG_TYPE_MANAGEMENT_UPDATE_PAGE_URL,
  DRUG_TYPE_MANAGEMENT_LIST_PAGE_URL,
  USER_MANAGEMENT_CLINIC_DIAGNOSE_PAGE_URL,
  USER_MANAGEMENT_CLINIC_DIAGNOSE_DATA_PAGE_URL,
  USER_MANAGEMENT_CLINIC_OPEN_VISIT_PAGE_URL,
  USER_MANAGEMENT_CLINIC_PAGE_URL,
  DRUG_MANAGEMENT_UPDATE_PAGE_URL,
  DRUG_MANAGEMENT_LIST_PAGE_URL,
  DRUG_MANAGEMENT_ADD_PAGE_URL,
  DRUG_MANAGEMENT_MAIN_PAGE_URL,
  USER_MANAGEMENT_PATIENT_HEALTH_DATA_URL,
  USER_MANAGEMENT_PATIENT_HEALTH_URL,
  USER_MANAGEMENT_MAIN_PAGE_URL,
  LOGIN_PAGE_URL,
  EXPECT_PAGE_URL,
  PROFILE_PAGE_URL,
  OVERVIEW_PAGE_URL,
  USER_MANAGEMENT_PAGE_URL,
  USER_MANAGEMENT_ADD_PATIENT_PAGE_PAGE_URL,
  USER_MANAGEMENT_EDIT_PATIENT_PAGE_PAGE_URL,
  USER_MANAGEMENT_ADD_DOCTOR_PAGE_PAGE_URL,
  USER_MANAGEMENT_EDIT_DOCTOR_PAGE_PAGE_URL,
  SETTING_PAGE_URL,
  HEALTH_DATA_MANAGEMENT_URL,
  HEALTH_DATA_MANAGEMENT_ADD_URL,
  HEALTH_DATA_MANAGEMENT_READ_URL,
  HEALTH_DATA_MANAGEMENT_UPDATE_URL,
  USER_MANAGEMENT_LIST_PAGE_URL,
  USER_MANAGEMENT_PATIENT_OVERVIEW_URL,
  USER_MANAGEMENT_PATIENT_OVERVIEW_DATA_URL,
}
