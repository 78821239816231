import { atom } from 'recoil'

const swipeableDrawerLoginStateAtom = atom({
  key: 'swipeableDrawerLogin',
  default: {
    state: false,
  },
})

const swipeableDrawerMenuStateAtom = atom({
  key: 'swipeableDrawerMenu',
  default: {
    state: false,
  },
})

export { swipeableDrawerLoginStateAtom, swipeableDrawerMenuStateAtom }
