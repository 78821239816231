import { Stack, TextField } from '@mui/material'
import { AlertComponent, ElementComponent, MenuComponent } from 'components'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { MedicalMutate } from 'services/graphql/medicalService'
import { DiseaseType } from 'types'
import { useMutation } from 'urql'
import { general } from 'utils'

function DiseaseAddPage() {
  const menuList = [
    { title: 'จัดการข้อมูลโรค', link: '' },
    { title: 'เพิ่มโรค', link: '' },
  ]

  const [thaiName, setThaiName] = useState<string>('')
  const [engName, setEngName] = useState<string>('')
  const initialValues: DiseaseType.Disease = {
    thaiName: thaiName,
    engName: engName,
  }

  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })

  const [createMedicalMutation, executeMedicalMutation] = useMutation(
    MedicalMutate.createUnderlyingDisease,
  )

  const createDisease = async (values: DiseaseType.Disease) => {
    let payload = {
      data: {
        medName: values.engName,
        thaiName: values.thaiName,
        engName: values.engName,
      },
    }
    const result = await executeMedicalMutation(payload)

    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: result.error.toString(),
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: '',
      onClose: () => {
        general.http.goto('/disease-management')
      },
    })

    return
  }

  return (
    <div className="p-[80px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      <div
        className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px]  text-[16px] mt-4"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <p className="text-[20px] text-blue-main">เพิ่มข้อมูลโรค</p>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          //validationSchema={validationSchema}
          onSubmit={createDisease}
        >
          {(formik) => (
            <Form className="w-[90%]">
              <Stack
                direction="row"
                spacing="10px"
                justifyContent="space-between"
                className="text-[14px] mt-4"
              >
                <div className="w-[40%]">
                  <p className="mt-2">ชื่อโรค(ภาษาไทย)</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => setThaiName(event.target.value)}
                  />
                </div>

                <div className="w-[40%]">
                  <p className="mt-2">ชื่อโรค(ภาษาอังกฤษ)</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => setEngName(event.target.value)}
                  />
                </div>
              </Stack>
              <ElementComponent.Button
                //submit
                id="submit-diagnose-button"
                width="w-main"
                height="32px"
                text="ยืนยันส่งข้อมูล"
                style="mt-4 "
                loading={createMedicalMutation.fetching}
                onAction={() => {
                  if (thaiName === '') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาระบุ ชื่อโรค(ภาษาไทย)',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else if (engName === '') {
                    setSubmitPopupData({
                      type: 'error',
                      open: true,
                      title: 'กรอกข้อมูลไม่ครบ',
                      description: 'กรุณาระบุ ชื่อโรค(ภาษาอังกฤษ)',
                      onClose: () => {
                        setSubmitPopupData({
                          type: 'success',
                          open: false,
                          title: '',
                          description: '',
                          onClose: () => {},
                        })
                      },
                    })
                  } else {
                    formik.submitForm()
                  }
                }}
              />
            </Form>
          )}
        </Formik>
        {submitPopupData.type === 'success' ? (
          <AlertComponent.SuccessModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        ) : (
          <AlertComponent.ErrorModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        )}
      </div>
    </div>
  )
}

export default DiseaseAddPage
