import React from 'react'
import { Grid, Skeleton } from '@mui/material'

type TopicConfigType = {
  key: number
  text: string
}

const topicConfig: TopicConfigType[] = [
  { key: 0, text: 'เพศ :' },
  { key: 1, text: 'อายุ :' },
  { key: 2, text: 'วันเกิด :' },
  { key: 3, text: 'จังหวัด :' },
  { key: 4, text: 'โรคประจำตัว :' },
  { key: 5, text: 'แพ้ยา/อาหาร :' },
]

export default function CardProfileSkeleton() {
  return (
    <div className="flex flex-col">
      {topicConfig.map((topic: TopicConfigType, index: number) => (
        <Grid key={`card-detail-${index}`} container sx={{ marginTop: '5px' }}>
          <Grid item mobile={5}>
            {topic.text}
          </Grid>
          <Grid item mobile={7}>
            <Skeleton variant="rounded" width="100%" />
          </Grid>
        </Grid>
      ))}
    </div>
  )
}
