import React from 'react'

function OverviewPage() {
  return (
    <div className="flex justify-center items-center h-[200px]">
      <h1>Overview Page</h1>
    </div>
  )
}

export default OverviewPage
