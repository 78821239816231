import React, { useEffect } from 'react'
import { Outlet, useMatch } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useQuery } from 'urql'

import NavbarDesktop from './NavbarDesktop'
import NavbarMobile from './NavbarMobile'
import AdminNavbar from './AdminNavbar'

import { route } from 'settings'
import { general } from 'utils'
import { useResponsive } from 'hooks'
import { useUser } from 'stores/recoil'
import { UserQuery } from 'services/graphql/userService'

function MainLayout() {
  const { isLaptop } = useResponsive()
  const [result] = useQuery(UserQuery.getUserProfile())
  const [, setUser] = useRecoilState(useUser.seletor.seletorUser)

  useEffect(() => {
    ;(async () => {
      if (general.auth.isAuthorized()) {
        const data = await result?.data?.getUser

        if (data) {
          setUser({
            firstname: data.firstname ?? '',
            imagePath: data.imagePath ?? '',
            hnId: data.hnId ?? 0,
          })
        }
      }
    })()
  }, [result])

  const hiddenNavbar = (): boolean => {
    const login = useMatch(route.LOGIN_PAGE_URL)

    return Boolean(login)
  }

  return (
    <div>
      {!hiddenNavbar() && <AdminNavbar />}
      <div
        className={`relative min-h-[100vh] bg-white-dark ${
          !hiddenNavbar() ? 'ml-[300px]' : ''
        }`}
      >
        {isLaptop ? <NavbarDesktop /> : <NavbarMobile />}
        <div className="pt-[90px] pb-[10px] h-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default MainLayout
