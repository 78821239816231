import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { AlertComponent, ElementComponent, MenuComponent } from 'components'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import { DrugType } from 'types'
import { useMutation, useQuery } from 'urql'
import { general } from 'utils'

function DrugUpdatePage() {
  const menuList = [
    { title: 'จัดการคลังยา', link: '' },
    { title: 'แก้ไขชนิดยา', link: '' },
  ]
  const unitList = [
    { title: 'mg' },
    { title: 'g' },
    { title: 'ml' },
    { title: 'mg/ml' },
    { title: 'mg/2ml' },
    { title: 'mg/3ml' },
    { title: 'mg/4ml' },
    { title: 'mg/5ml' },
  ]
  const typeList = [
    { title: 'เบาหวาน' },
    { title: 'ความดัน' },
    { title: 'ไขมัน' },
    { title: 'วิตามิน' },
    { title: 'อื่นๆ' },
    { title: '-' },
  ]

  const [isDrugTypeChoosed, setIsDrugTypeChoosed] = useState<boolean>(false)
  const [drugType, setDrugType] = useState<string>('')
  const [drugListApiData] = useQuery(MedicalQuery.getDrugTypeList())
  const [drugTypeData, setDrugTypeData] = useState([])
  const { duId } = useParams()
  const [drugApiOneData] = useQuery(MedicalQuery.getDrugOne(parseInt(duId)))

  const [dName, setDName] = useState<string>('')
  const [dUseType, setDUseType] = useState<string>('')
  const [tradeName, setTradeName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [cost, setCost] = useState<string>('0.0')
  const [income, setIncome] = useState<string>('0.0')
  const [alarmCutPoint, setAlarmCutPoint] = useState<string>('0')
  const [typeOne, setTypeOne] = useState<string>('')
  const [typeTwo, setTypeTwo] = useState<string>('')
  const [quantity, setQuantity] = useState<string>('')
  const [unit, setUnit] = useState<string>('')
  const [phrDrug, setPhrDrug] = useState<string>('')
  const [defaultShow, setDefaultShow] = useState<string>('')
  const [drugMainType, setDrugMainType] = useState<string>('')
  const [stock, setStock] = useState<number>(0)
  const [drugDefaultCondition, setDrugDefaultCondition] = useState([])
  const [injDefault, setInjDefault] = useState<string>('')
  const [injMin, setInjMin] = useState<string>('')
  const [injMax, setInjMax] = useState<string>('')
  const [injUnit, setInjUnit] = useState<string>('')
  const [injIM, setInjIM] = useState<string>('')
  const [injIV, setInjIV] = useState<string>('')
  const [injSC, setInjSC] = useState<string>('')
  const [invoiceType, setInvoiceType] = useState<string>('')
  const [defaultCondition, setDefaultCondition] = useState([])

  useEffect(() => {
    if (
      drugListApiData.fetching ||
      !drugListApiData.data ||
      drugApiOneData.fetching ||
      !drugApiOneData.data
    )
      return
    //alert(JSON.stringify(drugApiOneData.data))
    if (drugListApiData.error) {
      alert(JSON.stringify(drugListApiData.error))
    }
    if (!drugListApiData?.fetching && !drugListApiData.error) {
      let preData = []
      drugListApiData.data.getDrugType.map((item: any) => {
        preData.push({
          label: item.dutName,
          dutId: item.dutId,
          dutName: item.dutName,
        })
      })
      setDrugTypeData(preData)

      if (!drugApiOneData?.fetching && !drugApiOneData.error) {
        const item = drugApiOneData.data.getDrugOne
        setDName(item.dName)
        setDUseType(item.dUseType)
        setTradeName(item.tradeName)
        setDescription(item.description)
        setCost(item.cost)
        setIncome(item.income)
        setAlarmCutPoint(item.alarmCutPoint)
        setTypeOne(item.typeOne)
        setTypeTwo(item.typeTwo)
        setQuantity(item.quantity)
        setUnit(item.unit)
        setPhrDrug(item.phrDrug)
        setDefaultShow(item.defaultShow)
        setDrugMainType(item.drugMainType)
        setStock(item.stock)
        //setDrugDefaultCondition(item.drugDefaultCondition)
        setInjDefault(item.injDefault)
        setInjMin(item.injMin)
        setInjMax(item.injMax)
        setInjUnit(item.injUnit)
        setInjIM(item.injIM)
        setInjIV(item.injIV)
        setInjSC(item.injSC)
        setDrugType(item.dUseType)
        setInvoiceType(item.invoiceType)
        setIsDrugTypeChoosed(true)
        setDrugDefaultCondition(JSON.parse(item.drugDefaultCondition).data)
        //alert(item.drugDefaultCondition)
        let preCondition = []
        JSON.parse(item.drugDefaultCondition).data.map((itemCondition: any) => {
          preCondition.push({
            label: itemCondition.dutName,
            dutId: itemCondition.dutId,
            dutName: itemCondition.dutName,
          })
          //alert(itemCondition.label)
        })
        setDefaultCondition(preCondition)
        setDrugDefaultCondition(preCondition)
      }
    }
  }, [drugListApiData.data, drugApiOneData.data])

  const initialValues: DrugType.DrugDataType = {
    dName: dName,
    dUseType: dUseType,
    tradeName: tradeName,
    description: description,
    cost: cost,
    income: income,
    alarmCutPoint: alarmCutPoint,
    typeOne: typeOne,
    typeTwo: typeTwo,
    quantity: quantity,
    unit: unit,
    phrDrug: phrDrug,
    defaultShow: defaultShow,
    drugMainType: drugMainType,
    stock: stock,
    drugDefaultCondition: JSON.stringify({ data: drugDefaultCondition }),
    injDefault: injDefault,
    injMin: injMin,
    injMax: injMax,
    injUnit: injUnit,
    injIM: injIM,
    injIV: injIV,
    injSC: injSC,
    invoiceType: invoiceType,
  }

  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })

  const [updateMedicalMutation, executeMedicalMutation] = useMutation(
    MedicalMutate.updateDrug,
  )

  const updateDrug = async (values: DrugType.DrugDataType) => {
    let payload = {
      data: {
        dName: { set: values.dName },
        dUseType: { set: values.dUseType },
        tradeName: { set: values.tradeName },
        description: { set: values.description },
        cost: { set: values.cost },
        income: { set: values.income },
        alarmCutPoint: { set: values.alarmCutPoint },
        typeOne: { set: values.typeOne },
        typeTwo: { set: values.typeTwo },
        quantity: { set: values.quantity },
        unit: { set: values.unit },
        phrDrug: { set: values.phrDrug },
        defaultShow: { set: values.defaultShow },
        drugMainType: { set: values.drugMainType },
        stock: { set: values.stock },
        drugDefaultCondition: { set: values.drugDefaultCondition },
        injDefault: { set: values.injDefault },
        injMin: { set: values.injMin },
        injMax: { set: values.injMax },
        injUnit: { set: values.injUnit },
        injIM: { set: values.injIM },
        injIV: { set: values.injIV },
        injSC: { set: values.injSC },
        invoiceType: { set: values.invoiceType },
      },
      where: {
        duId: parseInt(duId),
      },
    }
    const result = await executeMedicalMutation(payload)

    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: result.error.toString(),
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: '',
      onClose: () => {
        general.http.goto('/drug-management/list')
      },
    })

    return
  }

  return (
    <div className="p-[80px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      <div
        className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px]  text-[16px] mt-4"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <p className="text-[20px] text-blue-main">แก้ไขชนิดยา</p>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          //validationSchema={validationSchema}
          onSubmit={updateDrug}
        >
          <Form className="w-[90%]">
            <Stack
              direction="row"
              spacing="10px"
              justifyContent="space-between"
              className="text-[14px] mt-4"
            >
              <div className="w-[40%]">
                <p className="mt-2">ชื่อยา</p>
                <TextField
                  className="w-[100%]"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={dName}
                  onChange={(event) => setDName(event.target.value)}
                />
              </div>
              <div className="w-[40%]">
                <p className="mt-2">ชื่อการค้า</p>
                <TextField
                  className="w-[100%]"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={tradeName}
                  onChange={(event) => setTradeName(event.target.value)}
                />
              </div>
              <div className="w-[20%]">
                <p className="mt-2">ขนาดยา</p>
                <TextField
                  className="w-[100%]"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={quantity}
                  onChange={(event) => setQuantity(event.target.value)}
                />
              </div>
              <div className="w-[20%]">
                <p className="mt-2">หน่วย</p>
                <FormControl className="w-[100%]" size="small">
                  <Select
                    name=""
                    value={unit}
                    onChange={(event: SelectChangeEvent) =>
                      setUnit(event.target.value)
                    }
                  >
                    {unitList.length > 0 &&
                      unitList.map((item: any) => (
                        <MenuItem value={item.title}>{item.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </Stack>

            <Stack direction="row" spacing="10px" className="text-[14px] mt-4">
              <div className="w-[30%]">
                <p className="mt-2">สรรพคุณยา</p>
                <TextField
                  className="w-[100%]"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
              <div className="w-[20%]">
                <p className="mt-2">ประเภทบัญชียา</p>
                <FormControl className="w-[100%]" size="small">
                  <Select
                    value={drugMainType}
                    onChange={(event: SelectChangeEvent) =>
                      setDrugMainType(event.target.value)
                    }
                  >
                    <MenuItem value={'ยาในบัญชี'}>ยาในบัญชี</MenuItem>
                    <MenuItem value={'ยานอกบัญชี ($)'}>ยานอกบัญชี ($)</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="w-[20%]">
                <p className="mt-2">PHR Drug</p>
                <FormControl className="w-[100%]" size="small">
                  <Select
                    value={phrDrug}
                    onChange={(event: SelectChangeEvent) =>
                      setPhrDrug(event.target.value)
                    }
                  >
                    <MenuItem value={'Yes'}>ใช่</MenuItem>
                    <MenuItem value={'No'}>ไม่ใช่</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="w-[20%]">
                <p className="mt-2">Default Show</p>
                <FormControl className="w-[100%]" size="small">
                  <Select
                    value={defaultShow}
                    onChange={(event: SelectChangeEvent) =>
                      setDefaultShow(event.target.value)
                    }
                  >
                    <MenuItem value={'Yes'}>ใช่</MenuItem>
                    <MenuItem value={'No'}>ไม่ใช่</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Stack>
            <Stack direction="row" spacing="10px" className="text-[14px] mt-4">
              <div className="w-[20%]">
                <p className="mt-2">กลุ่มยา 1</p>
                <FormControl className="w-[100%]" size="small">
                  <Select
                    value={typeOne}
                    onChange={(event: SelectChangeEvent) =>
                      setTypeOne(event.target.value)
                    }
                  >
                    {typeList.length > 0 &&
                      typeList.map((item: any) => (
                        <MenuItem value={item.title}>{item.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="w-[20%]">
                <p className="mt-2">กลุ่มยา 2</p>
                <FormControl className="w-[100%]" size="small">
                  <Select
                    value={typeTwo}
                    onChange={(event: SelectChangeEvent) =>
                      setTypeTwo(event.target.value)
                    }
                  >
                    {typeList.length > 0 &&
                      typeList.map((item: any) => (
                        <MenuItem value={item.title}>{item.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="w-[20%]">
                <p className="mt-2">หมวดเงินใบเสร็จ</p>
                <FormControl className="w-[100%]" size="small">
                  <Select
                    onChange={(event: SelectChangeEvent) =>
                      setInvoiceType(event.target.value)
                    }
                    value={invoiceType}
                  >
                    <MenuItem value="ค่ายา">ค่ายา</MenuItem>
                    <MenuItem value="ค่าหัตถการ">ค่าหัตถการ</MenuItem>
                    <MenuItem value="ค่าทางห้องปฏิบัติการ">
                      ค่าทางห้องปฏิบัติการ
                    </MenuItem>
                    <MenuItem value="ค่าวิชาชีพแพทย์">ค่าวิชาชีพแพทย์</MenuItem>
                    <MenuItem value="ค่าเวชภัณฑ์">ค่าเวชภัณฑ์</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Stack>
            <Stack direction="row" spacing="10px" className="text-[14px] mt-4">
              <div className="w-[20%]">
                <p className="mt-2">ต้นทุน (หน่วย)</p>
                <TextField
                  value={cost}
                  className="w-[100%]"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(event) => setCost(event.target.value)}
                />
              </div>
              <div className="w-[20%]">
                <p className="mt-2">ราคาขาย (หน่วย)</p>
                <TextField
                  value={income}
                  className="w-[100%]"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(event) => setIncome(event.target.value)}
                />
              </div>
            </Stack>
            <p className="text-[18px] mt-4">วิธีบริหารยา</p>
            <div className="w-[20%] mt-4">
              <p className="mt-2">ประเภทยา</p>
              <FormControl className="w-[100%]" size="small">
                <Select
                  value={dUseType}
                  onChange={(event: SelectChangeEvent) => {
                    setIsDrugTypeChoosed(true)
                    setDrugType(event.target.value)
                    setDUseType(event.target.value)
                  }}
                >
                  <MenuItem value={'ยากินเม็ด'}>ยากินเม็ด</MenuItem>
                  <MenuItem value={'ยาพ่น'}>ยาพ่น</MenuItem>
                  <MenuItem value={'ยาผงละลาย'}>ยาผงละลาย</MenuItem>
                  <MenuItem value={'ยาน้ำ'}>ยาน้ำ</MenuItem>
                  <MenuItem value={'ครีมภายนอก'}>ครีมภายนอก</MenuItem>
                  <MenuItem value={'ยาหยอดตา'}>ยาหยอดตา</MenuItem>
                  <MenuItem value={'ยาฉีด'}>ยาฉีด</MenuItem>
                </Select>
              </FormControl>
            </div>
            {isDrugTypeChoosed &&
              (drugType === 'ยากินเม็ด' ||
                drugType === 'ยาพ่น' ||
                drugType === 'ยาผงละลาย' ||
                drugType === 'ยาน้ำ' ||
                drugType === 'ครีมภายนอก' ||
                drugType === 'ยาหยอดตา') && (
                <div className="mt-4">
                  {!drugListApiData?.fetching && !drugListApiData.error && (
                    <Autocomplete
                      multiple
                      options={drugTypeData}
                      value={defaultCondition}
                      onChange={(_, value) => {
                        setDrugDefaultCondition(value)
                        setDefaultCondition(value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label=""
                          placeholder=""
                        />
                      )}
                    />
                  )}
                </div>
              )}
            {isDrugTypeChoosed && drugType === 'ยาฉีด' && (
              <Stack
                direction="row"
                spacing="10px"
                className="text-[14px] mt-4"
              >
                <div className="w-[10%]">
                  <p className="mt-2">Default</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    value={injDefault}
                    onChange={(event) => setInjDefault(event.target.value)}
                  />
                </div>
                <div className="w-[10%]">
                  <p className="mt-2">Min</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    value={injMin}
                    onChange={(event) => setInjMin(event.target.value)}
                  />
                </div>
                <div className="w-[10%]">
                  <p className="mt-2">Max</p>
                  <TextField
                    className="w-[100%]"
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    value={injMax}
                    onChange={(event) => setInjMax(event.target.value)}
                  />
                </div>
                <div className="w-[10%]">
                  <p className="mt-2">หน่วย</p>
                  <FormControl className="w-[100%]" size="small">
                    <Select
                      value={injUnit}
                      onChange={(event: SelectChangeEvent) =>
                        setInjUnit(event.target.value)
                      }
                    >
                      <MenuItem value={'mg'}>mg</MenuItem>
                      <MenuItem value={'g'}>g</MenuItem>
                      <MenuItem value={'ml'}>ml</MenuItem>
                      <MenuItem value={'amp'}>amp</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="w-[25%]">
                  <p className="mt-2">วิธีการฉีด</p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={injIM === 'Yes' ? true : false}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setInjIM('Yes')
                          } else {
                            setInjIM('No')
                          }
                        }}
                      />
                    }
                    label="IM"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={injIV === 'Yes' ? true : false}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setInjIV('Yes')
                          } else {
                            setInjIV('No')
                          }
                        }}
                      />
                    }
                    label="IV"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={injSC === 'Yes' ? true : false}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setInjSC('Yes')
                          } else {
                            setInjSC('No')
                          }
                        }}
                      />
                    }
                    label="SC"
                  />
                </div>
              </Stack>
            )}
            <div className="w-[20%]">
              <p className="mt-4 text-red-main">ปริมาณยาในคลัง</p>
              <TextField
                className="w-[100%]"
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={stock}
                onChange={(event) => setStock(parseInt(event.target.value))}
              />
            </div>
            <ElementComponent.Button
              submit
              id="submit-diagnose-button"
              width="w-main"
              height="32px"
              text="ยืนยัน"
              style="mt-2 ml-[40%]"
              loading={updateMedicalMutation.fetching}
              //onAction={() => alert(JSON.stringify(initialValues))}
            />
          </Form>
        </Formik>
        {submitPopupData.type === 'success' ? (
          <AlertComponent.SuccessModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        ) : (
          <AlertComponent.ErrorModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        )}
      </div>
    </div>
  )
}

export default DrugUpdatePage
