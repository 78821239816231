import { Grid, Stack, TextField } from '@mui/material'
import { AlertComponent, ElementComponent } from 'components'
import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { SelectBar } from 'components/element'
import { Form, Formik } from 'formik'
import { useMutation, useQuery } from 'urql'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import { general } from 'utils'
import dayjs from 'dayjs'
import { DiagnoseType } from 'types'
//import ImageForm from 'components/element/ImageForm'
//import { useUser } from 'stores/recoil'
//import { useRecoilState } from 'recoil'
import { ItemDrugForm, ItemIcdForm } from 'components/form'
import { PatientQuery, UserQuery } from 'services/graphql/userService'
//import { DrugPrint } from 'components/print'
import { useReactToPrint } from 'react-to-print'
import React from 'react'
import { DrugOnePrint, DrugPrint, InvoicePrint } from 'components/print'

/*interface IConsultSubmit {
  data: {
    placeName: string
    dateConsult: string
    time: string
    ctId: string
    textConsult: string
    uid: string
    temp: string
    bp_sys: string
    bp_dia: string
    hr: string
    rr: string
    doctorUid: string
    isDiagnose: boolean
    isPayedDrug: boolean
    typeConsult: string
  }
}*/

function ClinicDiagnosePage() {
  const componentRef = useRef()
  const componentRefOneDrug = useRef()
  const componentRefInvoice = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  const handlePrintOneDrug = useReactToPrint({
    content: () => componentRefOneDrug.current,
  })

  const handlePrintInvoice = useReactToPrint({
    content: () => componentRefInvoice.current,
  })
  //const [user] = useRecoilState(useUser.seletor.seletorUser)
  const { patientId } = useParams()
  const { cId } = useParams()
  const { dId } = useParams()
  const { uid } = useParams()
  const [icdTenValues, setIcdTenValues] = useState([])
  const [imgsVal, setImgsVal] = useState([])
  const [isShowApprointment] = useState<boolean>(false)
  //const [imageData, setImageData] = useState([])
  const [hour, setHour] = useState<string>('')
  const [textConsult, setTextConsult] = useState<string>('')
  const [minute, setMinute] = useState<string>('')
  const [date, setDate] = useState<Date>()
  const [cost, setCost] = useState<number>(0.0)
  const [income, setIncome] = useState<number>(0.0)
  const [runnumber, setRunNumber] = useState<number>(0)
  const [allergy, setSetAllergy] = useState<string>('')
  const [detail, setSetDetail] = useState<string>('')
  const [recommend, setSetRecommend] = useState<string>('')
  const [duId, setDuId] = useState<string>('')
  const [createdAt, setCreatedAt] = useState<string>('')
  const [patientDiagnoseLastOne] = useQuery(
    PatientQuery.getDiagnoseLastOne(dId),
  )
  const [incomeReal, setIncomeReal] = useState<string>('')
  const [patientData, setPatientData] = useState<any>({})
  const [patientUserData] = useQuery(PatientQuery.getUserByUserId(uid))

  const [drugValues, setDrugValues] = useState([])

  const [patientConsultFinishedList] = useQuery(
    PatientQuery.getPatientConsultFinishedList(
      dayjs(new Date()).format('DD/MM/YYYY'),
    ),
  )

  const [underlying, setUnderlying] = useState<any[]>([])
  const [doctorData] = useQuery({
    ...UserQuery.getUserPatientUpdate(duId),
  })
  const [underlyingData] = useQuery(MedicalQuery.getUnderlyingDisease(''))
  const handleUserData = (patientDisease: any[]): any[] => {
    if (patientDisease) {
      return patientDisease.map((patient: any) => ({
        key: patient.underlyingDisease.udId,
        value: patient.underlyingDisease.medName,
      }))
    } else {
      return null
    }
  }

  const handleOptions = (underlying: any[]): any[] => {
    return underlying?.map((diseaseItem: any) => ({
      key: diseaseItem.udId,
      value: diseaseItem.medName,
    }))
  }
  const [optionsChoice, setOptionsChoice] = useState<any[]>([])

  useEffect(() => {
    if (
      patientDiagnoseLastOne.fetching ||
      !patientDiagnoseLastOne.data ||
      patientUserData.fetching ||
      !patientUserData.data ||
      patientConsultFinishedList.fetching ||
      !patientConsultFinishedList.data ||
      underlyingData.fetching ||
      !underlyingData.data
    )
      return
    setSetAllergy(
      patientUserData.data?.getUser.UserMedicalDemograhicData?.allergic,
    )
    setUnderlying(
      handleUserData(
        patientUserData.data?.getUser.UserMedicalDemograhicData
          ?.userUnderlyingDiseaseTrasaction,
      ),
    )
    //setPatientData(getUser)
    const { getUnderlyingDiseases } = underlyingData.data
    setOptionsChoice(handleOptions(getUnderlyingDiseases))

    if (patientConsultFinishedList.data.getConsultList !== null) {
      setRunNumber(patientConsultFinishedList.data.getConsultList.length)
    }
    setCreatedAt(patientDiagnoseLastOne.data.getDiagnoseLastOne.createdAt)
    setDuId(patientDiagnoseLastOne.data.getDiagnoseLastOne.duId)

    setSetDetail(patientDiagnoseLastOne.data.getDiagnoseLastOne.detail)
    setSetAllergy(patientDiagnoseLastOne.data.getDiagnoseLastOne.allergy)
    setSetRecommend(patientDiagnoseLastOne.data.getDiagnoseLastOne.recommend)
    setIcdTenValues(
      JSON.parse(patientDiagnoseLastOne.data.getDiagnoseLastOne.icdData),
    )

    /*setDrugValues(
      JSON.parse(patientDiagnoseLastOne.data.getDiagnoseLastOne.drugData),
    )*/
    let preDrug = []
    JSON.parse(patientDiagnoseLastOne.data.getDiagnoseLastOne.drugData).map(
      (item: any, index: number) => {
        preDrug[index] = item
        preDrug[index].label =
          item.dName + ' ' + item.quantity + ' ' + item.unit
      },
    )
    setDrugValues(preDrug)
    let costTotal = 0.0
    let incomeTotal = 0.0
    JSON.parse(patientDiagnoseLastOne.data.getDiagnoseLastOne.drugData).map(
      (item: any) => {
        costTotal += Number.parseFloat(item.cost) * Number.parseFloat(item.qty)
        incomeTotal +=
          Number.parseFloat(item.income) * Number.parseFloat(item.qty)
      },
    )
    //alert(costTotal)
    setCost(costTotal)
    setIncome(incomeTotal)

    if (patientDiagnoseLastOne.data.getDiagnoseLastOne.incomeReal === '0')
      setIncomeReal(incomeTotal.toFixed(2))
    else
      setIncomeReal(patientDiagnoseLastOne.data.getDiagnoseLastOne.incomeReal)

    if (patientDiagnoseLastOne.data.getDiagnoseLastOne.imageFile !== null) {
      let imgs = []
      patientDiagnoseLastOne.data.getDiagnoseLastOne.imageFile
        .split(',')
        .map((img) => {
          imgs.push(img)
        })
      setImgsVal(imgs)
    }

    const { getUser } = patientUserData.data
    setPatientData(getUser)
    //alert(JSON.stringify(patientUserData.data))
  }, [patientDiagnoseLastOne.data, patientUserData.data])

  const handleHourList = (): SelectBar.OptionSelectType[] => {
    const hourOption = [
      '08',
      '09',
      '10',
      '11',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
    ]

    return hourOption.map((day: string) => ({
      key: day,
      value: day,
    }))
  }

  const handleRemoveDrugValues = (index: number) => {
    let newDrugVal = [...drugValues]
    newDrugVal.splice(index, 1)
    setDrugValues(newDrugVal)
  }

  const handleSetDrugValues = (index: number, data: any) => {
    let newDrugVal = [...drugValues]
    newDrugVal[index] = data
    setDrugValues(newDrugVal)

    let costTotal = 0.0
    let incomeTotal = 0.0
    drugValues.map((item: any) => {
      costTotal += Number.parseFloat(item.cost) * Number.parseFloat(item.qty)
      incomeTotal +=
        Number.parseFloat(item.income) * Number.parseFloat(item.qty)
    })
    setCost(costTotal)
    setIncome(incomeTotal)
  }

  const handleRemoveIcdTenValues = (index: number) => {
    let newIcdVal = [...icdTenValues]
    newIcdVal.splice(index, 1)
    setIcdTenValues(newIcdVal)
  }

  const handleSetIcdTenValues = (index: number, data: any) => {
    let newIcdVal = [...icdTenValues]
    newIcdVal[index] = data
    setIcdTenValues(newIcdVal)
  }

  const initialValues: DiagnoseType.DiagnoseType = {
    icdData: JSON.stringify(icdTenValues),
    drugData: JSON.stringify(drugValues),
    allergy: allergy,
    detail: detail,
    recommend: recommend,
    uId: patientId,
    costTotal: cost.toFixed(2),
    incomeTotal: income.toFixed(2),
    incomeReal: incomeReal,
    duId: '', //doctor_id
    images: [],

    placeName: 'online',
    time: hour + '.' + minute,
    ctId: 'ปรึกษาเเพทย์โรคทั่วไป',
    dateConsult: date,
    textConsult: textConsult,
    uid: patientId,
    temp: '-',
    bp_sys: '-',
    bp_dia: '-',
    hr: '-',
    rr: '-',
    doctorUid: '-',
    isDiagnose: false,
    isPayedDrug: false,
    typeConsult: 'online',
  }

  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateMedicalMutation, executeMedicalMutation] = useMutation(
    MedicalMutate.updateDiagnose,
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  /*const [createConsultMutation, executeMutationConsult] = useMutation(
    MedicalMutate.createConsult,
  )*/

  const [, executeUpdateConsultMutation] = useMutation(
    MedicalMutate.updateConsultPayed,
  )
  const updateDiagnose = async (values: DiagnoseType.DiagnoseType) => {
    let payload = {
      data: {
        icdData: { set: values.icdData },
        drugData: { set: values.drugData },
        costTotal: { set: values.costTotal },
        incomeTotal: { set: values.incomeTotal },
        incomeReal: { set: incomeReal },
      },
      where: {
        dId: dId,
      },
    }
    let payloadConsult = {
      data: {
        dId: { set: dId },
        isDiagnose: { set: true },
        isPayedDrug: { set: true },
        finishedFlag: { set: true },
      },
      where: {
        cId: cId,
      },
    }
    await executeUpdateConsultMutation(payloadConsult)
    await executeMedicalMutation(payload)
    /*if (isShowApprointment) {
      let payload: IConsultSubmit = {
        data: {
          placeName: values.placeName,
          dateConsult: dayjs(values.dateConsult).format('DD/MM/YYYY'),
          time: values.time,
          ctId: values.ctId,
          textConsult: values.textConsult,
          uid: values.uid,
          temp: '-',
          bp_sys: '-',
          bp_dia: '-',
          hr: '-',
          rr: '-',
          doctorUid: user['uId'],
          isDiagnose: false,
          isPayedDrug: false,
          typeConsult: 'online',
        },
      }
      await executeMutationConsult(payload)
    }
    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: result.error.toString(),
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }*/
    //alert(JSON.stringify(await executeUpdateConsultMutation(payloadConsult)))
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: '',
      onClose: () => {
        general.http.goto('/user-management/main/clinic')
      },
    })

    return
  }
  return (
    <div className="p-[40px]">
      {!patientUserData.fetching && !patientUserData.error && (
        <>
          <Grid
            container
            columns={{ desktop: 2.02 }}
            gap="8px"
            className="w-[90%] ml-[120px]"
          >
            <Grid item desktop={1}>
              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.4}>
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                    ผู้ป่วย
                  </p>
                </Grid>
                <Grid item desktop={1.2}>
                  <p className="text-[16px]  p-[4px] ">
                    {patientUserData.data.getUser.prefix}{' '}
                    {patientUserData.data.getUser.firstname}{' '}
                    {patientUserData.data.getUser.lastname}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item desktop={1}>
              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.4}>
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                    HN
                  </p>
                </Grid>
                <Grid item desktop={1.2}>
                  <p className="text-[16px]  p-[4px]">
                    {`${Array(
                      7 - patientUserData.data.getUser.hnId.toString().length,
                    )
                      .fill(0)
                      .join('')}${patientUserData.data.getUser.hnId}`}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            columns={{ desktop: 2.02 }}
            gap="8px"
            className="w-[90%] ml-[120px]"
          >
            <Grid item desktop={1}>
              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.4}>
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                    แพทย์
                  </p>
                </Grid>
                <Grid item desktop={1.2}>
                  {!doctorData.fetching &&
                    !doctorData.error &&
                    doctorData.data.getUser !== null && (
                      <p className="text-[16px]  p-[4px] ">
                        {' '}
                        {doctorData.data.getUser.prefix}{' '}
                        {doctorData.data.getUser.firstname}{' '}
                        {doctorData.data.getUser.lastname}
                      </p>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item desktop={1}>
              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.4}>
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                    เบอร์โทรศัพท์
                  </p>
                </Grid>
                <Grid item desktop={1.2}>
                  <p className="text-[16px]  p-[4px]">
                    {patientUserData.data.getUser.UserAuth.phoneNumber}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            columns={{ desktop: 2.02 }}
            gap="8px"
            className="w-[90%] ml-[120px]"
          >
            <Grid item desktop={1}>
              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.4}>
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                    วันที่และเวลา
                  </p>
                </Grid>
                <Grid item desktop={1.2}>
                  <p className="text-[16px]  p-[4px] ">
                    {dayjs(createdAt).format('DD MMMM BBBB เวลา H:mm น.')}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item desktop={1}>
              <Grid
                container
                columns={{ desktop: 2.02 }}
                gap="8px"
                className="mt-2"
              >
                <Grid item desktop={0.4}>
                  <p className="text-[16px] text-white-main  bg-blue-main rounded-[20px] w-[120px] p-[4px] text-center">
                    วัน เดือน ปี เกิด
                  </p>
                </Grid>
                <Grid item desktop={1.2}>
                  <p className="text-[16px]  p-[4px]">
                    {dayjs(
                      patientUserData.data.getUser.UserMedicalDemograhicData
                        .birthdate,
                    ).format('DD MMMM BBBB')}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        container
        justifyContent="center"
        columns={{ desktop: 2.2 }}
        gap="8px"
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          //validationSchema={validationSchema}
          onSubmit={updateDiagnose}
        >
          <Form className="w-[90%]">
            <div>
              <Grid item desktop={2.1} className="">
                <div className="w-[100%] p-[16px]">
                  <Grid
                    container
                    justifyContent="center"
                    columns={{ desktop: 2.1 }}
                    gap="16px"
                    className="mt-4"
                  >
                    <Grid item desktop={1}>
                      <div>
                        <p className="text-[22px] text-blue-main mt-1">
                          รายละเอียดการตรวจ
                        </p>
                        <TextField
                          multiline={true}
                          rows={4}
                          className="w-[100%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          value={detail}
                          onChange={(event) => setSetDetail(event.target.value)}
                        />
                        <p className="mt-2">คำแนะนำ</p>
                        <TextField
                          multiline={true}
                          rows={4}
                          className="w-[100%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          value={recommend}
                          onChange={(event) =>
                            setSetRecommend(event.target.value)
                          }
                        />
                        <p className="mt-2">แพ้ยา/แพ้อาหาร</p>
                        <TextField
                          className="w-[100%]"
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          value={allergy}
                          onChange={(event) =>
                            setSetAllergy(event.target.value)
                          }
                        />
                        <p className="mt-2">โรคประจำตัว</p>
                        <div>
                          <ElementComponent.MultiSelectBar
                            name="underlying"
                            isLoading={true}
                            height="30px"
                            data={underlying}
                            options={optionsChoice}
                            label=""
                            placeholder="โรคประจำตัว"
                            onSelect={(options) => {
                              //alert(JSON.stringify(options))
                              setUnderlying(options)
                              //formik.setFieldValue('underlying', options)
                              /*onChangeValue((prev: any) => ({
                              ...prev,
                              underlying: options,
                            }))*/
                            }}
                          />
                        </div>
                        <p className="text-[22px] text-blue-main mt-1">
                          วินิจฉัย
                        </p>
                        <p>ICD-10</p>
                        <Stack
                          direction={{ laptop: 'column' }}
                          className=" w-[100%] flex gap-[8px]"
                        >
                          {icdTenValues.length >= 1 &&
                            icdTenValues.map((value, index: number) => (
                              <ItemIcdForm
                                defaultValue={value}
                                index={index}
                                onRemove={() => handleRemoveIcdTenValues(index)}
                                onSelected={(selectVal) => {
                                  if (selectVal !== null) {
                                    handleSetIcdTenValues(index, selectVal)
                                  } else {
                                    handleSetIcdTenValues(index, '')
                                  }
                                }}
                              />
                            ))}
                        </Stack>
                        {/*<ElementComponent.Button
                          //disable={patientUserData.fetching}
                          id="submit-diagnose-button"
                          width="w-main"
                          height="32px"
                          text="เพิ่ม"
                          style="mt-2"
                          onAction={() =>
                            setIcdTenValues([
                              ...icdTenValues,
                              { icdCode: '', icdName: '' },
                            ])
                          }
                          //onAction={onClickSave}
                        />*/}
                        <p className="text-[16px] text-white-main w-[90px]  bg-blue-main rounded-[20px] text-center mt-4 mb-2">
                          รูปภาพ
                        </p>
                        {/*<ImageForm
                          key={`value`}
                          loading={false}
                          disable={false}
                          exampleImage={''}
                          onChoose={(images) => {
                            setImageData(images)
                          }}
                        />*/}
                        <Grid
                          container
                          columns={{ desktop: 4.1 }}
                          gap="16px"
                          className="mt-4"
                        >
                          {imgsVal.map((img: string) => (
                            <Grid item desktop={1}>
                              <ElementComponent.Image
                                fullImage={true}
                                src={img}
                                className="w-full"
                              />
                            </Grid>
                          ))}
                        </Grid>
                        {/*<SVG.BtnImageAdd />*/}
                      </div>
                    </Grid>
                    <Grid item desktop={1}>
                      <div>
                        <Stack
                          direction={{ laptop: 'column' }}
                          className=" w-[100%] flex gap-[8px]"
                        >
                          <Stack
                            direction={{ laptop: 'row' }}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <p className="text-[22px] text-blue-main mt-1">
                              วินิจฉัย
                            </p>
                          </Stack>
                          <Stack
                            direction={{ laptop: 'row' }}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <p className="w-[35%]">ชื่อยา</p>
                            <p className="w-[35%]">วิธีบริหารยา</p>
                            <p className="w-[15%]">จำนวน</p>
                            <p className="w-[10%]">PHR</p>
                          </Stack>
                          <Stack
                            direction={{ laptop: 'column' }}
                            className=" w-[100%] flex gap-[8px]"
                          >
                            {drugValues.length >= 1 &&
                              drugValues.map((value, index: number) => (
                                <ItemDrugForm
                                  defaultVal={value}
                                  index={index}
                                  onRemove={() => handleRemoveDrugValues(index)}
                                  onSelected={(selectVal) => {
                                    if (selectVal !== null) {
                                      handleSetDrugValues(index, selectVal)
                                    } else {
                                      handleSetDrugValues(index, '')
                                    }
                                  }}
                                />
                              ))}
                            {/*<ElementComponent.Button
                              //disable={patientUserData.fetching}
                              id="submit-diagnose-button"
                              width="w-main"
                              height="32px"
                              text="เพิ่ม"
                              style="mt-2"
                              onAction={() =>
                                setDrugValues([
                                  ...drugValues,
                                  {
                                    duId: 0,
                                    dName: '',
                                    dutId: 0,
                                    dutName: '',
                                    qty: 0,
                                    isPhr: 'No',
                                    income: 0.0,
                                    cost: 0.0,
                                    label: '',
                                  },
                                ])
                              }
                            />*/}

                            <Stack
                              direction={{ laptop: 'row' }}
                              justifyContent="space-between"
                              alignItems="center"
                              className=" w-[50%] ml-[37%]"
                            >
                              <p className="w-[45%]">ต้นทุน</p>
                              <p className="w-[45%]">รายได้</p>
                            </Stack>
                            <Stack
                              direction={{ laptop: 'column' }}
                              className=" w-[100%] flex gap-[8px]"
                            >
                              <Stack
                                direction={{ laptop: 'row' }}
                                justifyContent="space-between"
                                alignItems="center"
                                className=" w-[50%] ml-[37%]"
                              >
                                <TextField
                                  className="w-[45%]"
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={cost.toFixed(2)} //ต้นทุน
                                />
                                <TextField
                                  className="w-[45%]"
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={income.toFixed(2)} //ราคาขาย
                                />
                              </Stack>
                            </Stack>
                            <Stack
                              direction={{ laptop: 'row' }}
                              justifyContent="space-between"
                              alignItems="center"
                              className=" w-[50%] ml-[50%]"
                            >
                              <p className="w-[45%] text-red-main">
                                เงินที่รับจริง
                              </p>
                            </Stack>
                            <Stack
                              direction={{ laptop: 'column' }}
                              className=" w-[100%] flex gap-[8px]"
                            >
                              <Stack
                                direction={{ laptop: 'row' }}
                                justifyContent="space-between"
                                alignItems="center"
                                className=" w-[50%] ml-[50%]"
                              >
                                <TextField
                                  className="w-[45%] text-red-main"
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={incomeReal} //ราคาขาย
                                  onChange={(event) =>
                                    setIncomeReal(event.target.value)
                                  }
                                />
                              </Stack>
                            </Stack>
                          </Stack>

                          <p className="text-[22px] text-blue-main mt-1">
                            วันนัด
                          </p>
                          {/*<p
                            onClick={() => {
                              setIsShowApprointment(true)
                            }}
                            className="text-[16px] text-white-main w-[90px]  bg-blue-main rounded-[20px] text-center mt-2 mb-2 cursor-pointer"
                          >
                            เพิ่มการนัด
                          </p>*/}
                          {isShowApprointment ? (
                            <div
                              className="text-[16px] rounded-[5px] p-[10px]"
                              style={{
                                borderColor: '#ccc',
                                borderWidth: '1px',
                              }}
                            >
                              <Grid
                                container
                                justifyContent="start"
                                columns={{ desktop: 3.3 }}
                                gap="8px"
                              >
                                <Grid item desktop={3.24} className="">
                                  <ElementComponent.DatePicker
                                    dateValue={null}
                                    name="createDate"
                                    isSubmitting={null}
                                    height="60px"
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    onChange={(value: string) => {
                                      setDate(dayjs(value).toDate())
                                      //(data.date = dayjs(value).toDate())
                                    }}
                                    yearLength={2}
                                  />
                                </Grid>
                                <Grid item desktop={1.6}>
                                  <SelectBar.SelectBar
                                    focusSpacing={true}
                                    isLoading={false}
                                    name="hour"
                                    label={'ชั่วโมง'}
                                    placeholder={'ชั่วโมง'}
                                    width="w-full"
                                    height={'60px'}
                                    data={hour}
                                    option={handleHourList()}
                                    onChange={(value: string) => {
                                      setHour(value)
                                      //formik.setFieldValue('day', Number(value))
                                    }}
                                  />
                                </Grid>
                                <Grid item desktop={1.6}>
                                  <SelectBar.SelectBar
                                    focusSpacing={true}
                                    isLoading={false}
                                    name="min"
                                    label={'นาที'}
                                    placeholder={'นาที'}
                                    width="w-full"
                                    height={'60px'}
                                    data={minute}
                                    option={[
                                      { key: '00', value: '00' },
                                      { key: '30', value: '30' },
                                    ]}
                                    onChange={(value: string) => {
                                      setMinute(value)
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <div>
                                <p className="mt-2">หมายเหตุการนัด</p>
                                <TextField
                                  className="w-[100%]"
                                  size="small"
                                  id="outlined-basic"
                                  variant="outlined"
                                  onChange={(event) =>
                                    setTextConsult(event.target.value)
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className="text-[16px] text-gray-main rounded-[5px] text-center pt-[50px] pb-[50px]"
                              style={{
                                borderColor: '#ccc',
                                borderWidth: '1px',
                              }}
                            >
                              <p>ไม่มีข้อมูลวันนัด</p>
                            </div>
                          )}
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid
                container
                columns={{ desktop: 4.2 }}
                className="flex gap-[20px] text-white-main"
                justifyContent="center"
              >
                <Grid item desktop={0.9} justifyContent="center">
                  <ElementComponent.Button
                    //submit
                    id="submit-diagnose-button"
                    width="100%"
                    height="32px"
                    text="พิมพ์สติ๊กเกอร์"
                    style="mt-2"
                    onAction={handlePrintOneDrug}
                    //onAction={() => alert(JSON.stringify(drugValues))}
                  />
                  {!patientDiagnoseLastOne.fetching &&
                    !patientDiagnoseLastOne.error &&
                    !patientUserData.fetching &&
                    !patientUserData.error && (
                      <div style={{ display: 'none' }}>
                        <DrugOnePrint
                          ref={componentRefOneDrug}
                          diagnoseData={
                            patientDiagnoseLastOne.data.getDiagnoseLastOne
                          }
                          patientData={patientData}
                        />
                      </div>
                    )}
                </Grid>
                <Grid item desktop={0.9} justifyContent="center">
                  <ElementComponent.Button
                    //submit
                    id="submit-diagnose-button"
                    width="100%"
                    height="32px"
                    text="พิมพ์ใบเสร็จ"
                    style="mt-2 "
                    onAction={handlePrintInvoice}
                    //onAction={() => alert(JSON.stringify(drugValues))}
                  />
                  {!patientDiagnoseLastOne.fetching &&
                    !patientDiagnoseLastOne.error &&
                    !patientUserData.fetching &&
                    !patientUserData.error && (
                      <div style={{ display: 'none' }}>
                        <InvoicePrint
                          ref={componentRefInvoice}
                          diagnoseData={
                            patientDiagnoseLastOne.data.getDiagnoseLastOne
                          }
                          patientData={patientData}
                          runNumber={runnumber}
                          income={income + ''}
                        />
                      </div>
                    )}
                </Grid>
                <Grid item desktop={0.9} justifyContent="center">
                  <ElementComponent.Button
                    //submit
                    id="submit-diagnose-button"
                    width="100%"
                    height="32px"
                    text="พิมพ์ใบสั่งยา"
                    style="mt-2 "
                    onAction={handlePrint}
                    //onAction={() => alert(JSON.stringify(drugValues))}
                  />
                  {!patientDiagnoseLastOne.fetching &&
                    !patientDiagnoseLastOne.error &&
                    !patientUserData.fetching &&
                    !patientUserData.error && (
                      <div style={{ display: 'none' }}>
                        <DrugPrint
                          ref={componentRef}
                          diagnoseData={
                            patientDiagnoseLastOne.data.getDiagnoseLastOne
                          }
                          patientData={patientData}
                          runNumber={runnumber}
                        />
                      </div>
                    )}
                </Grid>
                <Grid item desktop={0.9} justifyContent="center">
                  <ElementComponent.Button
                    submit
                    id="submit-diagnose-button"
                    width="100%"
                    height="32px"
                    text="เสร็จสิ้น"
                    style="mt-2 bg-green-main "
                    loading={
                      updateMedicalMutation.fetching
                      //createConsultMutation.fetching
                    }
                    //onAction={() => alert(JSON.stringify(drugValues))}
                  />
                </Grid>
              </Grid>
            </div>
          </Form>
        </Formik>
        {submitPopupData.type === 'success' ? (
          <AlertComponent.SuccessModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        ) : (
          <AlertComponent.ErrorModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        )}
      </Grid>
    </div>
  )
}
export default ClinicDiagnosePage
