import React from 'react'
import { useRoutes, Navigate, BrowserRouter } from 'react-router-dom'

import { NavComponent } from 'components'
import { route } from 'settings'
import { general } from 'utils'
import {
  LoginPages,
  ProfilePages,
  OverviewPages,
  UserManagementPage,
  HealthDataManagementPage,
  DrugManagementPage,
  DiseaseManagementPages,
} from 'pages'

function RoutePage() {
  const auth = general.auth.isAuthorized()

  const redirectCondition = (condition: boolean, children: React.ReactNode) => {
    if (condition) return children

    if (!auth) return <Navigate replace to={route.LOGIN_PAGE_URL} />
    else return <Navigate replace to={route.OVERVIEW_PAGE_URL} />
  }

  return useRoutes([
    {
      path: '/',
      element: <NavComponent.MainLayout />,
      children: [
        {
          path: route.LOGIN_PAGE_URL,
          element: redirectCondition(!auth, <LoginPages.Login />),
        },
        {
          path: route.OVERVIEW_PAGE_URL,
          element: redirectCondition(auth, <OverviewPages.OverviewPage />),
        },
        {
          path: route.DRUG_MANAGEMENT_MAIN_PAGE_URL,
          children: [
            {
              path: route.DRUG_MANAGEMENT_MAIN_PAGE_URL,
              element: redirectCondition(
                auth,
                <DrugManagementPage.DrugOverviewPage />,
              ),
            },
            {
              path: route.DRUG_MANAGEMENT_ADD_PAGE_URL,
              element: redirectCondition(
                auth,
                <DrugManagementPage.DrugAddPage />,
              ),
            },
            {
              path: route.DRUG_MANAGEMENT_LIST_PAGE_URL,
              element: redirectCondition(
                auth,
                <DrugManagementPage.DrugListPage />,
              ),
            },
            {
              path: route.DRUG_MANAGEMENT_UPDATE_PAGE_URL,
              element: redirectCondition(
                auth,
                <DrugManagementPage.DrugUpdatePage />,
              ),
            },
            {
              path: route.DRUG_TYPE_MANAGEMENT_UPDATE_PAGE_URL,
              element: redirectCondition(
                auth,
                <DrugManagementPage.DrugTypeUpdatePage />,
              ),
            },
            {
              path: route.DRUG_TYPE_MANAGEMENT_LIST_PAGE_URL,
              element: redirectCondition(
                auth,
                <DrugManagementPage.DrugTypeListPage />,
              ),
            },
            {
              path: route.DRUG_TYPE_MANAGEMENT_ADD_PAGE_URL,
              element: redirectCondition(
                auth,
                <DrugManagementPage.DrugTypeAddPage />,
              ),
            },
          ],
        },
        {
          path: route.DISEASE_MANAGEMENT_URL,
          children: [
            {
              path: route.DISEASE_MANAGEMENT_URL,
              element: redirectCondition(
                auth,
                <DiseaseManagementPages.DiseaseManagementPage />,
              ),
            },
            {
              path: route.DISEASE_ADD_PAGE_URL,
              element: redirectCondition(
                auth,
                <DiseaseManagementPages.DiseaseAddPage />,
              ),
            },
            {
              path: route.DISEASE_UPDATE_PAGE_URL,
              element: redirectCondition(
                auth,
                <DiseaseManagementPages.DiseaseUpdatePage />,
              ),
            },
          ],
        },
        {
          path: route.HEALTH_DATA_MANAGEMENT_URL,
          children: [
            {
              path: route.HEALTH_DATA_MANAGEMENT_URL,
              element: redirectCondition(
                auth,
                <HealthDataManagementPage.HealthDataManagementPage />,
              ),
            },
            {
              path: route.HEALTH_DATA_MANAGEMENT_ADD_URL,
              element: redirectCondition(
                auth,
                <HealthDataManagementPage.AddHealthDataManagementPage />,
              ),
            },
            {
              path: route.HEALTH_DATA_MANAGEMENT_READ_URL,
              element: redirectCondition(
                auth,
                <HealthDataManagementPage.HealthDataPage />,
              ),
            },
            {
              path: route.HEALTH_DATA_MANAGEMENT_UPDATE_URL,
              element: redirectCondition(
                auth,
                <HealthDataManagementPage.EditHealthDataManagementPage />,
              ),
            },
          ],
        },
        {
          path: route.USER_MANAGEMENT_PAGE_URL,
          children: [
            {
              path: route.USER_MANAGEMENT_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.UserManagementPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_MAIN_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.UserManagementMainPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_PATIENT_HEALTH_DATA_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.CreateHealthSection />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_PATIENT_OVERVIEW_DATA_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.DiabetesPage />,
              ),
            },

            {
              path: route.USER_MANAGEMENT_LIST_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.UserManagementListPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_ADD_PATIENT_PAGE_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.AddPatientUserPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_EDIT_PATIENT_PAGE_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.EditPatientUserPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_ADD_DOCTOR_PAGE_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.AddDoctorUserPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_EDIT_DOCTOR_PAGE_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.EditDoctorUserPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_CLINIC_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.ClinicMainPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_CLINIC_OPEN_VISIT_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.ClinicVisitOpenPage />,
              ),
            },
            {
              path: route.USER_MANAGEMENT_CLINIC_DIAGNOSE_DATA_PAGE_URL,
              element: redirectCondition(
                auth,
                <UserManagementPage.ClinicDiagnosePage />,
              ),
            },
          ],
        },
        {
          path: route.SETTING_PAGE_URL,
          element: <NavComponent.UserLayout />,
          children: [
            {
              path: route.SETTING_PAGE_URL,
              element: <Navigate to={route.PROFILE_PAGE_URL} />,
            },
            {
              path: route.PROFILE_PAGE_URL,
              element: redirectCondition(
                auth,
                <ProfilePages.UserProfilePage />,
              ),
            },
          ],
        },
      ],
    },
    {
      path: route.EXPECT_PAGE_URL,
      element: <Navigate to={route.LOGIN_PAGE_URL} />,
    },
  ])
}

const Router = () => {
  return (
    <BrowserRouter basename={'/'}>
      <RoutePage />
    </BrowserRouter>
  )
}

export default Router
