import React, { useState, useRef } from 'react'
import { useMutation, useQuery } from 'urql'
import { Formik, Form } from 'formik'
import { Icon } from '@iconify/react'
import Resizer from 'react-image-file-resizer'
import dayjs from 'dayjs'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'
import { Skeleton } from '@mui/material'

import { UserMutate, UserQuery } from 'services/graphql/userService'
import { route } from 'settings'
import { ProfileType, RegisterType } from 'types'
import { useResponsive } from 'hooks'
import { AvatarProfile } from 'assets/svg'

import {
  ElementComponent,
  FormComponent,
  AlertComponent,
  MenuComponent,
} from 'components'

interface UserProfileFormProps {
  onOpenModal: Function
  setErrorModalData: React.Dispatch<
    React.SetStateAction<{
      title: string
      description: string
      open: boolean
    }>
  >
  data: {
    UserAuth: {
      email: string
      phoneNumber: string
      identifyId: string
    }
    UserMedicalDemograhicData: {
      birthdate: Date
      district: string
      province: string
      gender: string
    }
    prefix: string
    firstname: string
    lastname: string
    imagePath: string
  }
  uId: string
}

const phoneRegExp = /^(0[689]{1})+([0-9]{8})+$/

const validationProfile = yup.object({
  image: yup.string().nullable(),
  prefix: yup.string().required('กรุณาเลือกคำนำหน้า'),
  firstname: yup
    .string()
    .max(30, 'กรุณากรอกชื่อจริงไม่เกิน 30 ตัวอักษร')
    .required('กรุณากรอกชื่อจริงไม่เกิน 30 ตัวอักษร'),
  lastname: yup
    .string()
    .max(30, 'กรุณากรอกนามสกุลไม่เกิน 30 ตัวอักษร')
    .required('กรุณากรอกนามสกุลไม่เกิน 30 ตัวอักษร'),
  gender: yup.string().required('กรุณาเลือกเพศ'),
  birthdate: yup
    .date()
    .test(
      'check-date-more-than-today',
      'วันเกิดไม่ครวเป็นวันในอนาคต',
      (value) => {
        if (dayjs().add(543, 'year').isBefore(dayjs(value))) return false
        return true
      },
    )
    .test(
      'check-date-more-less-than-130-year',
      'ต้องขออภัย ระบบไม่รองรับคนที่อายุมากกว่า 130 ปี',
      (value) => {
        if (dayjs(value) < dayjs().subtract(130, 'year')) return false
        return true
      },
    )
    .typeError(
      'รูปแบบของวันที่ไม่ถูกต้อง โปรดระบุในรูปแบบ วัน/เดือน/ปี และ ปีเป็น พศ.',
    )
    .nullable()
    .required('กรุณาเลือกวัน/เดือน/ปี เกิด'),
  province: yup.string().required('กรุณาเลือกจังหวัด'),
  district: yup.string().required('กรุณาเลือก อำเภอ/เขต'),
  email: yup.string().email('รูปแบบอีเมล์ไม่ถูกต้อง').nullable(),
  citizenId: yup.string().max(13, 'กรุณากรอกไม่เกิน 13 ตัวเลข').nullable(),
  password: yup
    .string()
    .min(5, 'กรุณากรอกรหัสผ่านที่มีมีความยาวตั้งแต่ 5 ถึง 32')
    .max(32, 'กรุณากรอกรหัสผ่านที่มีมีความยาวตั้งแต่ 5 ถึง 32 '),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง'),
})

function UserProfileForm({
  onOpenModal,
  setErrorModalData,
  data,
  uId,
}: UserProfileFormProps) {
  const { isMobile } = useResponsive()
  const uploadRef = useRef(null)
  const [uploadFile, setUploadFile] = useState<boolean>(false)
  const [imageStateError, setImageStateError] = useState<boolean>(false)

  const [mutationResult, executeMutation] = useMutation(
    UserMutate.updateUserPatient,
  )

  const initialValues: ProfileType.CreatePatientUserForm = {
    image: data.imagePath,
    prefix: data.prefix,
    firstname: data.firstname,
    lastname: data.lastname,
    gender: data.UserMedicalDemograhicData.gender,
    birthdate: data.UserMedicalDemograhicData.birthdate,
    district: data.UserMedicalDemograhicData.district,
    province: data.UserMedicalDemograhicData.province,
    email: data.UserAuth.email,
    citizenId: data.UserAuth.identifyId,
    phoneNumber: data.UserAuth.phoneNumber,
    password: '',
  }

  const handleImage = async (
    event: React.ChangeEvent<HTMLInputElement>,
    formData,
  ) => {
    const { files } = event.target

    if (files) {
      setImageStateError(false)
      setUploadFile(true)
      Resizer.imageFileResizer(
        files[0],
        200,
        200,
        'JPEG',
        100,
        0,
        (url) => formData.setFieldValue('image', url),
        'base64',
      )
    }
  }

  const handleErrorMessage = (
    text: string,
  ): { title: string; description: string } => {
    let result = {
      title: 'บันทึกข้อมูลไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
    }

    if (text === 'this user already exist in the system')
      return {
        title: 'มีผู้ใช้งานนี้ในระบบ',
        description: 'กรุณากรอกเบอร์โทรศัพท์อื่นที่ไม่ซ้ำกับเบอร์นี้',
      }

    return result
  }

  const createUser = async (values: ProfileType.CreatePatientUserForm) => {
    let submitData = {
      imagePath: {
        set: values.image.split(',')[1],
      },
      prefix: {
        set: values.prefix,
      },
      firstname: {
        set: values.firstname,
      },
      lastname: {
        set: values.lastname,
      },
      UserMedicalDemograhicData: {
        update: {
          data: {
            birthdate: {
              set: dayjs(values.birthdate).toDate(),
            },
            gender: {
              set: values.gender,
            },
            district: {
              set: values.district,
            },
            province: {
              set: values.province,
            },
          },
        },
      },
      UserAuth: {
        update: {
          email: {
            set: values.email,
          },
          identifyId: {
            set: values.citizenId,
          },
          phoneNumber: {
            set: values.phoneNumber,
          },
          password: {
            set: values.password,
          },
        },
      },
    }

    if (!uploadFile) delete submitData.imagePath
    if (values.password === '') delete submitData.UserAuth.update.password

    await executeMutation({
      data: submitData,
      where: {
        uId: uId,
      },
    }).then((res) => {
      if (res.error) {
        const error: any = res.error

        setErrorModalData({
          ...handleErrorMessage(error?.graphQLErrors[0]?.message),
          open: true,
        })
        return
      }

      onOpenModal()
    })
  }

  const imageOnError = () => {
    setImageStateError(true)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationProfile}
      onSubmit={createUser}
    >
      {(formik) => (
        <Form>
          <div className="flex justify-center mt-[40px] mb-[45px]">
            <div className="relative flex justify-center items-center w-[150px] h-[150px] bg-white-dark border border-gray-light rounded-full">
              {!imageStateError && formik.values.image !== '' ? (
                <ElementComponent.Image
                  fullImage={false}
                  className="w-[150px] h-[150px] bg-center bg-cover rounded-full"
                  src={formik.values.image}
                  onError={imageOnError}
                />
              ) : (
                <AvatarProfile className="w-[150px] h-[150px] bg-center bg-cover rounded-full" />
              )}

              <input
                className="hidden"
                ref={uploadRef}
                type="file"
                accept="image/*"
                onChange={(event) => handleImage(event, formik)}
              />

              <div className="absolute right-[10px] bottom-0 bg-blue-main rounded-full w-[28px] h-[28px] flex justify-center items-center cursor-pointer">
                <Icon
                  icon="material-symbols:edit"
                  className="text-white-main"
                  onClick={() => uploadRef.current.click()}
                />
              </div>
            </div>
          </div>

          <div className="laptop:max-w-[470px] tablet:max-w-[630px] m-auto">
            <FormComponent.ProfileForm.PersonForm
              form={formik}
              isLoading={mutationResult.fetching}
              prefix_option={RegisterType.PrefixOption.PATIENT}
              require_email
              require_citizen_id
            />
          </div>

          <hr className="border-t-[1px] border-dashed border-gray-dark mt-[30px] mb-[42px]" />

          <div className="laptop:max-w-[470px] tablet:max-w-[630px] m-auto mb-5">
            <FormComponent.ProfileForm.AddressForm
              form={formik}
              isLoading={mutationResult.fetching}
            />
          </div>

          <hr className="border-t-[1px] border-dashed border-gray-dark mt-[30px] mb-[42px]" />

          <div className="laptop:max-w-[470px] tablet:max-w-[630px] m-auto mb-5">
            <FormComponent.ProfileForm.AuthForm
              form={formik}
              isLoading={mutationResult.fetching}
              require_phone
            />
          </div>

          <div className="flex justify-end pt-10">
            <ElementComponent.Button
              id="submit-button"
              submit
              text="ยืนยันส่งข้อมูล"
              width={isMobile ? '100%' : '260px'}
              height="52px"
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default function EditPatientUserPage() {
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
  const { uId } = useParams()
  const [userData] = useQuery({
    ...UserQuery.getUserPatientUpdate(uId),
  })

  const [errorModalData, setErrorModalData] = useState({
    title: 'บันทึกข้อมูลไม่สำเร็จ',
    description: 'กรุณาทดลองใหม่อีกครั้ง',
    open: false,
  })

  const onCloseErrorModal = () => {
    setErrorModalData({
      title: 'บันทึกข้อมูลไม่สำเร็จ',
      description: 'กรุณาทดลองใหม่อีกครั้ง',
      open: false,
    })
  }

  const onCloseSuccessModal = () => setOpenSuccessModal(false)

  const menuList = [
    { title: 'จัดการข้อมูลผู้ใช้งาน', link: route.USER_MANAGEMENT_PAGE_URL },
    { title: 'แก้ไขข้อมูลคนไข้', link: '' },
  ]

  return (
    <div className="pt-10 px-14">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      <div className="my-[25px] flex items-center justify-between">
        <div className="flex items-center gap-[10px]">
          <Icon icon="ic:baseline-person-add" className="w-[35px] h-[35px]" />
          <p className="text-[24px]">{`แก้ไขข้อมูลคนไข้`}</p>
        </div>
      </div>

      <div
        className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px] laptop:p-[60px] text-[16px]"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex justify-center mt-[40px] mb-[45px]">
          {!userData.fetching ? (
            <UserProfileForm
              onOpenModal={() => setOpenSuccessModal(true)}
              setErrorModalData={setErrorModalData}
              data={userData.data.getUser}
              uId={uId}
            />
          ) : (
            <div className="flex flex-col items-center gap-6">
              <Skeleton variant="circular" width={150} height={150} />
              <Skeleton variant="rounded" className="w-main" height={50} />
              <Skeleton variant="rounded" className="w-main" height={50} />
              <Skeleton variant="rounded" className="w-main" height={50} />
              <Skeleton variant="rounded" className="w-main" height={50} />
            </div>
          )}
        </div>
      </div>

      <AlertComponent.SuccessModal
        title="บันทึกข้อมูลเรียบร้อย"
        open={openSuccessModal}
        onClose={onCloseSuccessModal}
      />

      <AlertComponent.ErrorModal
        title={errorModalData.title}
        description={errorModalData.description}
        open={errorModalData.open}
        onClose={onCloseErrorModal}
      />
    </div>
  )
}
