import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { Icon } from '@iconify/react'

import { ElementComponent } from 'components'
import { useResponsive } from 'hooks'

interface GeneralDieaseFormProp {
  loading: boolean
  formData: any
  diseaseList: any
}

interface ImageFormProps {
  loading: boolean
  formData: any
  diseaseAttibute: any
}

function ImageForm({ loading, formData, diseaseAttibute }: ImageFormProps) {
  const [image, setImage] = useState([])

  const removeImage = (index: number) => {
    let newObject = [...image]
    newObject.splice(index, 1)

    setImage(newObject)
    formData.setFieldValue(diseaseAttibute?.medName, {
      ...formData.values[diseaseAttibute?.medName],
      value: newObject,
    })
  }

  return (
    <div className="mt-[30px]">
      <p className="font-bold">แนบรูปภาพเพิ่มเติม</p>
      <hr className="mt-[15px] mb-[38px]" />

      <div className="flex gap-[15px] flex-wrap">
        {image !== null &&
          image?.map((item: File, index: number) => (
            <div
              key={`image-upload-${index}`}
              className="w-full h-[200px] laptop:w-[170px] laptop:h-[170px] relative"
            >
              <div
                onClick={() => removeImage(index)}
                className="absolute top-0 right-0 p-[5px] border-indigo-900 bg-blue-main hover:brightness-75 cursor-pointer rounded-tr-[10px]"
              >
                <Icon
                  icon="mdi:bin"
                  className="h-[25px] w-[25px] text-white-main"
                />
              </div>
              <ElementComponent.Image
                className="h-full w-full object-cover bg-center rounded-[10px] border"
                src={URL.createObjectURL(item)}
                alt=""
              />
            </div>
          ))}

        {image.length < 3 && (
          <div
            className="bg-white-main w-full h-[200px] laptop:w-[170px] laptop:h-[170px]"
            style={{
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <ElementComponent.ImageButton
              isLoading={loading}
              id="image-upload-general-button"
              width="100%"
              height="100%"
              text={
                Array.isArray(image)
                  ? image.length >= 1
                    ? `${3 - image.length}/3`
                    : undefined
                  : undefined
              }
              onChange={(event) => {
                if (event.target?.files && event.target?.files?.length !== 0) {
                  setImage([...image, event.target.files[0]])
                  formData.setFieldValue(diseaseAttibute?.medName, {
                    ...formData.values[diseaseAttibute?.medName],
                    value: [
                      ...formData.values[diseaseAttibute?.medName].value,
                      event.target.files[0],
                    ],
                  })
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function GeneralHealthForm({
  loading,
  formData,
  diseaseList,
}: GeneralDieaseFormProp) {
  const { isMobile } = useResponsive()

  return (
    <div className="flex flex-col gap-[22px] pt-[10px]">
      {diseaseList?.map((disease, index: number) => {
        if (disease?.diseaseAttibute?.length === 1) {
          let diseaseAttibute = { ...disease?.diseaseAttibute[0] }

          if (diseaseAttibute.type === 'TEXT') {
            return (
              <ElementComponent.TextArea
                key={`disease-${diseaseAttibute?.medName}-${index}`}
                isLoading={loading}
                name={diseaseAttibute?.medName}
                label={diseaseAttibute?.thaiName}
                placeholder={diseaseAttibute?.thaiName}
                minlines={6}
                value={formData.values[diseaseAttibute?.medName]?.value}
                onChange={(value: string) => {
                  formData.setFieldValue(diseaseAttibute?.medName, {
                    ...formData.values[diseaseAttibute?.medName],
                    value: value,
                  })
                }}
              />
            )
          } else if (diseaseAttibute.type === 'FLOAT') {
            return (
              <ElementComponent.InputBar
                id={`disease-${diseaseAttibute?.medName}-${index}`}
                key={`disease-${diseaseAttibute?.medName}-${index}`}
                isLoading={loading}
                focusSpacing={index !== 0}
                name={diseaseAttibute?.medName}
                label={diseaseAttibute?.thaiName}
                placeholder={diseaseAttibute?.thaiName}
                height="50px"
                backIcon={
                  <span className="text-[16px]">
                    {diseaseAttibute?.unitThai}
                  </span>
                }
                value={formData.values[diseaseAttibute?.medName]?.value}
                errorText={formData.errors[diseaseAttibute?.medName]?.value}
                error={
                  formData.touched[diseaseAttibute?.medName] &&
                  Boolean(formData.errors[diseaseAttibute?.medName])
                }
                onChange={(value: string) =>
                  formData.setFieldValue(diseaseAttibute?.medName, {
                    ...formData.values[diseaseAttibute?.medName],
                    value: value,
                  })
                }
              />
            )
          } else if (diseaseAttibute.type === 'IMAGE') {
            return (
              <ImageForm
                key={`disease-${diseaseAttibute?.medName}-${index}`}
                loading={loading}
                formData={formData}
                diseaseAttibute={diseaseAttibute}
              />
            )
          }
        } else if (disease?.diseaseAttibute?.length > 1) {
          let diseaseAttibute = [...disease.diseaseAttibute]
          return (
            <Grid
              container
              spacing={1}
              alignItems="start"
              rowGap="20px"
              sx={{ width: '100%', marginTop: isMobile ? '0px' : '5px' }}
              key={`disease-${disease?.medName}-${index}`}
              columns={{ mobile: 1, laptop: diseaseAttibute.length }}
            >
              {diseaseAttibute.map((attribute, index: number) => (
                <Grid
                  item
                  mobile={1}
                  key={`disease-${attribute?.medName}-${index}`}
                >
                  <ElementComponent.InputBar
                    id={`disease-${attribute?.medName}-${index}`}
                    isLoading={loading}
                    focusSpacing={isMobile}
                    backIcon={
                      <span className="text-[16px]">{attribute?.unitThai}</span>
                    }
                    name={attribute?.medName}
                    label={attribute?.thaiName}
                    placeholder={attribute?.thaiName}
                    height="50px"
                    value={formData.values[attribute?.medName]?.value}
                    errorText={formData.errors[attribute?.medName]?.value}
                    error={
                      formData.touched[attribute?.medName] &&
                      Boolean(formData.errors[attribute?.medName])
                    }
                    onChange={(value: string) =>
                      formData.setFieldValue(attribute?.medName, {
                        ...formData.values[attribute?.medName],
                        value: value,
                      })
                    }
                  />
                </Grid>
              ))}
            </Grid>
          )
        }
      })}
    </div>
  )
}

export default GeneralHealthForm
