import React, { useState } from 'react'
import { Skeleton } from '@mui/material'
import { Field } from 'formik'

import { ElementComponent } from 'components'

interface TextAreaProps {
  isLoading?: boolean
  value: string
  name: string
  focusSpacing?: boolean
  width?: string
  height?: string
  disable?: boolean
  disable_color?: string
  minlines?: number
  label?: string
  placeholder?: string
  error?: boolean
  errorText?: string
  onChange: Function
}

function TextArea({
  isLoading = false,
  value,
  name,
  focusSpacing = true,
  width = '100%',
  height = 'auto',
  disable = false,
  disable_color = 'rgba(0, 0, 0, 0.5)',
  minlines = 50,
  placeholder,
  label,
  error = false,
  errorText,
  onChange,
}: TextAreaProps) {
  const [cliked, setCliked] = useState(false)

  const onHandleClick = (e, defaultOnClick) => {
    setCliked(true)

    if (defaultOnClick) defaultOnClick(e)
  }

  const onHandleBlur = (e, defaultOnblur) => {
    setCliked(false)

    if (defaultOnblur) defaultOnblur(e)
  }

  return !isLoading ? (
    <div
      className={`${
        focusSpacing && (cliked || value || disable)
          ? 'mt-[20px] transition-all ease-in-out'
          : ''
      }`}
    >
      <Field id={name} name={name}>
        {({ field }) => (
          <>
            <div className="relative w-fit">
              <p
                className={`text-black-main text-[16px] w-auto break-normal whitespace-nowrap text-left pl-1 font-bold ${
                  cliked || value || disable
                    ? 'absolute bottom-[0.25px] duration-300 delay-100 transition-all ease-in-out'
                    : 'absolute top-0.25 p-2 transition-all ease-in-out'
                }`}
              >
                {label}
              </p>
            </div>
            <ElementComponent.TextFieldStyle
              {...field}
              multiline
              fullWidth
              disabled={disable}
              disable_color={disable_color}
              value={value}
              placeholder={placeholder}
              aria-label={placeholder}
              minRows={minlines}
              width={width}
              height={height}
              error={error}
              onClick={(e) => onHandleClick(e, field.onClick)}
              onBlur={(e) => onHandleBlur(e, field.onBlur)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChange(event.target.value)
              }
            />
            {error && (
              <p
                id={`error-${name}`}
                className="text-[14px] text-red-main mt-[6px] text-start"
              >
                {errorText}
              </p>
            )}
          </>
        )}
      </Field>
    </div>
  ) : (
    <div>
      <Skeleton variant="rounded" width={width} height={`${25 * minlines}px`} />
    </div>
  )
}

export default TextArea
