const login = `
  mutation loginDoctor ($data: UsersLoginDoctorInput!) {
    loginDoctor (data: $data) {
      refreshToken
      accessToken
    }
  }
`

const logout = `
  mutation logout ($data: UserLogoutInput!) {
    logout (data: $data)
  }
`

const refreshToken = `
  mutation refreshToken ($data: UserRefreshTokenInput!) {
    refreshToken (data: $data) {
        refreshToken
        accessToken
    }
  }
`

export { login, logout, refreshToken }
