import React from 'react'
import * as Pics from 'assets/picture'
import { ElementComponent } from 'components'
import { Stack } from '@mui/material'
export default class DrugOnePrint extends React.PureComponent<{
  diagnoseData: any
  patientData: any
}> {
  render() {
    const mapValue = {
      ยากินเม็ด: {
        value: 'เม็ด',
      },
      ยาพ่น: {
        value: 'ขวด',
      },
      ยาผงละลาย: {
        value: 'ซอง',
      },
      ยาน้ำ: {
        value: 'ขวด',
      },
      ครีมภายนอก: {
        value: 'หลอด',
      },
      ยาหยอดตา: {
        value: 'ขวด',
      },
      ยาฉีด: {
        value: 'เข็ม',
      },
    }
    return (
      <Stack direction={{ laptop: 'column' }}>
        {JSON.parse(this.props.diagnoseData.drugData).map(
          (item: any) =>
            item.invoiceType === 'ค่ายา' && (
              <div className="text-black-main ">
                <ElementComponent.Image
                  fullImage={false}
                  src={Pics.PrintOneDrugHeader}
                  className="w-[80%]"
                />
                <table className="w-[100%] text-[14px]">
                  <tr>
                    <td align="left">
                      {JSON.stringify(this.props.patientData) !== '{}' && (
                        <>
                          {'HN '}
                          {`${Array(
                            7 - this.props.patientData.hnId.toString().length,
                          )
                            .fill(0)
                            .join('')}${this.props.patientData.hnId}`}{' '}
                          {this.props.patientData.prefix}{' '}
                          {this.props.patientData.firstname}{' '}
                          {this.props.patientData.lastname}
                        </>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td align="left">
                      <b>
                        {item.dName} ({item.tradeName}) {item.quantity}{' '}
                        {item.unit}{' '}
                        <b className="ml-[1%]">
                          {item.qty}{' '}
                          {/*item.dUseType === 'ยาฉีด' ? 'เข็ม' : 'เม็ด'*/}
                          {mapValue[item.dUseType].value}
                        </b>
                      </b>
                    </td>
                    <td align="right"></td>
                  </tr>
                  <tr>
                    <td align="left">
                      วิธีใช้ : {item.dutName}{' '}
                      {item.dUseType === 'ยาฉีด' &&
                        item.injQtyVal +
                          ' ' +
                          item.injUnit +
                          ' ' +
                          item.injChooseTypeVal}
                    </td>
                    <td align="right"></td>
                  </tr>
                  <tr>
                    <td align="left">สรรพคุณ : {item.description}</td>
                    <td align="right"></td>
                  </tr>
                </table>
                <div style={{ pageBreakAfter: 'always' }}></div>
              </div>
            ),
        )}
      </Stack>
    )
  }
}
