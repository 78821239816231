import React from 'react'
import * as Pics from 'assets/picture'
import { ElementComponent } from 'components'
import dayjs from 'dayjs'
import { date } from 'utils'
export default class InvoicePrint extends React.PureComponent<{
  diagnoseData: any
  patientData: any
  runNumber: number
  income: string
}> {
  render() {
    let row = 1
    let drugCost = 0
    let hutCost = 0
    let roomCost = 0
    let cerCost = 0
    let insulanceCost = 0
    {
      JSON.parse(this.props.diagnoseData.drugData).map((item: any) => {
        if (item.invoiceType === 'ค่ายา') {
          drugCost += item.qty * item.income
        }
        if (item.invoiceType === 'ค่าหัตถการ') {
          hutCost += item.qty * item.income
        }
        if (item.invoiceType === 'ค่าทางห้องปฏิบัติการ') {
          roomCost += item.qty * item.income
        }

        if (item.invoiceType === 'ค่าวิชาชีพแพทย์') {
          cerCost += item.qty * item.income
        }

        if (item.invoiceType === 'ค่าเวชภัณฑ์') {
          insulanceCost += item.qty * item.income
        }
      })
    }
    return (
      <>
        <div className="text-black-main">
          <ElementComponent.Image
            fullImage={false}
            src={Pics.PrintHeaderInvoice}
          />
          <div className="p-[24px]">
            <p className="text-black-main text-[20px] text-center">
              ใบเสร็จรับเงิน
            </p>
            <p className="text-black-main text-[18px]">
              เลขที่ :{dayjs(new Date()).format('DDMMYY')}/
              {`${Array(2 - this.props.runNumber.toString().length)
                .fill(0)
                .join('')}${this.props.runNumber + 1}`}
            </p>
            <p className="text-black-main text-[18px] mt-4">
              วันที่ {dayjs(new Date()).format('DD MMMM BBBB')}
            </p>
            {JSON.stringify(this.props.patientData) !== '{}' && (
              <p className="text-black-main text-[16px] mt-4">
                <div>
                  <p>
                    <b>ชื่อผู้รับบริการ</b> {this.props.patientData.firstname}{' '}
                    {this.props.patientData.lastname} <b>เพศ</b>{' '}
                    {' ' +
                      this.props.patientData.UserMedicalDemograhicData
                        .gender}{' '}
                    <b>อายุ</b>{' '}
                    {date.getAge(
                      this.props.patientData.UserMedicalDemograhicData
                        ?.birthdate,
                    )}{' '}
                    <b>ปี</b> <br /> <b>HN</b>
                    {' : ' +
                      `${Array(
                        7 - this.props.patientData.hnId.toString().length,
                      )
                        .fill(0)
                        .join('')}${this.props.patientData.hnId}`}
                  </p>
                </div>
              </p>
            )}
            <p className="text-black-main text-[16px] mt-4">
              <div>
                <p>
                  ที่อยู่ :{' '}
                  {JSON.stringify(this.props.patientData) !== '{}' && (
                    <>
                      {
                        this.props.patientData.UserMedicalDemograhicData
                          .subdistrict
                      }{' '}
                      {
                        this.props.patientData.UserMedicalDemograhicData
                          .district
                      }{' '}
                      {
                        this.props.patientData.UserMedicalDemograhicData
                          .province
                      }{' '}
                      {
                        this.props.patientData.UserMedicalDemograhicData
                          .postcode
                      }
                    </>
                  )}
                </p>
              </div>
            </p>
            <table className="w-[100%] mt-4">
              <thead>
                <th>ลำดับ</th>
                <th>รายการ</th>
                <th>จำนวนเงิน</th>
              </thead>
              <tbody>
                <tr className="mt-[18px] h-[40px]">
                  <td align="center">{row++}</td>
                  <td align="left">ค่าวิชาชีพแพทย์</td>
                  <td align="center">{cerCost.toFixed(2)}</td>
                </tr>
                <tr className="mt-[18px] h-[40px]">
                  <td align="center">{row++}</td>
                  <td align="left">ค่ายา</td>
                  <td align="center">{drugCost.toFixed(2)}</td>
                </tr>
                <tr className="mt-[18px] h-[40px]">
                  <td align="center">{row++}</td>
                  <td align="left">ค่าตรวจทางห้องปฎิบัติการ</td>
                  <td align="center">{roomCost.toFixed(2)}</td>
                </tr>
                <tr className="mt-[18px] h-[40px]">
                  <td align="center">{row++}</td>
                  <td align="left">ค่าหัตถการ</td>
                  <td align="center">{hutCost.toFixed(2)}</td>
                </tr>
                <tr className="mt-[18px] h-[40px]">
                  <td align="center">{row++}</td>
                  <td align="left">ค่าเวชภัณฑ์</td>
                  <td align="center">{insulanceCost.toFixed(2)}</td>
                </tr>
                <tr className="mt-[18px] h-[40px]">
                  <td align="center"></td>
                  <td align="right">
                    <b>
                      ยอดรวมสุทธิ <br /> Net amount
                    </b>
                  </td>
                  <td align="center">
                    {(
                      cerCost +
                      roomCost +
                      drugCost +
                      hutCost +
                      insulanceCost
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ElementComponent.Image fullImage={false} src={Pics.PrintSign} />
        </div>
      </>
    )
  }
}
