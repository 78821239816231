import React from 'react'
import { Skeleton } from '@mui/material'

export default function ChartSkeleton() {
  return (
    <div>
      <div className="m-[20px]">
        <Skeleton variant="rounded" height="30px" width="20%" />
        <hr className="mt-[10px]" />
      </div>
      <div className="mx-[20px] mb-[20px]">
        <Skeleton variant="rectangular" height="300px" />
      </div>
    </div>
  )
}
