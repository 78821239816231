import { ElementComponent } from 'components'
import { EditPatientUserInModal } from 'pages/user-management'

interface EditUserProfileModalProps {
  open: boolean
  onClose: Function
  puId: string
}
function EditUserProfileModal({
  open,
  onClose,
  puId,
}: EditUserProfileModalProps) {
  const handleClose = () => {
    onClose()
  }
  return (
    <ElementComponent.ModalForm open={open} onClose={() => handleClose()}>
      <EditPatientUserInModal puId={puId} onClose={() => handleClose()} />
    </ElementComponent.ModalForm>
  )
}

export default EditUserProfileModal
