import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import buddhistEra from 'dayjs/plugin/buddhistEra'

import 'dayjs/locale/th' // import locale
import 'dayjs/locale/en' // import locale
dayjs.extend(localizedFormat) // use plugin
dayjs.extend(buddhistEra)
dayjs.locale('th')

const convertStringToDate = (date_string: string): Date => {
  return dayjs(date_string).toDate()
}

const convertToShowDate = (date: Date): string => {
  return dayjs(date).format('DD/MM/BBBB')
}

const convertToShowDateThai = (date: Date): string => {
  return dayjs(date).format('DD MMMM BBBB')
}

const convertToShowDateCommonEra = (date: Date): string => {
  return dayjs(date).format('DD/MM/BBBB')
}

const getDateCommonEra = (): Date => {
  return dayjs().add(543, 'year').toDate()
}

const convertToShowDateForGraph = (date: Date): string => {
  return dayjs(date).format('DD/MM/BBBB HH:mm:ss')
}

const getAge = (date: Date): number => {
  return dayjs().diff(dayjs(date), 'year')
}

const receviceThaiDate = (date_string: string): string => {
  return dayjs(date_string).format('DD/MM/YYYY')
}

const months = [
  { key: 0, value: 'มกราคม' },
  { key: 1, value: 'กุมภาพันธ์' },
  { key: 2, value: 'มีนาคม' },
  { key: 3, value: 'เมษายน' },
  { key: 4, value: 'พฤษภาคม' },
  { key: 5, value: 'มิถุนายน' },
  { key: 6, value: 'กรกฏาคม' },
  { key: 7, value: 'สิงหาคม' },
  { key: 8, value: 'กันยายน' },
  { key: 9, value: 'ตุลาคม' },
  { key: 10, value: 'พฤศจิกายน' },
  { key: 11, value: 'ธันวาคม' },
]

export {
  convertToShowDate,
  convertToShowDateCommonEra,
  getDateCommonEra,
  convertToShowDateForGraph,
  convertStringToDate,
  receviceThaiDate,
  getAge,
  convertToShowDateThai,
  months,
}
