import { Icon } from '@iconify/react'
import { ElementComponent, MenuComponent, SkeletonComponent } from 'components'
import { Form, Formik } from 'formik'
import { Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MedicalQuery } from 'services/graphql/medicalService'
import { route } from 'settings'
import { useQuery } from 'urql'
import { styled } from '@mui/material/styles'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
const menuList = [
  { title: 'จัดการข้อมูลโรค', link: '' },
  { title: 'รายชื่อข้อมูลโรคทั้งหมด', link: '' },
]
const topicTable = ['ชื่อโรค(ภาษาไทย)', 'ชื่อโรค(ภาษาอังกฤษ)', 'จัดการ']
function DiseaseManagementPage() {
  const navigate = useNavigate()
  const [nameKeyCode, setNameKeyCode] = useState<string>('')
  const [underlyingData] = useQuery(
    MedicalQuery.getUnderlyingDisease(nameKeyCode),
  )
  const onTextConsultChange = (text) => {
    //if (text.length > 2) {
    setNameKeyCode(text)
    // }
  }
  const option = (udId: string, disable = true) => {
    const StyleToolTip = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#C9E2F4',
        color: '#1475BB',
        fontSize: 12,
      },
    })

    return (
      <div className="flex justify-center">
        <StyleToolTip title={`แก้ไขข้อมูลโรค`} color="info">
          <Icon
            icon="mdi:pencil"
            className={`w-[28px] h-[28px] ${
              disable
                ? 'cursor-not-allowed text-gray-main'
                : 'cursor-pointer hover:text-blue-main'
            }`}
            onClick={() => {
              navigate(route.DISEASE_MANAGEMENT_URL + '/' + udId)
            }}
          />
        </StyleToolTip>
        <StyleToolTip title={`ลบข้อมูลโรค`} color="info">
          <Icon
            icon="mdi:bin"
            className={`w-[28px] h-[28px] ${
              disable
                ? 'cursor-not-allowed text-gray-main'
                : 'cursor-pointer text-red-main hover:text-blue-main'
            }`}
          />
        </StyleToolTip>
      </div>
    )
  }
  const formatData = (data) => {
    //alert(JSON.stringify(data))
    return data.map((item) => {
      return {
        [topicTable[0]]: {
          data: item.thaiName,
          color: 'black',
        },
        [topicTable[1]]: {
          data: item.engName,
          color: 'black',
        },
        [topicTable[2]]: {
          data: option(item.udId, false),
          color: 'black',
        },
      }
    })
  }
  return (
    <div className="p-[80px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      <div
        className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px]  text-[16px] mt-4"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <div className="flex flex-row justify-between">
          <div className="mb-[24px]">
            <Formik
              initialValues={{ text: '' }}
              onSubmit={null}
              //onSubmit={() => handleSearchData()}
            >
              {(formik) => (
                <Form>
                  <div className="flex gap-[5px]">
                    <ElementComponent.InputBar
                      id="search-data-table"
                      name="text"
                      focusSpacing={false}
                      isLoading={false}
                      placeholder={'ค้นหาจากชื่อโรค'}
                      height="40px"
                      frontIcon={
                        <Icon
                          icon="material-symbols:search-rounded"
                          className="w-[20px] h-[20px]"
                        />
                      }
                      value={formik.values.text}
                      onChange={(text: string) => {
                        formik.setFieldValue('text', text)
                        onTextConsultChange(text)
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="mb-[24px]">
            <ElementComponent.Button
              id="submit-button"
              onAction={() => navigate(route.DISEASE_ADD_PAGE_URL)}
              icon="material-symbols:add"
              text={'เพิ่มโรค'}
              width="200px"
              height="40px"
            />
          </div>
        </div>

        {!underlyingData?.fetching && !underlyingData.error ? (
          <div>
            <ElementComponent.Table
              col={topicTable}
              row={formatData(underlyingData.data.getUnderlyingDiseases || [])}
            />
            {underlyingData.data.getUnderlyingDiseases === 0 && (
              <div className="my-[80px]">
                <p className="text-center text-[18px] text-black-lighter">
                  ไม่มีข้อมูลโรค
                </p>
              </div>
            )}
          </div>
        ) : (
          <SkeletonComponent.TableSkeleton columnsAmount={3} />
        )}

        {/*pageAmount !== 0 && (
            <div className="mt-[30px] flex justify-center">
              <Pagination
                page={page}
                count={pageAmount}
                variant="outlined"
                shape="rounded"
                onChange={(_, newPage: number) => setPage(newPage)}
              />
            </div>
          )*/}
      </div>
    </div>
  )
}

export default DiseaseManagementPage
