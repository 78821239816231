import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'

import { ElementComponent } from 'components'
import { RegisterType } from 'types'
import { endpoints } from 'settings'

export default function AddressForm({
  form,
  isLoading,
}: RegisterType.FormProps) {
  const [provinceId, setProvinceId] = useState<number>(0)
  const [districtId, setDistrictId] = useState<number>(0)
  const [province, setProvince] = useState<RegisterType.ProvinceListType[]>([
    { id: 0, name: '' },
  ])
  const [district, setDistrict] = useState<RegisterType.DistrictType[]>([
    { id: 0, name: '' },
  ])
  const [subdistrict, setSubDistrict] = useState<
    RegisterType.SubDistrictType[]
  >([{ name: '' }])

  const provinceOptions = useMemo(() => {
    if (province.length > 1)
      return [
        { key: 'จังหวัด', value: 'จังหวัด' },
        ...province.map((value) => ({ key: value.name, value: value.name })),
      ]
    return [{ key: 'จังหวัด', value: 'จังหวัด' }]
  }, [province])

  const districtOptions = useMemo(() => {
    if (district.length > 1)
      return [
        { key: 'อำเภอ/เขต', value: 'อำเภอ/เขต' },
        ...district.map((value) => ({ key: value.name, value: value.name })),
      ]
    return [{ key: 'อำเภอ/เขต', value: 'อำเภอ/เขต' }]
  }, [district])

  const subdistrictOptions = useMemo(() => {
    if (subdistrict.length > 1)
      return [
        { key: 'ตำบล/แขวง', value: 'ตำบล/แขวง' },
        ...subdistrict.map((value) => ({ key: value.name, value: value.name })),
      ]
    return [{ key: 'ตำบล/แขวง', value: 'ตำบล/แขวง' }]
  }, [subdistrict])

  useEffect(() => {
    ;(async () => {
      const response = await axios.get(endpoints.SUBDISTRICT_DATA_URL) //subdistrict
      const resData: { amphure_id: number; name_th: string; id: number }[] =
        await response.data
      setSubDistrict(
        resData
          .filter((item) => item.amphure_id === districtId)
          .map((item) => ({ name: item.name_th })),
      )
    })()
  }, [districtId])

  useEffect(() => {
    ;(async () => {
      const response = await axios.get(endpoints.PROVINCE_DATA_URL) //district
      const resData: { province_id: number; name_th: string; id: number }[] =
        await response.data
      setDistrict(
        resData
          .filter((item) => item.province_id === provinceId)
          .map((item) => ({ id: item.id, name: item.name_th })),
      )
    })()
  }, [provinceId])

  useEffect(() => {
    ;(async () => {
      const response = await axios.get(endpoints.DISTRICT_DATA_URL) //province
      const resData = await response.data
      setProvince(resData.map((item) => ({ id: item.id, name: item.name_th })))

      if (form.values.province) {
        setProvinceId(
          resData.filter((item) => item.name_th === form.values.province)[0]
            ?.id,
        )
      }
    })()
  }, [])

  const handleProvinceChange = async (data: {
    label: string
    value: string
  }) => {
    if (data) {
      await form.setFieldValue('province', data.value)
      await form.setFieldValue('district', '')
      await form.setFieldValue('subdistrict', '')
      setProvinceId(
        province.filter((item) => item.name === data.value)[0]?.id ?? 0,
      )
    }
  }

  const handleDistrictChange = async (data: {
    label: string
    value: string
  }) => {
    form.setFieldValue('district', data.value)
    await form.setFieldValue('subdistrict', '')
    setDistrictId(
      district.filter((item) => item.name === data.value)[0]?.id ?? 0,
    )
  }

  const handleSubDistrictChange = (data: { label: string; value: string }) => {
    form.setFieldValue('subdistrict', data.value)
  }

  return (
    <div>
      <p className="text-[18px] font-bold mb-[30px]">ข้อมูลที่อยู่</p>
      <div className="flex flex-col gap-main">
        <ElementComponent.SearchBar.SearchBar
          name="province"
          label="จังหวัด"
          isLoading={isLoading}
          isSubmitting={form.isSubmitting}
          data={form.values.province}
          error={form.touched.province && Boolean(form.errors.province)}
          errorText={form.errors.province}
          option={provinceOptions}
          onSelect={handleProvinceChange}
        />

        <ElementComponent.SearchBar.SearchBar
          name="district"
          label="อำเภอ/เขต"
          isLoading={isLoading}
          isSubmitting={form.isSubmitting}
          data={form.values.district}
          error={form.touched.district && Boolean(form.errors.district)}
          errorText={form.errors.district}
          option={districtOptions}
          disabled={Boolean(form.values.province === '')}
          onSelect={handleDistrictChange}
        />

        <ElementComponent.SearchBar.SearchBar
          name="subdistrict"
          label="ตำบล/แขวง"
          isLoading={isLoading}
          isSubmitting={form.isSubmitting}
          data={form.values.subdistrict}
          error={form.touched.subdistrict && Boolean(form.errors.subdistrict)}
          errorText={form.errors.subdistrict}
          option={subdistrictOptions}
          disabled={Boolean(form.values.district === '')}
          onSelect={handleSubDistrictChange}
        />

        <ElementComponent.InputBar
          id="postcode"
          label="รหัสไปรษณีย์"
          placeholder="รหัสไปรษณีย์"
          name="postcode"
          width="100%"
          height="50px"
          value={form.values.postcode}
          errorText={form.errors.postcode}
          error={form.touched.postcode && Boolean(form.errors.postcode)}
          onChange={(text: string) => form.setFieldValue('postcode', text)}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}
