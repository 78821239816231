import { Stack, TextField } from '@mui/material'
import { AlertComponent, ElementComponent, MenuComponent } from 'components'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MedicalMutate, MedicalQuery } from 'services/graphql/medicalService'
import { DrugType } from 'types'
import { useMutation, useQuery } from 'urql'
import { general } from 'utils'

function DrugTypeAddPage() {
  const { dutId } = useParams()
  const [drugTypeApiOneData] = useQuery(
    MedicalQuery.getDrugTypeOneById(parseInt(dutId)),
  )
  const menuList = [
    { title: 'จัดการคลังยา', link: '' },
    { title: 'แก้ไขวิธีบริหารยา', link: '' },
  ]

  const [dutName, setDutName] = useState<string>('')

  useEffect(() => {
    if (drugTypeApiOneData.fetching || !drugTypeApiOneData.data) return
    //alert(JSON.stringify(drugTypeApiOneData.data))
    setDutName(drugTypeApiOneData.data.getDrugTypeOne.dutName)
  }, [drugTypeApiOneData.data])

  const initialValues: DrugType.DrugType = {
    dutName: dutName,
  }

  const [submitPopupData, setSubmitPopupData] = useState<{
    type: 'success' | 'error'
    open: boolean
    title: string
    description: string
    onClose: Function
  }>({
    type: 'success',
    open: false,
    title: '',
    description: '',
    onClose: () => {},
  })

  const [createMedicalMutation, executeMedicalMutation] = useMutation(
    MedicalMutate.updateDrugType,
  )

  const createDrugType = async (values: DrugType.DrugType) => {
    let payload = {
      data: {
        dutName: { set: values.dutName },
      },
      where: {
        dutId: parseInt(dutId),
      },
    }
    const result = await executeMedicalMutation(payload)

    if (result.error) {
      setSubmitPopupData({
        type: 'error',
        open: true,
        title: 'ส่งข้อมูลไม่สำเร็จ',
        description: result.error.toString(),
        onClose: () => {
          setSubmitPopupData({
            type: 'success',
            open: false,
            title: '',
            description: '',
            onClose: () => {},
          })
        },
      })
      return
    }
    setSubmitPopupData({
      type: 'success',
      open: true,
      title: 'ส่งข้อมูลสำเร็จ',
      description: '',
      onClose: () => {
        general.http.goto('/drug-management/type/list')
      },
    })

    return
  }

  return (
    <div className="p-[80px]">
      <MenuComponent.MenuHeaderBar menuList={menuList} />
      <div
        className="relative overflow-hidden rounded-[10px] bg-white-main p-[25px]  text-[16px] mt-4"
        style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <p className="text-[20px] text-blue-main">แก้ไขวิธีบริหารยา</p>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          //validationSchema={validationSchema}
          onSubmit={createDrugType}
        >
          <Form className="w-[90%]">
            <Stack
              direction="row"
              spacing="10px"
              justifyContent="space-between"
              className="text-[14px] mt-4"
            >
              <div className="w-[40%]">
                <p className="mt-2">ชื่อวิธีบริหารยา</p>
                <TextField
                  className="w-[100%]"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={dutName}
                  onChange={(event) => setDutName(event.target.value)}
                />
              </div>
            </Stack>
            <ElementComponent.Button
              submit
              id="submit-diagnose-button"
              width="w-main"
              height="32px"
              text="ยืนยันส่งข้อมูล"
              style="mt-4 "
              loading={createMedicalMutation.fetching}
              //onAction={() => alert(JSON.stringify(initialValues))}
            />
          </Form>
        </Formik>
        {submitPopupData.type === 'success' ? (
          <AlertComponent.SuccessModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        ) : (
          <AlertComponent.ErrorModal
            open={submitPopupData.open}
            title={submitPopupData.title}
            description={submitPopupData.description}
            onClose={submitPopupData.onClose}
          />
        )}
      </div>
    </div>
  )
}

export default DrugTypeAddPage
