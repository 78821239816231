export enum ProfileOption {
  PROFILE = 'PROFILE',
}

export type ResetPasswordDataType = {
  password: string
  newPassword: string
  confirmPassword: string
}

export type UserInfoType = {
  image: string
  prefix: string
  firstname: string
  lastname: string
  gender: string
  birthdate: Date | null
  district: string
  province: string
  email: string
  identifyId: string
  phoneNumber: string
}

export type CreateDoctorUserForm = {
  image: string
  prefix: string
  firstname: string
  lastname: string
  gender: string
  birthdate: Date | null
  district: string
  province: string
  email: string
  identifyId: string
  phoneNumber: string
  password: string
}

export type CreatePatientUserForm = {
  image: string
  prefix: string
  firstname: string
  lastname: string
  gender: string
  birthdate: Date | null
  district: string
  province: string
  email: string
  citizenId: string
  phoneNumber: string
  password: string
}

export type EditPatientUserForm = {
  image: string
  prefix: string
  firstname: string
  lastname: string
  gender: string
  birthdate: Date | null
  postcode: string
  subdistrict: string
  district: string
  province: string
  email: string
  citizenId: string
  phoneNumber: string
  password: string
  ercId: string
  docId: string
}
