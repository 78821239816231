import React from 'react'

import { ChartComponent } from 'components'

import { Grid } from '@mui/material'

interface IAssociateDiseaseCardProps {
  puId: string
  diseaseData: {
    imagePath: string
    medName: string
    thaiName: string
    associateDieaseAttibute: {
      adaId: string
      thaiName: string
      description: string
      unitThai: string
      showFlag: boolean
      type: string
      indexSelect: string
      showPrevoius: boolean
    }[]
  }[]
}

function AssociateDiseaseCard({
  diseaseData,
  puId,
}: IAssociateDiseaseCardProps) {
  return (
    <>
      {/* Disease Attribute for Associate Disease */}
      <Grid
        container
        justifyContent="center"
        columns={{ desktop: 2.2 }}
        gap="8px"
      >
        {diseaseData.map((associateDisease) => {
          return (
            <Grid desktop={1} justifyContent="center" className="mt-8">
              {/* Associate Disease attribute */}
              <div className=" bg-zinc-100 rounded-[20px] p-[16px] ">
                <div className="gap-[18px] w-[100%] laptop:w-[100%]">
                  <div className="flex justify-center flex-row -mt-9">
                    <img
                      src={associateDisease.imagePath}
                      className="w-12 h-12 -mr-3.5 z-10 bg-white-main border-transparent rounded-3xl"
                      alt={`icon-${associateDisease.medName}`}
                    />
                    <div className="h-8 z-0 p-1 mt-2 bg-blue-main border border-transparent rounded-xl">
                      <p className="text-white-main mx-3">
                        {associateDisease.thaiName}
                      </p>
                    </div>
                  </div>

                  {/* Associate Disease attribute */}
                  {associateDisease.associateDieaseAttibute.map(
                    (associateDieaseAttribute) => {
                      return (
                        <>
                          {associateDieaseAttribute.showFlag && (
                            <>
                              {associateDieaseAttribute.type === 'FLOAT' &&
                                !associateDieaseAttribute.showPrevoius && (
                                  <ChartComponent.CardLatestData
                                    dieaseData={associateDieaseAttribute}
                                    puId={puId}
                                  />
                                )}
                              {associateDieaseAttribute.type === 'FLOAT' &&
                                associateDieaseAttribute.showPrevoius && (
                                  <>
                                    {/*<ChartComponent.LineChartAssociateDiseaseMedicalData
                                    adaId={associateDieaseAttribute.adaId}
                                    thaiName={associateDieaseAttribute.thaiName}
                                    description={
                                      associateDieaseAttribute.description
                                    }
                                    unitThai={associateDieaseAttribute.unitThai}
                                    puId={puId}
                                  />*/}
                                  </>
                                )}
                              {associateDieaseAttribute.type === 'INDEX' && (
                                <ChartComponent.CardStatusData
                                  dieaseData={associateDieaseAttribute}
                                  puId={puId}
                                />
                              )}
                            </>
                          )}
                        </>
                      )
                    },
                  )}
                </div>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default AssociateDiseaseCard
