import React from 'react'
import * as Pics from 'assets/picture'
import { ElementComponent } from 'components'
import dayjs from 'dayjs'
import { date } from 'utils'
export default class DrugPrint extends React.PureComponent<{
  diagnoseData: any
  patientData: any
  runNumber: number
}> {
  render() {
    let row = 1
    return (
      <>
        <div className="text-black-main">
          <ElementComponent.Image
            fullImage={false}
            src={Pics.PrintDrugHeader}
          />
          <div className="p-[24px]">
            <p className="text-black-main text-[20px]">
              ใบสั่งยาแลขที่ GPO-P {dayjs(new Date()).format('DDMMYY')}-
              {`${Array(4 - this.props.runNumber.toString().length)
                .fill(0)
                .join('')}${this.props.runNumber + 1}`}
            </p>
            <p className="text-black-main text-[18px] mt-4">
              วันที่ {dayjs(new Date()).format('DD MMMM BBBB')}
              {dayjs(new Date()).format(' เวลา H.m น.')}
            </p>
            {JSON.stringify(this.props.patientData) !== '{}' && (
              <p className="text-black-main text-[16px] mt-4">
                <div>
                  <p>
                    <b>ชื่อ</b> {this.props.patientData.firstname}{' '}
                    {this.props.patientData.lastname} <b>เพศ</b>{' '}
                    {' ' +
                      this.props.patientData.UserMedicalDemograhicData
                        .gender}{' '}
                    <b>อายุ</b>{' '}
                    {date.getAge(
                      this.props.patientData.UserMedicalDemograhicData
                        ?.birthdate,
                    )}{' '}
                    <b>ปี</b> <b>HN</b>
                    {' : ' +
                      `${Array(
                        7 - this.props.patientData.hnId.toString().length,
                      )
                        .fill(0)
                        .join('')}${this.props.patientData.hnId}`}
                  </p>
                </div>
              </p>
            )}
            <p className="text-black-main text-[16px] mt-4">
              <div>
                <p>ที่อยู่ : -</p>
              </div>
            </p>
            <table className="w-[100%] mt-4">
              <thead>
                <th>ลำดับ</th>
                <th>รายการยา</th>
                <th>วิธีบริหารยา</th>
                <th>จำนวนหน่วย</th>
              </thead>
              <tbody>
                {JSON.parse(this.props.diagnoseData.drugData).map(
                  (item: any) =>
                    item.invoiceType === 'ค่ายา' && (
                      <tr className="mt-[18px] h-[40px]">
                        <td align="center">{row++}</td>
                        <td align="center">
                          {item.dName} {item.tradeName} {item.quantity}{' '}
                          {item.unit}{' '}
                        </td>
                        <td align="center">
                          {item.dUseType === 'ยาฉีด'
                            ? item.injQtyVal +
                              ' ' +
                              item.injUnit +
                              ' ' +
                              item.injChooseTypeVal
                            : item.dutName}
                        </td>
                        <td align="center">{item.qty}</td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>

            <p className="text-black-main text-[16px] mt-6">
              <div>
                <p>
                  แพทย์ผู้สั่งยา น.พธนกร ยนต์นิยม เลขที่ใบประกอบวิชาชีพเวชกรรม{' '}
                  <b>70668</b>
                </p>
              </div>
            </p>
          </div>
        </div>
      </>
    )
  }
}
