import React from 'react'
import { useMutation } from 'urql'

import { ElementComponent } from 'components'
import { useResponsive } from 'hooks'
import { AuthMutate } from 'services/graphql/authService'
import { endpoints } from 'settings'

function LoginForm() {
  const { isMobile } = useResponsive()
  const [loginMutation] = useMutation(AuthMutate.login)

  const onClick = async () => {
    window.location.replace(`${endpoints.APP_SERVER_URL}/sso/google`)
  }

  return (
    <div className="w-full">
      <div className="text-center laptop:text-start flex flex-col gap-[20px]">
        <p
          className="text-[24px] laptop:text-[32px] font-bold"
          id="login-title-message"
        >
          เข้าสู่ระบบ
        </p>
        <p className="text-[18px] laptop:text-[24px]">
          ยินดีต้อนรับสู่ DR. ASA Primary Care Platform
        </p>
      </div>

      <hr className="border-t-[1px] border-dashed border-gray-main mt-[25px] mb-[42px]" />

      <div className="mt-[32px] w-full laptop:w-[470px]">
        <ElementComponent.Button
          loading={loginMutation.fetching}
          icon="basil:google-solid"
          id="login-submit-button"
          text="Login with Google"
          width="100%"
          height={isMobile ? '60px' : '52px'}
          onAction={onClick}
        />
      </div>
    </div>
  )
}

export default LoginForm
